import { SxProps } from '@mui/material';
import { CourseOccurrence } from '@shared/models/calendar';
import { DialogResult } from '@shared/services';
import { DialogActionButtons, DialogButton, ResponsiveDialog, SaveDialog, useSaveDialog } from '@studyo/components';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { PlannerPeriodEditView } from '../../../views';

export interface PlannerPeriodEditScreenProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  courseOccurrence: CourseOccurrence;
}

export const PlannerPeriodEditScreen = observer((props: PlannerPeriodEditScreenProps) => {
  const { localizationService, viewModelFactory } = useStudyoServices();
  const { courseOccurrence, sx = [], className, onSuccess, onCancel } = props;
  const strings = localizationService.localizedStrings.studyo.agenda.planner.periodEditStrings;
  const viewModel = useMemo(
    () => viewModelFactory.createPlannerPeriodEdit(courseOccurrence, onSuccess!, onCancel!),
    [courseOccurrence]
  );
  const [savePromise, startSave, resetSave, retrySave] = useSaveDialog(() => viewModel.save());

  const dismiss = () => {
    void viewModel.dismiss(false);
  };

  const saveDialogClose = (success: boolean) => {
    resetSave();
    if (success) {
      void viewModel.dismiss(true);
    }
  };

  return (
    <ResponsiveDialog
      sx={sx}
      className={className}
      maxWidth="sm"
      fullWidth={true}
      open={true}
      maxHeight="medium"
      onClose={dismiss}
    >
      <PlannerPeriodEditView sx={{ flex: 1 }} viewModel={viewModel} />

      <DialogActionButtons minimumScreenSize="sm">
        <DialogButton title={strings.cancel} type="cancel" onPress={dismiss} />
        <DialogButton title={strings.save} type="normal" variant="contained" onPress={startSave} />
      </DialogActionButtons>

      <SaveDialog
        promise={savePromise}
        retryCall={retrySave}
        onClose={saveDialogClose}
        titles={{
          saving: strings.savingTitle,
          saved: strings.savedTitle,
          error: strings.saveErrorTitle
        }}
        descriptions={{
          saving: strings.savingDescription,
          saved: strings.savedDescription,
          error: strings.saveErrorDescription,
          permanentError: strings.savePermanentErrorDescription
        }}
      />
    </ResponsiveDialog>
  );
});
