import { ListItemButton, ListItemText, SxProps } from '@mui/material';
import { TaskIcon } from '@shared/components/contents';
import { ContentDefinitionUtils } from '@shared/components/utils';
import { observer } from 'mobx-react-lite';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { ContentListItemViewModel } from '../../../viewmodels';

export interface ContentListItemProps {
  sx?: SxProps;
  className?: string;
  viewModel: ContentListItemViewModel;
}

export const ContentListItem = observer((props: ContentListItemProps) => {
  const { imageService, localizationService } = useStudyoServices();
  const { sx = [], className, viewModel } = props;

  const iconSize = 30;
  const { content } = viewModel;
  const title = ContentDefinitionUtils.getDisplayTitleForContent(content, localizationService.localizedStrings);

  const externalSource = content.externalContent != null ? content.externalContent.sourceIntegration : undefined;

  return (
    <ListItemButton sx={sx} className={className} onClick={() => void viewModel.displayContentInfo()}>
      <TaskIcon
        icon={content.kind === 'task' ? content.icon : undefined}
        customIcon={content.kind === 'note' ? imageService.studyoImages.tasks.regularIcons.note : undefined}
        state={content.state}
        squareSize={iconSize}
        isAssignment={viewModel.isAssignment}
        isUnread={false}
        publishedKind={viewModel.taskIconPublishKind}
        externalSource={externalSource}
        isPrivate={content.isPrivate}
        workloadLevel={content.workloadLevel}
        hasSteps={content.steps.length > 0}
        hasPublishError={viewModel.hasPublishError}
      />

      <ListItemText sx={{ ml: 2 }} primary={title} secondary={content.notes.length > 0 ? content.notes : undefined} />
    </ListItemButton>
  );
});
