import { CircularProgress, Stack, SxProps, Typography } from '@mui/material';
import { isSxArray } from '@shared/components/utils';
import { observer } from 'mobx-react-lite';

export interface PresenterPendingViewProps {
  sx?: SxProps;
  className?: string;
  loadingMessage?: string;
  indicatorsSize?: 'small' | 'medium' | 'large';
}

export const PresenterPendingView = observer((props: PresenterPendingViewProps) => {
  const { loadingMessage, indicatorsSize, sx = [], className } = props;

  return (
    <Stack
      className={className}
      spacing={2}
      sx={[
        {
          alignItems: 'center',
          justifyContent: 'center'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <CircularProgress size={indicatorSize(indicatorsSize)} />
      {loadingMessage != null && (
        <Typography
          variant="body2"
          sx={{
            color: (theme) => theme.palette.text.secondary
          }}
        >
          {loadingMessage}
        </Typography>
      )}
    </Stack>
  );
});

function indicatorSize(size: 'small' | 'medium' | 'large' | undefined) {
  switch (size) {
    case 'small':
      return '1em';

    case 'medium':
      return '2em';

    default:
      return '3em';
  }
}
