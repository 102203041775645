import { Button, Divider, Stack, SxProps, Typography, useMediaQuery, useTheme } from '@mui/material';
import { isSxArray } from '@shared/components/utils';
import { OnboardingBackgroundImage, OnboardingContentBackgroundView } from '@studyo/components';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { useStudyoServices } from '../../UseStudyoServicesHook';

export interface SuccessScreenProps {
  sx?: SxProps;
  className?: string;
}

export const SuccessScreen = observer((props: SuccessScreenProps) => {
  const { localizationService } = useStudyoServices();
  const { sx = [], className } = props;
  // Yes, same as "not found" page
  const strings = localizationService.localizedStrings.studyo.redirects;
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));
  const navigate = useNavigate();

  const goToHomepage = () => {
    navigate('/');
  };

  return (
    <Stack
      className={className}
      sx={[
        {
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <OnboardingBackgroundImage />
      <div className="login-container">
        <Stack
          sx={{
            flex: 1,
            position: 'relative'
          }}
        >
          <OnboardingContentBackgroundView sx={{ zIndex: 1 }} />

          <Stack
            spacing={2}
            sx={{
              flex: 1,
              zIndex: 2,
              alignItems: { xs: 'stretch', sm: 'center' },
              p: { xs: 2, sm: 3 },
              overflowY: 'auto',
              overflowX: 'hidden'
            }}
          >
            <Stack
              spacing={1}
              sx={{
                alignItems: 'center'
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  textAlign: 'center'
                }}
              >
                {strings.successTitle}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  px: 4,
                  textAlign: 'center'
                }}
              >
                {strings.successSubtitle}
              </Typography>
            </Stack>

            <Divider sx={{ width: 300 }} />

            <Button variant="contained" onClick={goToHomepage} sx={{ minWidth: !isExtraSmallScreen ? 350 : undefined }}>
              {strings.goToHomepageButtonTitle}
            </Button>
          </Stack>
        </Stack>
      </div>
    </Stack>
  );
});
