import { Stack, SxProps, TextField, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useStudyoServices } from '../../UseStudyoServicesHook';
import { DataPresenter } from '../../components';
import { AskNameViewModel } from '../../viewmodels';

export interface AskNameViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: AskNameViewModel;
}

export const AskNameView = observer((props: AskNameViewProps) => {
  const { localizationService } = useStudyoServices();
  const { sx = [], className, viewModel } = props;
  const strings = localizationService.localizedStrings.studyo.agenda.setting.askName;

  return (
    <DataPresenter
      sx={sx}
      className={className}
      dataLoaders={[viewModel.data]}
      renderData={() => (
        <Stack
          spacing={2}
          sx={{
            width: '100%',
            height: '100%',
            p: 2,
            overflow: 'auto'
          }}
        >
          <Typography
            sx={{
              textAlign: 'center'
            }}
          >
            {strings.subTitle}
          </Typography>

          <TextField
            variant="filled"
            value={viewModel.firstName}
            label={strings.firstName}
            onChange={(e) => (viewModel.firstName = e.currentTarget.value)}
            autoFocus
            slotProps={{ input: { slotProps: { input: { maxLength: 50 } } } }}
          />

          <TextField
            variant="filled"
            value={viewModel.lastName}
            label={strings.lastName}
            onChange={(e) => (viewModel.lastName = e.currentTarget.value)}
            onSubmit={() => void viewModel.save()}
            slotProps={{ input: { slotProps: { input: { maxLength: 50 } } } }}
          />

          <Typography
            variant="subtitle2"
            sx={{
              textAlign: 'center',
              color: (theme) => theme.palette.error.main
            }}
          >
            {viewModel.message}
          </Typography>
        </Stack>
      )}
    />
  );
});
