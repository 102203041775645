import { Divider, List, SxProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';
import { ListSection, PeriodPrioritySelectorElement } from '../../components';
import { PeriodPrioritySelectorViewModel } from '../../viewmodels';

export interface PeriodPrioritySelectorViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: PeriodPrioritySelectorViewModel;
}

export const PeriodPrioritySelectorView = observer((props: PeriodPrioritySelectorViewProps) => {
  const { sx = [], className, viewModel } = props;

  return (
    <List sx={{ ...sx, overflow: 'visible' }} className={className}>
      <ListSection>
        {viewModel.elements.map((element, index) => (
          <Fragment key={`period-priority-selector-item-${index}`}>
            <PeriodPrioritySelectorElement element={element} />
            {index < viewModel.elements.length - 1 && <Divider />}
          </Fragment>
        ))}
      </ListSection>
    </List>
  );
});
