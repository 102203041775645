import { ContentDefinitionUtils, SectionUtils } from '@shared/components/utils';
import { Section, SectionModel } from '@shared/models/config';
import { ContentDefinitionModel } from '@shared/models/content';
import { Color } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { AccountData } from '@shared/services/stores';
import { chain, sortBy } from 'lodash';
import { computed, makeObservable } from 'mobx';
import { AccountService, ContentPasteboardStore } from '../../services';
import {
  AppContentDistributeElementViewModel,
  ContentDistributeElementViewModel
} from './ContentDistributeElementViewModel';

export type ContentDistributeKind = 'same-period' | 'same-day' | 'same-occurrence';

export interface ContentDistributeViewModel {
  readonly canDistributeToOccurrence: boolean;
  readonly elements: ContentDistributeElementViewModel[];
  readonly contentTitle: string;
  readonly color: Color;
  distribute(kind: ContentDistributeKind): Promise<void>;
  cancel(): void;
  dismiss(): void;
}

export class AppContentDistributeViewModel implements ContentDistributeViewModel {
  readonly elements: ContentDistributeElementViewModel[];

  private _selectedSectionIds: string[] = [];

  constructor(
    private readonly _accountService: AccountService,
    private readonly _contentPasteboardStore: ContentPasteboardStore,
    private readonly _localizationService: LocalizationService,
    private readonly _contentId: string,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void
  ) {
    makeObservable(this);
    const sortedSection = sortBy(this.taughtSections, [(s) => s.title, (s) => s.sectionNumber]);
    this.elements = sortedSection.map(
      (s) =>
        new AppContentDistributeElementViewModel(
          this.data,
          s as Section,
          () => this.onSectionSelection(s.id),
          s.id === this.content.sectionId
        )
    );
  }

  @computed
  private get data(): AccountData {
    return this._accountService.displayedAccountData;
  }

  @computed
  private get content(): ContentDefinitionModel {
    return this.data.contentsById.get(this._contentId)!;
  }

  @computed
  private get taughtSections(): SectionModel[] {
    return this.data.userSections.filter((co) => co.teacherIds.includes(this.data.accountId));
  }

  @computed
  get canDistributeToOccurrence() {
    if (this.content.duePeriodTag === '') {
      return false;
    }

    const dueSchoolDay = this.data.schoolDaysByDay.get(this.content.dueDay.asString);
    if (dueSchoolDay == null) {
      return false;
    }

    const duePeriod = dueSchoolDay.periods.find((p) => p.tag === this.content.duePeriodTag);
    if (duePeriod == null) {
      return false;
    }

    const displayedOccurrence = this.data.periodPrioritiesStore.getOccurrenceForPeriod(duePeriod, dueSchoolDay.day);
    if (displayedOccurrence == null) {
      return false;
    }

    return displayedOccurrence.sectionId === this.content.sectionId;
  }

  @computed
  get contentTitle(): string {
    return ContentDefinitionUtils.getDisplayTitleForContent(this.content, this._localizationService.localizedStrings);
  }

  @computed
  get color(): Color {
    const section = this.data.sectionsById.get(this.content.sectionId);
    return SectionUtils.getSectionColor(section, this.data.account, undefined)!;
  }

  async distribute(kind: ContentDistributeKind) {
    switch (kind) {
      case 'same-day':
        await this.distributeToSameDay();
        break;

      case 'same-occurrence':
        if (this.canDistributeToOccurrence) {
          await this.distributeToSameOccurrence();
        } else {
          throw new Error(
            `Can't distribute to same occurrence. We can only distribute to the same
               occurrence if the task is due in a period of its due section.`
          );
        }
        break;

      case 'same-period':
        await this.distributeToSamePeriod();
        break;
    }
  }

  cancel() {
    this._onCancel();
  }

  dismiss() {
    this._onSuccess();
  }

  private onSectionSelection(sectionId: string) {
    const sectionIndex = this._selectedSectionIds.indexOf(sectionId);

    if (sectionIndex < 0) {
      this._selectedSectionIds.push(sectionId);
    } else {
      this._selectedSectionIds.splice(sectionIndex, 1);
    }
  }

  // We copy the content into the same period for all the selected sections
  private async distributeToSamePeriod() {
    await Promise.all(
      this._selectedSectionIds.map((item) =>
        this._contentPasteboardStore.pasteContentInPeriod(
          this.content,
          this.content.dueDay,
          this.content.duePeriodTag,
          this.data,
          item,
          false,
          false,
          true
        )
      )
    );
  }

  // Looks for a period with an occurrence of the selected section in the content due SchoolDay. If we found one,
  // we copy the content into that period. Otherwise, we copy it in the same period.
  private async distributeToSameDay() {
    const schoolDay = this.data.schoolDaysByDay.get(this.content.dueDay.asString)!;

    await Promise.all(
      this._selectedSectionIds.map((sectionId) => {
        // Looking for first occurrence of the section in the SchoolDay.
        const period = schoolDay.sortedPeriods.find(
          (p) => p.courseOccurrences.find((co) => co.sectionId === sectionId) != null
        );

        return this._contentPasteboardStore.pasteContentInPeriod(
          this.content,
          this.content.dueDay,
          period?.tag ?? this.content.duePeriodTag,
          this.data,
          sectionId,
          false,
          false,
          true
        );
      })
    );
  }

  private async distributeToSameOccurrence() {
    const schoolDay = this.data.schoolDaysByDay.get(this.content.dueDay.asString)!;
    const period = schoolDay.periods.find((p) => p.tag === this.content.duePeriodTag)!;
    const occurrence = this.data.periodPrioritiesStore.getOccurrenceForPeriod(period, schoolDay.day);

    if (occurrence != null) {
      const ordinal = occurrence.normalizedOrdinal;

      await Promise.all(
        this._selectedSectionIds.map((item) => {
          const occurrences = this.data.getOccurrencesForSectionId(item);
          let targetOccurrence = occurrences.find((co) => co.occurrence.normalizedOrdinal === ordinal);

          if (targetOccurrence == null) {
            // If no occurrence has the same ordinal, we copy the task into the last not-skipped occurrence
            targetOccurrence = chain(occurrences)
              .filter((co) => !co.occurrence.skipped)
              .sortBy([(o) => o.occurrence.ordinal])
              .last()
              .value()!;
          }

          return this._contentPasteboardStore.pasteContentInPeriod(
            this.content,
            targetOccurrence.day,
            targetOccurrence.period.tag,
            this.data,
            item,
            false,
            false,
            true
          );
        })
      );
    }
  }
}
