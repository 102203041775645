import { ContentDefinition_Step as PBStep } from '@buf/studyo_studyo.bufbuild_es/studyo/type_contents_pb';
import { computed, makeObservable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { EditableModel, EditableNullableDayProperty, EditableValueProperty } from '../EditableModel';
import { Day } from '../types';
import { ContentStep, ContentStepModel } from './ContentStep';
import { EditableContentDefinition } from './EditableContentDefinition';

export class EditableContentStep extends EditableModel<PBStep> implements ContentStepModel {
  private _stepId: EditableValueProperty<string, PBStep>;
  private _title: EditableValueProperty<string, PBStep>;
  private _date: EditableNullableDayProperty<PBStep>;
  private _sortOrder: EditableValueProperty<number, PBStep>;
  private _completed: EditableValueProperty<boolean, PBStep>;

  static createNew(content: EditableContentDefinition, parentStepId: string | undefined) {
    const step = new PBStep();
    step.id = uuidv4();
    step.completed = false;
    step.title = '';
    step.date = undefined;
    step.stepId = parentStepId ?? uuidv4();
    step.sortOrder = content.steps.filter((s) => !s.shouldBeDeleted).length + 1;

    return new EditableContentStep(new ContentStep(step), true);
  }

  constructor(
    private readonly _originalStep: ContentStepModel,
    public readonly isNew = false
  ) {
    super(_originalStep.toProtobuf(), isNew);

    makeObservable(this);

    this.setFields([
      (this._stepId = new EditableValueProperty(_originalStep.stepId, (pb, value) => (pb.stepId = value))),
      (this._title = new EditableValueProperty(_originalStep.title, (pb, value) => (pb.title = value))),
      (this._date = new EditableNullableDayProperty(
        _originalStep.date,
        (pb, value) => (pb.date = value != null ? value.asPB : undefined)
      )),
      (this._sortOrder = new EditableValueProperty(_originalStep.sortOrder, (pb, value) => (pb.sortOrder = value))),
      (this._completed = new EditableValueProperty(_originalStep.completed, (pb, value) => (pb.completed = value)))
    ]);
  }

  //
  // Read-only properties
  //

  get id() {
    return this._originalStep.id;
  }

  //
  // Editable properties
  //

  @computed
  get stepId(): string {
    return this._stepId.value;
  }

  set stepId(value: string) {
    this._stepId.value = value;
  }

  @computed
  get title(): string {
    return this._title.value;
  }

  set title(value: string) {
    this._title.value = value;
  }

  @computed
  get date(): Day | undefined {
    return this._date.value;
  }

  set date(value: Day | undefined) {
    this._date.value = value;
  }

  @computed
  get sortOrder(): number {
    return this._sortOrder.value;
  }

  set sortOrder(value: number) {
    this._sortOrder.value = value;
  }

  @computed
  get completed(): boolean {
    return this._completed.value;
  }

  set completed(value: boolean) {
    this._completed.value = value;
  }
}
