import { CalendarMonth } from '@mui/icons-material';
import { Box, Button, Divider, IconButton, Popover, Stack, SxProps, Typography } from '@mui/material';
import { isSxArray } from '@shared/components/utils';
import { SchoolDay } from '@shared/models/calendar';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { TaskStepListItemViewModel } from '../../../viewmodels';
import { SchoolDayPicker } from '../../utils';

export interface TaskStepCalendarProps {
  sx?: SxProps;
  className?: string;
  viewModel: TaskStepListItemViewModel;
}

export const TaskStepCalendar = observer((props: TaskStepCalendarProps) => {
  const { localizationService, viewModelFactory } = useStudyoServices();
  const { viewModel, sx = [], className } = props;
  const [datePickerAnchorRef, setDatePickerAnchorRef] = useState<HTMLButtonElement | null>(null);

  const getSchoolDayPickerViewModel = () => {
    const vm = viewModelFactory.createSchoolDayPicker();
    vm.setMinimumAndMaximumDays(viewModel.minimumDay, viewModel.maximumDay);
    vm.setCurrentDay(viewModel.date ?? viewModel.maximumDay);

    return vm;
  };

  const hideSchoolDayPicker = () => {
    setDatePickerAnchorRef(null);
  };

  const didSelectDate = (schoolDay: SchoolDay) => {
    viewModel.date = schoolDay.day;
    hideSchoolDayPicker();
  };

  const removeDate = () => {
    viewModel.date = undefined;
    hideSchoolDayPicker();
  };

  return (
    <Box
      className={className}
      sx={[
        {
          display: 'flex',
          flexDirection: 'column'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      {viewModel.canEdit &&
        (viewModel.date != null ? (
          <Button size="small" onClick={(e) => setDatePickerAnchorRef(e.currentTarget)} endIcon={<CalendarMonth />}>
            {viewModel.date.formattedString(localizationService.localizedStrings.models.dateFormats.shortUnabridged)}
          </Button>
        ) : (
          <IconButton size="small" color="primary" onClick={(e) => setDatePickerAnchorRef(e.currentTarget)}>
            {<CalendarMonth fontSize="medium" />}
          </IconButton>
        ))}
      {!viewModel.canEdit && viewModel.date != null && (
        <Typography
          variant="caption"
          sx={{
            color: (theme) => theme.palette.text.secondary
          }}
        >
          {viewModel.date.formattedString(localizationService.localizedStrings.models.dateFormats.shortUnabridged)}
        </Typography>
      )}
      {viewModel.canEdit && (
        <Popover
          id="step-date-selection-popover"
          open={datePickerAnchorRef != null}
          anchorEl={datePickerAnchorRef}
          onClose={hideSchoolDayPicker}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
        >
          <Stack
            sx={{
              pt: 2
            }}
          >
            <Box sx={{ height: 300, width: 400, display: 'flex', flexDirection: 'column' }}>
              <SchoolDayPicker sx={{ flex: 1 }} viewModel={getSchoolDayPickerViewModel()} onSelection={didSelectDate} />
            </Box>

            <Divider variant="middle" />

            <Stack
              direction="row"
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                p: 2
              }}
            >
              <Button disabled={viewModel.date == null} onClick={removeDate} color="error">
                {localizationService.localizedStrings.studyo.contents.stepList.removeDate}
              </Button>
            </Stack>
          </Stack>
        </Popover>
      )}
    </Box>
  );
});
