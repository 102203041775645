import { ContentDefinitionUtils } from '@shared/components/utils';
import { SchoolDayPeriod } from '@shared/models/calendar';
import { SectionModel } from '@shared/models/config';
import { ContentDefinitionModel, EditableContentDefinition } from '@shared/models/content';
import { Day } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { AccountData } from '@shared/services/stores/interfaces';
import { verifyAndConfirmWorkload } from '@studyo/utils';
import { Memoize } from 'fast-typescript-memoize';
import _ from 'lodash';
import { computed, makeObservable } from 'mobx';
import {
  ContentPasteboardStore,
  DailyContentDisplayKind,
  DailyContentPresentationKind,
  NavigationService,
  StudyoAccountSettings,
  StudyoAnalyticsService,
  WeeklyContentDisplayKind
} from '../../../services';
import {
  AppContentNoteBoxViewModel,
  AppDisplayableContentBoxViewModel,
  AppDisplayableContentViewModel,
  ContentNoteBoxViewModel,
  DisplayableContentBoxViewModel,
  DisplayableContentViewModel
} from '../../contents';
import { DayAndWeekViewModelKind } from './DayAndWeekViewModel';

export interface DayAndWeekPeriodContentBoxViewModel {
  readonly contentBoxViewModel: DisplayableContentBoxViewModel;
  readonly noteBoxViewModel: ContentNoteBoxViewModel;
  readonly currentDisplayKind: DailyContentDisplayKind | WeeklyContentDisplayKind;
  readonly presentationKind: DailyContentPresentationKind;
  readonly kind: DayAndWeekViewModelKind;
  readonly isSkipped: boolean;
  readonly isReadonly: boolean;
  readonly canPasteContent: boolean;

  compareSection: (sectionId: string) => boolean;
  copyContent: (contentId: string, sectionId: string, copyFromAssignment: boolean) => void;
}

export class AppDayAndWeekPeriodContentBoxViewModel implements DayAndWeekPeriodContentBoxViewModel {
  @computed
  private get courseOccurrence() {
    return this._data.periodPrioritiesStore.getOccurrenceForPeriod(this._period, this._day);
  }

  @computed
  private get schoolDayContents() {
    return this._data.visibleContents.filter(
      (c) => c.dueDay.isSame(this._day) || ContentDefinitionUtils.isAssignedToDay(c, this._day)
    );
  }

  @computed
  private get contents() {
    const courseOccurrence = this.courseOccurrence;
    const contentsForPeriod = this.schoolDayContents.filter((content) => {
      if (this.isAssignment(content)) {
        if (
          this.kind === 'weekly' ||
          content.sectionId === '' ||
          !this.isFirstPeriodOfDayToDisplaySection ||
          courseOccurrence == null ||
          !this._preferences.dailyShowAssignmentDay
        ) {
          return false;
        }

        return content.sectionId === courseOccurrence.sectionId;
      } else {
        return this.shouldDisplayNonAssignmentContent(content);
      }
    });

    return this.displayableContentViewModelsFromContents(contentsForPeriod);
  }

  @computed
  private get contentsWithoutNote() {
    return _.filter(this.contents, (c) => {
      return c.content.kind === 'task';
    });
  }

  @computed
  private get note() {
    return this.contents.find((c) => c.content.kind === 'note');
  }

  private get otherPeriodsWithTag(): SchoolDayPeriod[] {
    return this._periods.filter((p) => p.tag === this._period.tag && p.id !== this._period.id);
  }

  private get otherBusyPeriodsWithTag(): SchoolDayPeriod[] {
    return this.otherPeriodsWithTag.filter((p) => !this.isFreePeriod(p));
  }

  private get otherFreePeriodsWithTag(): SchoolDayPeriod[] {
    return this.otherPeriodsWithTag.filter((p) => this.isFreePeriod(p));
  }

  private get isFirstFreePeriodWithTag(): boolean {
    const freePeriods = this._periods.filter((p) => p.tag === this._period.tag && this.isFreePeriod(p));
    return _.findIndex(freePeriods, { id: this._period.id }) === 0;
  }

  private get isUniquePeriodTag(): boolean {
    return this.otherPeriodsWithTag.length === 0;
  }

  @computed
  private get isFirstPeriodOfDayToDisplaySection(): boolean {
    if (this.courseOccurrence == null) {
      return false;
    }

    for (const currentPeriod of this._periods) {
      const periodCourseOccurrence = this._data.periodPrioritiesStore.getOccurrenceForPeriod(currentPeriod, this._day);
      if (periodCourseOccurrence != null) {
        // if a period's course occurrence shares a same sectionId, but not the same period tag,
        // it means the period to verify's tag is later in the day, therefore it's not the first occurrence
        if (periodCourseOccurrence.sectionId === this._section()?.id) {
          return this._period.tag === currentPeriod.tag;
        }
      }
    }

    return false;
  }

  @Memoize()
  get noteBoxViewModel() {
    return new AppContentNoteBoxViewModel(
      this._navigationService,
      this._day,
      this._period.tag,
      this._section()?.id,
      () => this.note,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {}
    );
  }

  @computed
  get presentationKind() {
    return this.kind === 'daily' ? this._preferences.dailyContentPresentationKind : 'detailed';
  }

  @computed
  get currentDisplayKind() {
    return this.kind === 'daily'
      ? this._preferences.dailyContentDisplayKind
      : this._preferences.weeklyContentDisplayKind;
  }

  @computed
  get isSkipped(): boolean {
    return this.courseOccurrence?.skipped ?? false;
  }

  @computed
  get isReadonly() {
    return this._data.isReadOnly;
  }

  @computed
  get canPasteContent() {
    return this._data.canAddOrEditContentAtDate(this._day);
  }

  readonly contentBoxViewModel: DisplayableContentBoxViewModel;

  constructor(
    public readonly kind: DayAndWeekViewModelKind,
    private readonly _navigationService: NavigationService,
    private readonly _localizationService: LocalizationService,
    private readonly _contentPasteboardStore: ContentPasteboardStore,
    private readonly _analyticsService: StudyoAnalyticsService,
    private readonly _data: AccountData,
    private readonly _day: Day,
    private readonly _period: SchoolDayPeriod,
    private readonly _section: () => SectionModel | undefined,
    private readonly _canEdit: boolean,
    private readonly _preferences: StudyoAccountSettings,
    private readonly _periods: SchoolDayPeriod[]
  ) {
    makeObservable(this);
    this.contentBoxViewModel = new AppDisplayableContentBoxViewModel(
      this._localizationService,
      this._navigationService,
      this._analyticsService,
      this._contentPasteboardStore,
      this._data,
      this._day,
      this._period,
      () => this._section(),
      () =>
        this._preferences.dailyContentDisplayKind === 'both' && this.kind === 'daily'
          ? this.contentsWithoutNote
          : this.contents.filter((cd) => cd.content.kind === 'task' || cd.content.notes.trim().length > 0)
    );
  }

  compareSection(sectionId: string) {
    const section = this._section();
    return (section?.id.length ?? 0) > 0 && sectionId.length > 0 ? section?.id === sectionId : false;
  }

  copyContent(contentId: string, sectionId: string, copyFromAssignment: boolean) {
    const isSameSection = this.compareSection(sectionId);
    const content = this._data.contentsById.get(contentId)!;

    if (content.kind === 'task') {
      this.copyTask(content, copyFromAssignment);
    } else if (content.kind === 'note') {
      this.moveNote(content, isSameSection);
    }
  }

  private contentHasSection(content: ContentDefinitionModel): boolean {
    return content.sectionId !== '';
  }

  private copyTask(task: ContentDefinitionModel, copyFromAssignment: boolean) {
    const isSameSection = this.compareSection(task.sectionId);

    if (isSameSection) {
      void this.moveTask(task, copyFromAssignment);
    } else {
      void this._contentPasteboardStore.pasteContentInPeriod(
        task,
        this._day,
        this._period.tag,
        this._data,
        this._section()?.id,
        false,
        copyFromAssignment
      );
    }
  }

  private displayableContentViewModelsFromContents(
    contentDefinitions: ContentDefinitionModel[]
  ): DisplayableContentViewModel[] {
    const section = this._section();

    return contentDefinitions.map(
      (cd) =>
        new AppDisplayableContentViewModel(
          this._localizationService,
          this._data,
          this._data.sectionsById.get(cd.sectionId),
          section != null ? section.id : undefined,
          cd,
          this.isAssignment(cd)
        )
    );
  }

  private isAssignment(content: ContentDefinitionModel): boolean {
    return !content.dueDay.isSame(this._day);
  }

  private async moveTask(task: ContentDefinitionModel, isAssignment: boolean) {
    const editableContent = new EditableContentDefinition(task);
    const isSameAssignmentAndDueDate = editableContent.assignmentDay.isSame(editableContent.dueDay);

    if (isAssignment) {
      editableContent.assignmentDay = this._day;

      if (isSameAssignmentAndDueDate || editableContent.plannedDay.isBefore(editableContent.assignmentDay)) {
        editableContent.plannedDay = editableContent.assignmentDay;
      }

      if (isSameAssignmentAndDueDate || editableContent.dueDay.isBefore(editableContent.assignmentDay)) {
        editableContent.dueDay = editableContent.assignmentDay;
      }
    } else {
      editableContent.dueDay = this._day;

      if (isSameAssignmentAndDueDate || editableContent.assignmentDay.isAfter(editableContent.dueDay)) {
        editableContent.assignmentDay = editableContent.dueDay;
      }

      if (isSameAssignmentAndDueDate || editableContent.plannedDay.isAfter(editableContent.dueDay)) {
        editableContent.plannedDay = editableContent.dueDay;
      }

      editableContent.duePeriodTag = this._period.tag;
    }

    if (editableContent.isMaster) {
      if (!(await verifyAndConfirmWorkload(this._navigationService, this._data, editableContent, true))) {
        return;
      }
    }

    await this._data.createOrUpdateContent(editableContent);
  }

  private moveNote(note: ContentDefinitionModel, isSameSection: boolean) {
    const existingNote = this._data.contents.find(
      (cd) => cd.kind === 'note' && cd.dueDay.isSame(this._day) && cd.duePeriodTag === this._period.tag
    );

    // If we are copying into the same period, we do nothing
    if (existingNote?.id === note.id) {
      return;
    }

    const editableTargetNote =
      existingNote != null
        ? new EditableContentDefinition(existingNote, false)
        : EditableContentDefinition.createNewNote(
            this._data.configId,
            this._data.accountId,
            this._day,
            this._period.tag
          );

    const editableSourceNote = new EditableContentDefinition(note, false);

    // If existing note already has notes, we merge them by first doing a return of line.
    if (editableTargetNote.notes.length > 0) {
      editableTargetNote.notes += '\n';
    }

    editableTargetNote.notes += editableSourceNote.notes;

    // If both notes are linked to a section and they are identical, we move the note instead of creating a copy.
    if (isSameSection) {
      editableSourceNote.notes = '';
    }

    void this._data.createOrUpdateContents([editableSourceNote, editableTargetNote]);
  }

  private shouldDisplayNonAssignmentContent(content: ContentDefinitionModel): boolean {
    if (content.duePeriodTag !== this._period.tag) {
      return false;
    }

    const sectionId = this._section()?.id ?? '';

    if (this.isUniquePeriodTag) {
      return true;
    } else if (content.sectionId === sectionId) {
      return this.contentHasSection(content) || this.isFirstFreePeriodWithTag;
    } else if (!this.isContentDisplayedInAnotherPeriodWithTag(content)) {
      const isFirstFreePeriodWithTag = this.isFirstFreePeriodWithTag;

      return this.contentHasSection(content)
        ? sectionId !== '' || (this.otherBusyPeriodsWithTag.length === 0 && isFirstFreePeriodWithTag)
        : this.otherFreePeriodsWithTag.length === 0 || isFirstFreePeriodWithTag;
    }

    return false;
  }

  private isContentDisplayedInAnotherPeriodWithTag(content: ContentDefinitionModel): boolean {
    return (
      _.find(this.otherPeriodsWithTag, (p) =>
        this.isFreePeriod(p)
          ? !this.contentHasSection(content)
          : _.find(p.courseOccurrences, { sectionId: content.sectionId })
      ) != null
    );
  }

  private isFreePeriod(period: SchoolDayPeriod): boolean {
    return period.courseOccurrences.filter((co) => co.teacherIds.includes(this._data.accountId)).length === 0;
  }
}
