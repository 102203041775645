import { AccountUtils } from '@shared/components/utils';
import { SchoolYearConfigurationUtils } from '@shared/components/utils/models/SchoolYearConfigurationUtils';
import { AccountModel, EditableAccount, SectionModel } from '@shared/models/config';
import { OnDestroy, OnInit } from '@shared/services';
import { AccountData } from '@shared/services/stores';
import _ from 'lodash';
import { IReactionDisposer, action, autorun, computed, makeObservable, observable, runInAction } from 'mobx';
import { AccountAutoSyncService, AccountService, NavigationService } from '../../services';
import {
  AppCourseSelectionFilterCellViewModel,
  CourseSelectionFilterCellViewModel
} from './CourseSelectionFilterCellViewModel';
import {
  AppCourseSelectionSectionListViewModel,
  CourseSelectionSectionListViewModel
} from './CourseSelectionSectionListViewModel';

export interface CourseSelectionViewModel extends OnInit, OnDestroy {
  readonly activeConfigSchool: string;
  readonly gradeLevels: CourseSelectionFilterCellViewModel;
  readonly hasChanges: boolean;
  readonly searchResultsViewModel?: CourseSelectionSectionListViewModel;
  readonly sectionNumbers: CourseSelectionFilterCellViewModel;
  readonly selectedCourses: CourseSelectionSectionListViewModel;
  readonly showNoneValueForSectionNumberFilter: boolean;
  readonly teachers: CourseSelectionFilterCellViewModel;
  searchText: string;

  dismissModal: (cancel: boolean) => void;
  didSelectNewGradeLevel: (index: number | undefined) => void;
  didSelectNewSectionNumber: (index: number | undefined) => void;
  didSelectNewTeacher: (index: number | undefined) => void;
  didChangeSelectedForSectionId: (sectionId: string, selected: boolean) => void;
  save: () => Promise<void>;
}

export class AppCourseSelectionViewModel implements CourseSelectionViewModel {
  @observable private _searchText = '';
  @observable private _selectedGradeLevelIndex: number | undefined;
  @observable private _selectedSectionNumberIndex: number | undefined;
  @observable private _selectedTeacherIndex: number | undefined;
  @observable private _newSelectedSelectedSectionIds: string[] = [];
  @observable private _showNoneValueForSectionNumberFilter = true;

  private readonly _data: AccountData;
  private readonly _account: EditableAccount;
  private readonly _listGradeLevels: string[];
  private _listSectionNumbers: string[] = [];
  private readonly _listTeachers: AccountModel[];
  private readonly _originalSelectedSectionIds: string[];
  private readonly _gradeLevelFilter: CourseSelectionFilterCellViewModel;
  @observable private _sectionNumberFilter?: CourseSelectionFilterCellViewModel;
  private readonly _teacherFilter: CourseSelectionFilterCellViewModel;
  private _disposer: IReactionDisposer;

  constructor(
    private readonly _navigationService: NavigationService,
    private readonly _accountAutoSyncService: AccountAutoSyncService,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    accountService: AccountService
  ) {
    makeObservable(this);
    this._data = accountService.displayedAccountData;
    this._account = new EditableAccount(this._data.account, false);
    this._originalSelectedSectionIds = this._account.settings.selectedSectionIds;

    this._listGradeLevels = _.chain(this._data.sections)
      .map((s) => s.gradeLevel)
      .uniq()
      .compact()
      .sort()
      .value();

    this._gradeLevelFilter = new AppCourseSelectionFilterCellViewModel(this._listGradeLevels);

    this._disposer = autorun(() => {
      let sections: SectionModel[] = [];

      if (this._selectedGradeLevelIndex != null) {
        const gradeLevel = this._listGradeLevels[this._selectedGradeLevelIndex];
        sections = _.filter(this._data.sections, (s) => s.gradeLevel === gradeLevel);
      } else {
        sections = this._data.sections;
      }

      this._listSectionNumbers = _.chain(sections)
        .map((s) => s.sectionNumber)
        .uniq()
        .compact()
        .sort()
        .value();

      runInAction(() => {
        this._showNoneValueForSectionNumberFilter = this._selectedGradeLevelIndex == null;
        this._sectionNumberFilter = new AppCourseSelectionFilterCellViewModel(this._listSectionNumbers);
      });
    });

    this._listTeachers = _.chain(this._data.accounts)
      .filter((a) => a.role === 'teacher')
      .sortBy(['firstName', 'lastName'])
      .value();

    this._teacherFilter = new AppCourseSelectionFilterCellViewModel(this.listTeachers);
  }

  @computed
  get activeConfigSchool() {
    return `${this._data.config.schoolName} (${SchoolYearConfigurationUtils.displayTitle(this._data.config)})`;
  }

  @computed
  get gradeLevels() {
    return this._gradeLevelFilter;
  }

  @computed
  get hasChanges() {
    return this._account.hasChanges;
  }

  @computed
  get sectionNumbers() {
    return this._sectionNumberFilter!;
  }

  @computed
  get searchText() {
    return this._searchText;
  }

  set searchText(text: string) {
    runInAction(() => (this._searchText = text));
  }

  @computed
  get searchResults(): SectionModel[] | undefined {
    let results: SectionModel[] = [];
    let isFiltering = false;

    if (this._selectedGradeLevelIndex != null) {
      if (this._selectedGradeLevelIndex === -1) {
        results = this._data.sections;
      } else {
        const gradeLevel = this._listGradeLevels[this._selectedGradeLevelIndex];
        results = _.filter(this._data.sections, (s) => s.gradeLevel === gradeLevel);
      }

      isFiltering = true;
    }

    if (this._selectedSectionNumberIndex != null) {
      if (this._selectedSectionNumberIndex === -1) {
        results = this._data.sections;
      } else {
        const filteringResults = isFiltering ? results : this._data.sections;
        const sectionNumber = this._listSectionNumbers[this._selectedSectionNumberIndex];

        results = _.filter(filteringResults, (s) => s.sectionNumber == sectionNumber);
      }

      isFiltering = true;
    }

    if (this._selectedTeacherIndex != null) {
      const filteringResults = isFiltering ? results : this._data.sections;
      const teacher = this._listTeachers[this._selectedTeacherIndex];

      results = _.filter(filteringResults, (s) => {
        if (s.defaultTeacherId != null) {
          const defaultTeacher = this._data.accountsById.get(s.defaultTeacherId);

          return defaultTeacher != null ? defaultTeacher.id === teacher.id : false;
        } else {
          return false;
        }
      });

      isFiltering = true;
    }

    if (this._searchText.length > 0) {
      const uppercaseSearchText = this._searchText.toUpperCase();

      if (!isFiltering) {
        results = this._data.sections;
      }

      results = _.filter(results, (s) => {
        if (s.title.toUpperCase().includes(uppercaseSearchText)) {
          return true;
        }

        const teacher = this._data.accountsById.get(s.defaultTeacherId);

        if (
          teacher != null &&
          AccountUtils.getDisplayFirstLastName(teacher, undefined).toUpperCase().includes(uppercaseSearchText)
        ) {
          return true;
        }

        return s.associatedSectionNumbers.find((n) => n.toUpperCase() == uppercaseSearchText) != null;
      });
      isFiltering = true;
    }

    return isFiltering ? _.sortBy(results, ['title', 'sectionNumber']) : undefined;
  }

  @computed
  get searchResultsViewModel() {
    if (this.searchResults != null) {
      return new AppCourseSelectionSectionListViewModel(this._data, this._account, this.searchResults);
    } else {
      return undefined;
    }
  }

  @computed
  get selectedCourses() {
    return new AppCourseSelectionSectionListViewModel(
      this._data,
      this._account,
      _.orderBy(this.userSections, ['title', 'sectionNumber'])
    );
  }

  @computed
  get showNoneValueForSectionNumberFilter() {
    return this._showNoneValueForSectionNumberFilter;
  }

  @computed
  get teachers() {
    return this._teacherFilter;
  }

  onInit() {
    this._accountAutoSyncService.suspend();
  }

  onDestroy() {
    this._disposer();
    this._accountAutoSyncService.resume();
  }

  dismissModal(cancel: boolean) {
    (cancel ? this._onCancel : this._onSuccess)();
  }

  @action
  didSelectNewGradeLevel(index?: number) {
    this._teacherFilter.selectedIndex = undefined;
    this._selectedTeacherIndex = undefined;
    this._selectedGradeLevelIndex = index;
  }

  @action
  didSelectNewSectionNumber(index?: number) {
    this._teacherFilter.selectedIndex = undefined;
    this._selectedTeacherIndex = undefined;
    this._selectedSectionNumberIndex = index;
  }

  @action
  didSelectNewTeacher(index?: number) {
    this._selectedGradeLevelIndex = undefined;
    this._gradeLevelFilter.selectedIndex = undefined;
    this._selectedSectionNumberIndex = undefined;
    this._sectionNumberFilter!.selectedIndex = undefined;
    this._selectedTeacherIndex = index;
  }

  didChangeSelectedForSectionId(sectionId: string, selected: boolean) {
    if (selected) {
      this._account.getEditableSettings().selectedSectionIds = _.uniq(
        this._account.settings.selectedSectionIds.concat(sectionId)
      );

      runInAction(() => {
        this._newSelectedSelectedSectionIds = _.uniq(this._newSelectedSelectedSectionIds.concat(sectionId));
      });
    } else {
      this._account.getEditableSettings().selectedSectionIds = _.filter(
        this._account.settings.selectedSectionIds,
        (sId) => sId !== sectionId
      );
    }
  }

  async save(): Promise<void> {
    await this._data.createOrUpdateAccount(this._account);
    await this._data.refresh();
  }

  @computed
  private get listTeachers(): string[] {
    return _.chain(this._listTeachers)
      .map((t) => `${t.firstName} ${t.lastName}`)
      .compact()
      .value();
  }

  @computed
  private get selectedSectionIds(): string[] {
    return _.chain(this._originalSelectedSectionIds).concat(this._newSelectedSelectedSectionIds).uniq().value();
  }

  @computed
  private get selectedSections(): SectionModel[] {
    return _.chain(this.selectedSectionIds)
      .map((sId) => this._data.sectionsById.get(sId))
      .compact()
      .value();
  }

  @computed
  get userSections(): SectionModel[] {
    return _.concat(this.selectedSections, this._data.autoEnrollSections);
  }
}
