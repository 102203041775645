import { Stack, SxProps } from '@mui/material';
import { isSxArray } from '@shared/components/utils';
import { OnboardingBackgroundImage, OnboardingContentBackgroundView } from '@studyo/components';
import { ConfigCreationView } from '@studyo/views';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import '../../styles/LoginScreen.style.scss';

export interface ConfigCreationScreenProps {
  sx?: SxProps;
  className?: string;
}

export const ConfigCreationScreen = observer((props: ConfigCreationScreenProps) => {
  const { viewModelFactory } = useStudyoServices();
  const { sx = [], className } = props;
  const params = useParams();
  const isInitialConfigNumeric = params.isInitialConfig ?? '';

  const viewModel = useMemo(
    () => viewModelFactory.createConfigCreationScreen(isInitialConfigNumeric === '1'),
    [isInitialConfigNumeric]
  );

  return (
    <Stack
      className={className}
      sx={[
        {
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <OnboardingBackgroundImage />
      <div className="login-container">
        <Stack
          sx={{
            flex: 1,
            position: 'relative'
          }}
        >
          <OnboardingContentBackgroundView sx={{ zIndex: 1 }} />

          <ConfigCreationView viewModel={viewModel} sx={{ flex: 1, zIndex: 2 }} />
        </Stack>
      </div>
    </Stack>
  );
});
