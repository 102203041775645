import { Stack, SxProps, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { OptionsDialogElement } from '../../../models';
import { DialogHeader } from '../DialogHeader.tsx';
import { ResponsiveDialog } from '../ResponsiveDialog.tsx';
import { OptionsDialogElementRow } from './OptionsDialogElementRow';

export interface OptionsDialogProps {
  sx?: SxProps;
  className?: string;
  open: boolean;
  title: string;
  description?: string;
  elements: OptionsDialogElement[];
  onCancel?: () => void;
  onClose: () => void;
}

export const OptionsDialog = observer((props: OptionsDialogProps) => {
  const { localizationService } = useStudyoServices();
  const { sx = [], className, open, title, description, onCancel, onClose, elements } = props;

  const didPressOnCancel = () => {
    onCancel?.();
  };

  return (
    <ResponsiveDialog sx={sx} className={className} open={open} onClose={onClose} maxWidth="xs" fullWidth={true}>
      <DialogHeader title={title} />
      {description != null && (
        <Typography
          sx={{
            mx: 3,
            my: 2
          }}
        >
          {description}
        </Typography>
      )}
      <Stack
        sx={{
          flex: 1,
          overflow: 'auto'
        }}
      >
        {elements.map((element, index) => (
          <OptionsDialogElementRow key={`option-dialog-element-${index}`} element={element} />
        ))}

        {onCancel != null && (
          <OptionsDialogElementRow
            key={'option-dialog-element-cancel'}
            element={{
              title: localizationService.localizedStrings.studyo.navigation.headerCancelButton,
              type: 'destructive',
              selectionHandler: didPressOnCancel
            }}
          />
        )}
      </Stack>
    </ResponsiveDialog>
  );
});
