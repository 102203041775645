import ErrorIcon from '@mui/icons-material/Error';
import { Box, Button, CircularProgress, Divider, Stack, SxProps, Typography } from '@mui/material';
import { DataPresenter, WorkloadHeader } from '@studyo/components';
import { AgendaWorkloadViewModel } from '@studyo/viewmodels';
import { Observer, observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useStudyoServices } from '../../UseStudyoServicesHook';

export interface AgendaWorkloadViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: AgendaWorkloadViewModel;
}

export const AgendaWorkloadView = observer((props: AgendaWorkloadViewProps) => {
  const { localizationService } = useStudyoServices();
  const { sx = [], className, viewModel } = props;
  const [lastRefreshId, setLastRefreshId] = useState<number | undefined>();
  const [hasError, setHasError] = useState(false);
  const strings = localizationService.localizedStrings.studyo.agenda.workload;

  const onContentLoad = () => {
    setLastRefreshId(viewModel.urlRefreshId);
  };

  const onContentError = () => {
    setHasError(true);
  };

  const refreshContent = () => {
    setHasError(false);

    viewModel.refresh();
  };

  return (
    <DataPresenter
      sx={sx}
      className={className}
      dataLoaders={[viewModel.data]}
      defaultErrorMessage={localizationService.localizedStrings.studyo.agenda.errorMessage}
      renderData={() => (
        <Observer>
          {() => (
            <Stack
              sx={{
                width: '100%',
                height: '100%',
                overflow: 'hidden'
              }}
            >
              <Box>
                <WorkloadHeader viewModel={viewModel.header} />
              </Box>

              <Divider />

              <Stack
                sx={{
                  flex: 1,
                  position: 'relative'
                }}
              >
                {lastRefreshId != null && lastRefreshId !== viewModel.urlRefreshId && (
                  <Stack
                    spacing={2}
                    sx={{
                      alignItems: 'center',
                      justifyContent: 'center',
                      flex: 1
                    }}
                  >
                    <CircularProgress />
                    <Typography
                      variant="body2"
                      sx={{
                        color: (theme) => theme.palette.text.secondary
                      }}
                    >
                      {strings.loadingTitle}
                    </Typography>
                  </Stack>
                )}

                {hasError && (
                  <Stack
                    spacing={2}
                    sx={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0
                    }}
                  >
                    <ErrorIcon fontSize="large" color="error" />
                    <Typography variant="body1">{strings.errorMessage}</Typography>

                    <Button variant="contained-grey" onClick={refreshContent} sx={{ minWidth: 250 }}>
                      {strings.refresh}
                    </Button>
                  </Stack>
                )}

                <iframe
                  src={viewModel.url}
                  style={{ border: 'none', flex: 1 }}
                  onLoad={onContentLoad}
                  onError={onContentError}
                />
              </Stack>
            </Stack>
          )}
        </Observer>
      )}
    />
  );
});
