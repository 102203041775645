import { SxProps, Typography } from '@mui/material';
import { DialogCancelled } from '@shared/services';
import { isFailedPreconditionError } from '@shared/services/transports';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import { useStudyoServices } from '../../UseStudyoServicesHook';
import { DialogActionButtons } from './DialogActionButtons.tsx';
import { DialogButton } from './DialogButton.tsx';
import { DialogHeader } from './DialogHeader.tsx';
import { ResponsiveDialog } from './ResponsiveDialog.tsx';

export type SaveDialogPromise = Promise<void | DialogCancelled>;

export function useSaveDialog(
  action: () => SaveDialogPromise
): [SaveDialogPromise | undefined, () => void, () => void, () => SaveDialogPromise] {
  const [promise, setPromise] = useState<Promise<void | DialogCancelled> | undefined>(undefined);
  return [
    promise,
    () => setPromise(action()),
    () => setPromise(undefined),
    () => {
      const newPromise = action();
      setPromise(newPromise);
      return newPromise;
    }
  ];
}

export function useSaveDialogWithParam<T>(
  initialValue: T,
  action: (value: T) => SaveDialogPromise
): [SaveDialogPromise | undefined, (value: T) => void, () => void, () => SaveDialogPromise] {
  const [promiseAndValue, setPromiseAndValue] = useState<{
    promise: Promise<void | DialogCancelled> | undefined;
    value: T;
  }>({ promise: undefined, value: initialValue });

  return [
    promiseAndValue.promise,
    (value: T) => {
      setPromiseAndValue({ promise: action(value), value });
    },
    () => setPromiseAndValue({ promise: undefined, value: initialValue }),
    () => {
      const newPromise = action(promiseAndValue.value);
      setPromiseAndValue({ promise: newPromise, value: promiseAndValue.value });
      return newPromise;
    }
  ];
}

export interface StateDependantStrings {
  saving: string;
  saved: string;
  error: string;
  permanentError?: string;
}

export interface SaveDialogProps {
  sx?: SxProps;
  className?: string;
  onClose: (success: boolean) => void;
  promise?: Promise<void | DialogCancelled>;
  retryCall: () => Promise<void | DialogCancelled>;
  titles: StateDependantStrings;
  descriptions: StateDependantStrings;
}

type SaveDialogState = 'saving' | 'error' | 'permanentError' | 'saved';

function getStateDependantString(strings: StateDependantStrings, state: SaveDialogState): string {
  return strings[state] ?? (state === 'permanentError' ? strings.error : '');
}

export const SaveDialog = observer((props: SaveDialogProps) => {
  const { localizationService } = useStudyoServices();
  const { onClose, titles, retryCall, promise, descriptions, sx = [], className } = props;
  const strings = localizationService.localizedStrings.studyo.utils.saveDialog;

  const [state, setState] = useState<SaveDialogState>('saving');

  const executeAction = async (p: Promise<void | DialogCancelled>) => {
    try {
      setState('saving');
      const result = await p;
      setState('saved');
      onClose(result !== 'cancelled');
    } catch (e) {
      setState(isFailedPreconditionError(e) ? 'permanentError' : 'error');
    }
  };

  const retry = () => {
    void executeAction(retryCall());
  };

  useEffect(() => {
    if (promise != null) {
      void executeAction(promise);
    }
  }, [promise]);

  const title = useMemo(() => getStateDependantString(titles, state), [titles, state]);
  const description = useMemo(() => getStateDependantString(descriptions, state), [descriptions, state]);

  return (
    <ResponsiveDialog
      sx={sx}
      className={className}
      open={promise != null}
      onClose={() => {
        if (state !== 'saved') {
          return;
        }

        onClose(true);
      }}
      maxWidth="xs"
      fullWidth={true}
      supportsFullScreen={false}
    >
      <DialogHeader title={title} />
      <Typography
        sx={{
          p: 3
        }}
      >
        {description}
      </Typography>
      <DialogActionButtons backgroundColor="transparent">
        {state === 'error' && (
          <DialogButton type="cancel" title={strings.retry} onPress={retry} sx={{ minWidth: 75 }} />
        )}
        {(state === 'error' || state === 'permanentError') && (
          <DialogButton
            type="normal"
            variant="contained"
            title={strings.ok}
            onPress={() => onClose(false)}
            sx={{ minWidth: 75 }}
          />
        )}
      </DialogActionButtons>
    </ResponsiveDialog>
  );
});
