import { SxProps } from '@mui/material';
import { useTrackWebPage } from '@studyo/services';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { AgendaYearlyView } from '../../../views';

export interface AgendaYearlyScreenProps {
  sx?: SxProps;
  className?: string;
}

export const AgendaYearlyScreen = observer((props: AgendaYearlyScreenProps) => {
  const { viewModelFactory } = useStudyoServices();
  const { sx = [], className } = props;
  const params = useParams();
  const configId = params.configId ?? '';
  const accountId = params.accountId ?? '';

  const viewModel = useMemo(() => viewModelFactory.createAgendaYearly(), [configId, accountId]);
  useTrackWebPage('Yearly view');

  return <AgendaYearlyView sx={sx} className={className} viewModel={viewModel} />;
});
