import { CardActionArea, SxProps, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';

export interface ContentAttachmentAddTypeOptionProps {
  sx?: SxProps;
  className?: string;
  title: string;
  icon: { case: 'named'; value: string } | { case: 'component'; value: (size: number) => ReactNode };
  onPress?: () => void;
}

export const ContentAttachmentAddTypeOption = observer((props: ContentAttachmentAddTypeOptionProps) => {
  const { sx = [], className, title, icon, onPress } = props;

  return (
    <CardActionArea
      sx={{
        ...sx,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        p: 1,
        height: 125,
        width: 125,
        borderRadius: 1
      }}
      className={className}
      disabled={onPress == null}
      onClick={() => onPress?.()}
    >
      {icon.case === 'named' ? <img src={icon.value} style={{ width: 40, height: 40 }} /> : icon.value(40)}
      <Typography
        variant="body2"
        sx={{
          textAlign: 'center',
          mt: 1
        }}
      >
        {title}
      </Typography>
    </CardActionArea>
  );
});
