import { Box, Menu, MenuItem, SxProps } from '@mui/material';
import { DialogResult } from '@shared/services';
import { DialogActionButtons, DialogButton, ResponsiveDialog } from '@studyo/components';
import { observer } from 'mobx-react-lite';
import { useMemo, useRef, useState } from 'react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { PlannerSectionInfoView } from '../../../views';

export interface PlannerSectionInfoScreenProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  sectionId: string;
}

export const PlannerSectionInfoScreen = observer((props: PlannerSectionInfoScreenProps) => {
  const { localizationService, viewModelFactory } = useStudyoServices();
  const { sx = [], className, sectionId, onCancel, onSuccess } = props;
  const viewModel = useMemo(
    () => viewModelFactory.createPlannerSectionInfo(sectionId, onSuccess!, onCancel!),
    [sectionId]
  );
  const strings = localizationService.localizedStrings.studyo.agenda.planner.sectionInfo;

  const [displayMoreMenu, setDisplayMoreMenu] = useState(false);
  const moreButtonRef = useRef<HTMLDivElement | null>();

  const sendToAllButtonPressed = () => {
    setDisplayMoreMenu(true);
  };

  const sendToAllStudents = () => {
    setDisplayMoreMenu(false);
    viewModel.sendEmailToAll('student');
  };

  const sendToAllParents = () => {
    setDisplayMoreMenu(false);
    viewModel.sendEmailToAll('parent');
  };

  const sendToAll = () => {
    setDisplayMoreMenu(false);
    viewModel.sendEmailToAll(undefined);
  };

  return (
    <ResponsiveDialog
      sx={sx}
      className={className}
      maxWidth="sm"
      fullWidth={true}
      open={true}
      maxHeight="large"
      onClose={() => viewModel.dismiss()}
    >
      <PlannerSectionInfoView sx={{ flex: 1 }} viewModel={viewModel} />
      <DialogActionButtons>
        <div ref={(ref) => (moreButtonRef.current = ref)}>
          <DialogButton title={strings.more} onPress={sendToAllButtonPressed} type="normal" />
        </div>

        <Box
          sx={{
            flex: 1
          }}
        />
        <DialogButton title={strings.close} type="normal" onPress={() => viewModel.dismiss()} minimumScreenSize="sm" />
      </DialogActionButtons>
      <Menu open={displayMoreMenu} anchorEl={moreButtonRef.current} onClose={() => setDisplayMoreMenu(false)}>
        <MenuItem onClick={sendToAllStudents}>{strings.sendEmailAllStudents}</MenuItem>
        <MenuItem onClick={sendToAllParents}>{strings.sendEmailAllParents}</MenuItem>
        <MenuItem onClick={sendToAll}>{strings.sendEmailAll}</MenuItem>
      </Menu>
    </ResponsiveDialog>
  );
});
