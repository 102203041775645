import { SxProps } from '@mui/material';
import { DataPresenter } from '@studyo/components';
import { useTrackWebPage } from '@studyo/services';
import { PeriodsView } from '@studyo/views';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useStudyoServices } from '../../../UseStudyoServicesHook';

export interface AgendaPeriodsScreenProps {
  sx?: SxProps;
  className?: string;
}

export const AgendaPeriodsScreen = observer((props: AgendaPeriodsScreenProps) => {
  const { localizationService, viewModelFactory } = useStudyoServices();
  const { sx = [], className } = props;
  const params = useParams();
  const configId = params.configId ?? '';
  const accountId = params.accountId ?? '';

  const viewModel = useMemo(() => viewModelFactory.createAgendaPeriods(), [configId, accountId]);
  useTrackWebPage('Periods view');

  return (
    <DataPresenter
      sx={sx}
      className={className}
      dataLoaders={[viewModel.data]}
      defaultErrorMessage={localizationService.localizedStrings.studyo.agenda.errorMessage}
      renderData={() => <PeriodsView sx={{ height: '100%', width: '100%' }} viewModel={viewModel} />}
    />
  );
});
