import { Edit } from '@mui/icons-material';
import { InputBase, Paper, Stack, SxProps, Typography } from '@mui/material';
import { isSxArray } from '@shared/components/utils';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { ListSection } from '../../../components';
import { PlannerPeriodInfoViewModel } from '../../../viewmodels';

export interface PlannerPeriodInfoViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: PlannerPeriodInfoViewModel;
}

export const PlannerPeriodInfoView = observer((props: PlannerPeriodInfoViewProps) => {
  const { localizationService } = useStudyoServices();
  const { sx = [], className, viewModel } = props;
  const strings = localizationService.localizedStrings.studyo.agenda.planner.periodInfoStrings;

  useEffect(() => {
    viewModel.onInit();
    return () => viewModel.onDestroy();
  }, []);

  return (
    <Stack
      className={className}
      sx={[
        {
          overflow: 'hidden'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <ListSection
        title={strings.periodInfoSectionTitle}
        icon={viewModel.canEdit ? <Edit fontSize="small" /> : undefined}
        onIconPressed={() => void viewModel.openEditPeriod()}
        iconIsDisabled={viewModel.isNoteDirty}
      >
        <Stack
          spacing={0.5}
          sx={{
            mx: 2
          }}
        >
          <Stack direction="row" spacing={1}>
            <Typography
              sx={{
                flex: 1
              }}
            >
              {strings.classOfTerm(viewModel.occurrenceInTerm)}
            </Typography>
            <Typography>{viewModel.sectionNumber}</Typography>
          </Stack>

          <Stack direction="row" spacing={1}>
            <Typography
              sx={{
                flex: 1
              }}
            >
              {strings.classLeftInYear(viewModel.occurrenceLeftInYear)}
            </Typography>
            <Typography>{viewModel.roomName}</Typography>
          </Stack>

          <Stack direction="row" spacing={1}>
            <Typography
              sx={{
                flex: 1
              }}
            >
              {strings.classOfTerm(viewModel.occurrenceLeftInTerm)}
            </Typography>
          </Stack>
        </Stack>
      </ListSection>
      <ListSection sx={{ flex: 1 }} title={strings.noteSectionTitle}>
        <Paper
          sx={{
            flex: 1,
            px: 2,
            py: 1,
            borderWidth: 0,
            borderRadius: 0,
            overflow: 'auto',
            backgroundColor: 'transparent'
          }}
          elevation={0}
        >
          <InputBase
            sx={{ height: '100%', width: '100%', fontSize: '1em', alignItems: 'flex-start' }}
            placeholder={strings.notePlaceholder(viewModel.canEdit)}
            value={viewModel.notes}
            onChange={(e) => (viewModel.notes = e.currentTarget.value)}
            multiline
            minRows={0}
            disabled={!viewModel.canEdit}
          />
        </Paper>
      </ListSection>
    </Stack>
  );
});
