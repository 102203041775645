import { ExpandMore } from '@mui/icons-material';
import {
  Box,
  CardActionArea,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Popover,
  SxProps,
  useTheme
} from '@mui/material';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { ReactNode, useState } from 'react';

export interface MultiDropDownSelectorProps {
  sx?: SxProps;
  className?: string;
  options: string[];
  selectedIndexes: number[];
  didSelectOptions: (index: number[]) => void;
  renderOption?: (option: string, index: number) => ReactNode;
  displayIconForOption?: (option: string, index: number) => ReactNode;
  displayValueForOption?: (option: string, index: number) => string;
  renderPreview: (options: string[], indexes: number[]) => ReactNode;
  renderOptions?: (options: string[], onSelectItem: (index: number) => void) => ReactNode;
  maxHeight?: number;
  disabled?: boolean;
}

export const MultiDropDownSelector = observer(
  ({
    sx = [],
    className,
    selectedIndexes,
    didSelectOptions,
    renderOption,
    options,
    renderOptions,
    renderPreview,
    maxHeight,
    displayIconForOption,
    displayValueForOption,
    disabled
  }: MultiDropDownSelectorProps) => {
    const theme = useTheme();
    const [selectMenuAnchor, setSelectMenuAnchor] = useState<HTMLButtonElement | null>(null);
    const selectedOptions = selectedIndexes.map((i) => options.at(i)!);

    const selectOptionAtIndex = (index: number) => {
      let newIndexes = selectedIndexes;
      if (newIndexes.includes(index)) {
        newIndexes = selectedIndexes.filter((i) => i != index);
      } else {
        newIndexes = _.orderBy(selectedIndexes.concat(index), (i) => i);
      }

      didSelectOptions(newIndexes);
    };

    const renderItem = (option: string, index: number) => {
      if (renderOption != null) {
        return renderOption(option, index);
      } else if (displayValueForOption != null) {
        return (
          <>
            {displayIconForOption != null && <ListItemIcon>{displayIconForOption(option, index)}</ListItemIcon>}
            <ListItemText primary={displayValueForOption(option, index)} />{' '}
          </>
        );
      }

      return option;
    };

    return (
      <Box>
        <CardActionArea
          sx={{ ...sx, display: 'flex', flexDirection: 'row', pl: 1, pr: 0, py: 0.5, borderRadius: 1, minHeight: 30 }}
          className={className}
          onClick={(e) => setSelectMenuAnchor(e.currentTarget)}
          disabled={disabled}
        >
          {renderPreview(selectedOptions, selectedIndexes)}

          <ExpandMore fontSize="small" sx={{ color: theme.palette.text.secondary, ml: 1 }} />
        </CardActionArea>

        {renderOptions != null ? (
          <Popover
            open={selectMenuAnchor != null}
            anchorEl={selectMenuAnchor}
            onClose={() => setSelectMenuAnchor(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            {renderOptions(options, selectOptionAtIndex)}
          </Popover>
        ) : (
          <Menu
            open={selectMenuAnchor != null}
            anchorEl={selectMenuAnchor}
            onClose={() => setSelectMenuAnchor(null)}
            slotProps={{ paper: { sx: { minWidth: 250, maxHeight } } }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            {options.map((v, i) => (
              <MenuItem key={v} onClick={() => selectOptionAtIndex(i)} selected={selectedIndexes.includes(i)}>
                {renderItem(v, i)}
              </MenuItem>
            ))}
          </Menu>
        )}
      </Box>
    );
  }
);
