import { CalendarMonth } from '@mui/icons-material';
import { Box, Button, Divider, IconButton, Popover, Stack, SxProps } from '@mui/material';
import { SchoolDay } from '@shared/models/calendar';
import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';
import { useStudyoServices } from '../../UseStudyoServicesHook';
import { DateSwitcherContext } from '../../viewmodels';
import { SchoolDayPicker } from './SchoolDayPicker';

export interface DateSwitcherProps {
  sx?: SxProps;
  className?: string;
  context: DateSwitcherContext;
}

export const DateSwitcher = observer((props: DateSwitcherProps) => {
  const { localizationService, viewModelFactory } = useStudyoServices();
  const { sx = [], className, context } = props;
  const viewModel = useMemo(() => viewModelFactory.createDateSwitcher(context), [context]);
  const [dateSwitcherButtonRef, setDateSwitcherButtonRef] = useState<HTMLButtonElement | null>(null);

  const goToToday = () => {
    viewModel.goToToday();
    hideDateSwitcher();
  };

  const goToSchoolDay = (schoolDay: SchoolDay) => {
    viewModel.goToDay(schoolDay.day);
    hideDateSwitcher();
  };

  const hideDateSwitcher = () => {
    setDateSwitcherButtonRef(null);
  };

  return (
    <Box sx={sx} className={className}>
      <IconButton color="primary" size="small" onClick={(e) => setDateSwitcherButtonRef(e.currentTarget)}>
        <CalendarMonth fontSize="medium" />
      </IconButton>
      <Popover
        open={dateSwitcherButtonRef != null}
        anchorEl={dateSwitcherButtonRef}
        onClose={hideDateSwitcher}
        slotProps={{ paper: { sx: { height: 400, width: { xs: '100%', sm: 450 } } } }}
      >
        <Stack sx={{ height: '100%', width: '100%' }}>
          <SchoolDayPicker
            sx={{ flex: 1 }}
            viewModel={viewModel.schoolDayPickerViewModel}
            onSelection={goToSchoolDay}
            backgroundColor="transparent"
          />

          <Divider variant="middle" />

          <Stack
            direction="row"
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              px: 2,
              py: 1
            }}
          >
            <Button onClick={goToToday}>
              {localizationService.localizedStrings.studyo.utils.dateSwitcherGoToToday}
            </Button>
          </Stack>
        </Stack>
      </Popover>
    </Box>
  );
});
