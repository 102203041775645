import { Stack, SxProps, Typography } from '@mui/material';
import { AllDayOfWeek } from '@shared/models/types';
import { observer } from 'mobx-react-lite';
import { useStudyoServices } from '../../../UseStudyoServicesHook';

export interface YearMonthDayOfWeeksProps {
  sx?: SxProps;
  className?: string;
}

export const YearMonthDayOfWeeks = observer((props: YearMonthDayOfWeeksProps) => {
  const { localizationService } = useStudyoServices();
  const { sx = [], className } = props;

  return (
    <Stack direction="row" sx={sx} className={className}>
      {AllDayOfWeek.map((dow) => (
        <Typography
          key={`dow-${dow}`}
          variant="body2"
          sx={{
            textAlign: 'center',
            flexGrow: 1,
            flexBasis: 0,
            flexShrink: 0,
            color: (theme) => theme.palette.text[dow === 'sunday' || dow === 'saturday' ? 'disabled' : 'secondary'],
            userSelect: 'none'
          }}
        >
          {localizationService.localizedStrings.models.dayOfWeek.singleLetterDayOfWeek(dow)}
        </Typography>
      ))}
    </Stack>
  );
});
