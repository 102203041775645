import { Check } from '@mui/icons-material';
import { SxProps } from '@mui/material';
import { DialogResult } from '@shared/services';
import {
  ConfirmDialog,
  DialogActionButtons,
  DialogButton,
  ResponsiveDialog,
  SaveDialog,
  useSaveDialog
} from '@studyo/components';
import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { DialogHeader } from '../../../components';
import { CourseSelectionView } from '../../../views';

export interface CourseSelectionScreenProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
}

export const CourseSelectionScreen = observer((props: CourseSelectionScreenProps) => {
  const { localizationService, viewModelFactory } = useStudyoServices();
  const { sx = [], className, onCancel, onSuccess } = props;
  const strings = localizationService.localizedStrings.studyo.agenda.setting.courseSelection;
  const viewModel = useMemo(() => viewModelFactory.createCourseSelection(onSuccess!, onCancel!), []);
  const [displayCancelChangesDialog, setDisplayCancelChangesDialog] = useState(false);
  const [savePromise, startSave, resetSave, retrySave] = useSaveDialog(() => viewModel.save());

  const cancelButtonPressed = () => {
    if (viewModel.hasChanges) {
      showCancelConfirmationDialog();
    } else {
      viewModel.dismissModal(true);
    }
  };

  const cancel = () => {
    hideCancelConfirmationDialog();
    viewModel.dismissModal(true);
  };

  const showCancelConfirmationDialog = () => {
    setDisplayCancelChangesDialog(true);
  };

  const hideCancelConfirmationDialog = () => {
    setDisplayCancelChangesDialog(false);
  };

  const saveDialogClose = (success: boolean) => {
    resetSave();
    if (success) {
      viewModel.dismissModal(false);
    }
  };

  return (
    <ResponsiveDialog
      sx={sx}
      className={className}
      maxWidth="sm"
      fullWidth={true}
      open={true}
      maxHeight="large"
      onClose={cancelButtonPressed}
    >
      <DialogHeader
        title={strings.title}
        subtitle={viewModel.activeConfigSchool}
        onClose={cancelButtonPressed}
        rightButton={{
          icon: (size) => <Check fontSize={size} color="inherit" />,
          action: startSave,
          onlyShowOnExtraSmallScreen: true
        }}
      />

      <CourseSelectionView sx={{ flex: 1 }} viewModel={viewModel} />

      <DialogActionButtons minimumScreenSize="sm">
        <DialogButton title={strings.cancel} type="cancel" onPress={cancelButtonPressed} />
        <DialogButton title={strings.save} type="normal" variant="contained" onPress={startSave} />
      </DialogActionButtons>

      <ConfirmDialog
        open={displayCancelChangesDialog}
        title={strings.unsavedChangesTitle}
        description={strings.unsavedChangesDescription}
        cancelTitle={strings.unsavedChangesStayTitle}
        onCancelPress={hideCancelConfirmationDialog}
        confirmTitle={strings.unsavedChangesDiscardTitle}
        onConfirmPress={cancel}
        onClose={hideCancelConfirmationDialog}
      />

      <SaveDialog
        promise={savePromise}
        retryCall={retrySave}
        onClose={saveDialogClose}
        titles={{
          saving: strings.savingTitle,
          saved: strings.savedTitle,
          error: strings.saveErrorTitle
        }}
        descriptions={{
          saving: strings.savingDescription,
          saved: strings.savedDescription,
          error: strings.saveErrorDescription,
          permanentError: strings.savePermanentErrorDescription
        }}
      />
    </ResponsiveDialog>
  );
});
