import { SxProps } from '@mui/material';
import { AllContentWorkloadLevels, ContentWorkloadLevel } from '@shared/models/types';
import { observer } from 'mobx-react-lite';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { TaskEditViewModel } from '../../../viewmodels';
import { DropDownSelector } from '../../utils';

export interface TaskEditWorkloadSelectorProps {
  sx?: SxProps;
  className?: string;
  viewModel: TaskEditViewModel;
}

export const TaskEditWorkloadSelector = observer((props: TaskEditWorkloadSelectorProps) => {
  const { localizationService } = useStudyoServices();
  const { sx = [], className, viewModel } = props;

  return (
    <DropDownSelector
      sx={sx}
      className={className}
      options={AllContentWorkloadLevels}
      selectedIndex={AllContentWorkloadLevels.indexOf(viewModel.workloadLevel)}
      didSelectOption={(index) => (viewModel.workloadLevel = AllContentWorkloadLevels[index])}
      displayValueForOption={(option) =>
        localizationService.localizedStrings.models.contents.workloadLevelString(option as ContentWorkloadLevel)
      }
    />
  );
});
