import { Box, Stack, SxProps, Typography, useTheme } from '@mui/material';

import { AllDayOfWeek } from '@shared/models/types';
import { observer } from 'mobx-react-lite';
import { useStudyoServices } from '../../../UseStudyoServicesHook';

export interface MonthDayOfWeekProps {
  sx?: SxProps;
  className?: string;
}

export const MonthDayOfWeek = observer((props: MonthDayOfWeekProps) => {
  const { localizationService } = useStudyoServices();
  const { sx = [], className } = props;
  const theme = useTheme();

  return (
    <Stack
      direction="row"
      className={className}
      sx={{
        padding: 1,
        ...sx,
        backgroundColor: theme.studyo.agenda.month.dayOfWeekHeaderColor
      }}
    >
      {AllDayOfWeek.map((dow) => (
        <Box
          key={`month-dow-header-${dow}`}
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            margin: 0.5,
            display: 'flex',
            flexGrow: 1,
            flexBasis: 0
          }}
        >
          <Typography variant="body2">
            {localizationService.localizedStrings.models.dayOfWeek.localizedDayOfWeek(dow)}
          </Typography>
        </Box>
      ))}
    </Stack>
  );
});
