import { ListItemIcon, SxProps, useTheme } from '@mui/material';
import { TintedImage } from '@shared/rxp/tinted-image';
import { observer } from 'mobx-react-lite';

export interface TintedListItemIconProps {
  sx?: SxProps;
  className?: string;
  icon: string;
  isTextPrimaryColor?: boolean;
}

export const TintedListItemIcon = observer(
  ({ sx = [], className, icon, isTextPrimaryColor = false }: TintedListItemIconProps) => {
    const theme = useTheme();
    let color = theme.palette.mode === 'light' ? theme.palette.text.secondary : theme.palette.text.primary;
    if (isTextPrimaryColor) {
      color = theme.palette.text.primary;
    }

    return (
      <ListItemIcon sx={sx} className={className}>
        <TintedImage source={icon} color={color} sx={{ width: 24, height: 24 }} />
      </ListItemIcon>
    );
  }
);
