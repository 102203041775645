import { alpha, Stack, SxProps, Typography, useTheme } from '@mui/material';
import { isSxArray } from '@shared/components/utils';
import { OnboardingBackgroundImage, OnboardingContentBackgroundView } from '@studyo/components';
import { useTrackWebPage } from '@studyo/services';
import { LoginView } from '@studyo/views';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStudyoServices } from '../UseStudyoServicesHook';
import './styles/LoginScreen.style.scss';

export interface LoginScreenProps {
  sx?: SxProps;
  className?: string;
}

export const LoginScreen = observer((props: LoginScreenProps) => {
  const { viewModelFactory } = useStudyoServices();
  const { sx = [], className } = props;
  const viewModel = useMemo(() => viewModelFactory.createLogin(), []);
  const theme = useTheme();
  const footerTextColor = alpha(theme.palette.common.white, 0.87);
  const location = useLocation();
  const navigate = useNavigate();

  useTrackWebPage('Onboarding login');
  useEffect(() => {
    void viewModel.onInit(location, navigate);
  }, [viewModel]);

  return (
    <Stack
      className={className}
      sx={[
        {
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <OnboardingBackgroundImage />
      <div className="login-container">
        <Stack
          sx={{
            flex: 1,
            position: 'relative'
          }}
        >
          <OnboardingContentBackgroundView sx={{ zIndex: 1 }} />
          <LoginView viewModel={viewModel} sx={{ zIndex: 2 }} />
        </Stack>
      </div>
      <Stack
        sx={{
          alignItems: 'center',
          mt: 1
        }}
      >
        <Typography
          variant="caption"
          sx={{
            color: footerTextColor,
            zIndex: 2
          }}
        >
          {viewModel.versionNumber}
        </Typography>
        <Typography
          variant="caption"
          sx={{
            color: footerTextColor,
            zIndex: 2
          }}
        >
          {viewModel.copyright}
        </Typography>
      </Stack>
    </Stack>
  );
});
