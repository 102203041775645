import { Checkbox, Divider, List, ListItemButton, ListItemIcon, ListItemText, Radio, SxProps } from '@mui/material';
import { ScrollShadowWrapper } from '@shared/components/utils';
import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';
import { DataPresenter, ListSection } from '../../components';
import { OptionMenuViewModel } from '../../viewmodels';

export interface OptionMenuViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: OptionMenuViewModel;
}

export const OptionMenuView = observer((props: OptionMenuViewProps) => {
  const { sx = [], className, viewModel } = props;

  return (
    <DataPresenter
      sx={{ ...sx, overflow: 'hidden' }}
      className={className}
      dataLoaders={[viewModel.preferences]}
      renderData={() => (
        <ScrollShadowWrapper sx={{ width: '100%', height: '100%' }}>
          <List>
            {viewModel.sections.map((section, sectionIndex) => (
              <ListSection key={`${sectionIndex}-section`} title={section.title}>
                {section.data.map((element, elementIndex) => (
                  <Fragment key={`${sectionIndex}-${elementIndex}`}>
                    <ListItemButton onClick={() => element.onSelection()}>
                      <ListItemIcon>
                        {section.selectionType === 'single-selection' && (
                          <Radio checked={element.isSelected} disableRipple />
                        )}
                        {section.selectionType !== 'single-selection' && (
                          <Checkbox checked={element.isSelected} disableRipple />
                        )}
                      </ListItemIcon>
                      <ListItemText primary={element.title} />
                    </ListItemButton>

                    {elementIndex < section.data.length - 1 && <Divider />}
                  </Fragment>
                ))}
              </ListSection>
            ))}
          </List>
        </ScrollShadowWrapper>
      )}
    />
  );
});
