import { HighlightOff } from '@mui/icons-material';
import { Box, ListItemButton, ListItemText, Stack, SxProps, Typography, useTheme } from '@mui/material';
import { SectionColors, SectionShadowColors } from '@shared/models/Colors';
import { observer } from 'mobx-react-lite';
import { PeriodPrioritySelectorElementViewModel } from '../../../viewmodels';

export interface PeriodPrioritySelectorElementProps {
  sx?: SxProps;
  className?: string;
  element: PeriodPrioritySelectorElementViewModel;
}

export const PeriodPrioritySelectorElement = observer((props: PeriodPrioritySelectorElementProps) => {
  const { sx = [], className, element } = props;
  const theme = useTheme();

  const fallbackBackgroundColor = theme.studyo.periods.freePeriodColor;
  const fallbackSelectedBackgroundColor = theme.studyo.periods.freePeriodShadowColor;
  const fallbackTextColor = theme.studyo.periods.freePeriodTextColor;

  const backgroundColor =
    element.color != null ? (SectionColors.get(element.color) ?? fallbackBackgroundColor) : fallbackBackgroundColor;

  const isSelectedBackgroundColor = element.isSelected
    ? element.color != null
      ? (SectionShadowColors.get(element.color) ?? fallbackSelectedBackgroundColor)
      : fallbackSelectedBackgroundColor
    : undefined;

  const textColor = element.color != null ? (SectionColors.get(element.color) ?? fallbackTextColor) : fallbackTextColor;

  return (
    <ListItemButton sx={sx} className={className} onClick={() => element.onPress()} disableGutters>
      <Stack
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          p: 1,
          flexShrink: 0,
          mr: 2,
          height: 72,
          backgroundColor
        }}
      >
        <Box sx={{ height: 24, width: 24, backgroundColor: isSelectedBackgroundColor, borderRadius: '50%' }}>
          {element.isSkipped && <HighlightOff sx={{ height: 24, width: 24, color: theme.palette.common.white }} />}
        </Box>
      </Stack>
      <ListItemText primary={element.title} secondary={element.subtitle} />
      <Box
        sx={{
          p: 2
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: textColor
          }}
        >
          {element.periodTag}
        </Typography>
      </Box>
    </ListItemButton>
  );
});
