import { AttachFile } from '@mui/icons-material';
import { ListItemButton, ListItemIcon, ListItemText, SxProps, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { TaskEditViewModel } from '../../../viewmodels';

export interface TaskEditAttachmentsCellProps {
  sx?: SxProps;
  className?: string;
  viewModel: TaskEditViewModel;
}

export const TaskEditAttachmentsCell = observer((props: TaskEditAttachmentsCellProps) => {
  const { localizationService } = useStudyoServices();
  const { sx = [], className, viewModel } = props;

  return (
    <ListItemButton sx={sx} className={className} onClick={() => void viewModel.showAttachments()}>
      <ListItemIcon>
        <AttachFile />
      </ListItemIcon>
      <ListItemText primary={localizationService.localizedStrings.studyo.contents.taskEdit.attachments} />

      <Typography variant="body2">{viewModel.numberOfAttachments}</Typography>
    </ListItemButton>
  );
});
