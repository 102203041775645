import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';
import { useSharedServices } from './UseSharedServicesHook.ts';

export interface ThemedScreenProps {
  children: ReactNode;
}

export const ThemedScreen = observer(({ children }: ThemedScreenProps) => {
  const { themeService } = useSharedServices();
  // For some reason, we need to create a new theme everytime. If we store two themes somewhere
  // and just change which one we pass to the ThemeProvider, the theme change is not propagated.
  const muiTheme = createTheme({ cssVariables: true, ...themeService.currentMuiThemeOptions });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={muiTheme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
});
