import { Divider, List, Stack, SxProps, useTheme } from '@mui/material';
import { isSxArray } from '@shared/components/utils';
import { SectionColors } from '@shared/models/Colors';
import { ContentActionHeader, LinkedTasksPublishOccurrenceCell, ListSection } from '@studyo/components';
import { LinkedTasksPublishViewModel } from '@studyo/viewmodels';
import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';

export interface LinkedTasksPublishViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: LinkedTasksPublishViewModel;
}

export const LinkedTasksPublishView = observer((props: LinkedTasksPublishViewProps) => {
  const { sx = [], className, viewModel } = props;
  const { content } = viewModel;
  const theme = useTheme();

  return (
    <Stack
      className={className}
      sx={[
        {
          overflow: 'hidden'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <ContentActionHeader content={content} />
      <List sx={{ flex: 1, overflow: 'auto' }} disablePadding dense>
        {viewModel.sections.map((section, sectionIndex) => (
          <ListSection
            title={section.displaySectionTitle}
            headerColor={
              section.sectionColor != null
                ? SectionColors.get(section.sectionColor)!
                : theme.studyo.periods.freePeriodColor
            }
            titleColor={
              section.sectionColor != null
                ? theme.studyo.periods.periodWithSectionTextColor
                : theme.studyo.periods.freePeriodTextColor
            }
            key={`task-section-${sectionIndex}`}
          >
            {section.occurrences.map((occurrence, occurrenceIndex) => (
              <Fragment key={`task-element-${sectionIndex}-${occurrenceIndex}`}>
                <LinkedTasksPublishOccurrenceCell
                  key={`task-element-${sectionIndex}-${occurrenceIndex}`}
                  viewModel={occurrence}
                />
                {occurrenceIndex < section.occurrences.length - 1 && <Divider />}
              </Fragment>
            ))}
          </ListSection>
        ))}
      </List>
    </Stack>
  );
});
