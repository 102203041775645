import { Check } from '@mui/icons-material';
import { Box, CardActionArea, List, Stack, SxProps, TextField, useTheme } from '@mui/material';
import { SectionColors } from '@shared/models/Colors';
import { AllColors, Color } from '@shared/models/types';
import { observer } from 'mobx-react-lite';
import { useStudyoServices } from '../../UseStudyoServicesHook';
import { ListSection } from '../../components';
import { ProfileEditViewModel } from '../../viewmodels';

export interface ProfileEditViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: ProfileEditViewModel;
}

export const ProfileEditView = observer((props: ProfileEditViewProps) => {
  const { localizationService } = useStudyoServices();
  const { sx = [], className, viewModel } = props;
  const strings = localizationService.localizedStrings.studyo.agenda.setting.profileEdit;
  const theme = useTheme();

  const renderColorElement = (color: Color) => {
    const backgroundColor = SectionColors.get(color)!;
    const checkmarkColor = theme.palette.getContrastText(backgroundColor);

    const onPress = () => (viewModel.color = color);
    const isSelected = viewModel.color === color;

    return (
      <CardActionArea
        key={`profile-edit-color-${color}`}
        sx={{ backgroundColor, width: 46, height: 46, borderRadius: 1, position: 'relative' }}
        onClick={onPress}
      >
        {isSelected && (
          <Check sx={{ color: checkmarkColor, width: 22, height: 22, position: 'absolute', right: 4, bottom: 4 }} />
        )}
      </CardActionArea>
    );
  };

  return (
    <List sx={sx} className={className}>
      <ListSection title={strings.generalInfoSectionTitle}>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={2}
          sx={{
            px: 2,
            py: 1
          }}
        >
          <TextField
            sx={{ flex: 1 }}
            variant="filled"
            value={viewModel.firstName}
            onChange={(e) => (viewModel.firstName = e.currentTarget.value)}
            autoFocus
            label={strings.firstnamePlaceholder}
            slotProps={{ input: { inputProps: { maxLength: 50 } } }}
          />

          <TextField
            sx={{ flex: 1 }}
            variant="filled"
            value={viewModel.lastName}
            onChange={(e) => (viewModel.lastName = e.currentTarget.value)}
            label={strings.lastnamePlaceholder}
            slotProps={{ input: { inputProps: { maxLength: 50 } } }}
          />
        </Stack>
      </ListSection>
      <ListSection title={strings.profileColorSectionTitle}>
        <Stack
          direction="row"
          sx={{
            px: 2,
            flexWrap: 'wrap'
          }}
        >
          {AllColors.map((color) => (
            <Box
              key={color}
              sx={{
                m: 1
              }}
            >
              {renderColorElement(color)}
            </Box>
          ))}
        </Stack>
      </ListSection>
    </List>
  );
});
