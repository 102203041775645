import { Box, SxProps } from '@mui/material';
import { MouseEvent, ReactNode, useRef } from 'react';

export interface DoubleClickViewProps {
  sx?: SxProps;
  className?: string;
  children: ReactNode;
  onPress?: () => void;
  onDoublePress?: () => void;
  delay?: number; // In milliseconds. Defaults to 250.
  propagateEvent?: boolean; // Defaults to false.
}

export function DoubleClickView(props: DoubleClickViewProps) {
  const { sx = [], delay, propagateEvent, onPress, onDoublePress, className, children } = props;
  const hasAction = onPress != null || onDoublePress != null;
  const clicksCounts = useRef(0);

  const onClick = (e: MouseEvent) => {
    if (propagateEvent === false) {
      e.stopPropagation();
    }

    clicksCounts.current = clicksCounts.current + 1;

    if (clicksCounts.current === 1) {
      setTimeout(() => {
        if (clicksCounts.current === 1) {
          if (onPress != null) {
            onPress();
          }
        } else {
          onDoublePress?.();
        }

        clicksCounts.current = 0;
      }, delay ?? 250);
    }
  };

  return (
    <Box
      onClick={hasAction ? onClick : undefined}
      sx={{
        ...sx,
        cursor: hasAction ? 'pointer' : undefined,
        WebkitTapHighlightColor: 'transparent',
        WebkitTouchCallout: 'none',
        WebkitUserSelect: 'none',
        KhtmlUserSelect: 'none',
        MozUserSelect: 'none',
        msUserSelect: 'none',
        userSelect: 'none'
      }}
      className={className}
    >
      {children}
    </Box>
  );
}
