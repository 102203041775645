import { Divider, List, ListItemButton, ListItemText, SxProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';
import { ListSection } from '../../components';
import { ConfigLinkListViewModel } from '../../viewmodels';

export interface ConfigLinkListViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: ConfigLinkListViewModel;
}

export const ConfigLinkListView = observer((props: ConfigLinkListViewProps) => {
  const { sx = [], className, viewModel } = props;

  return (
    <List sx={{ ...sx, overflow: 'auto' }} className={className}>
      <ListSection>
        {viewModel.links.map((link, i) => (
          <Fragment key={link.url}>
            <ListItemButton component="a" href={link.url} target="_blank">
              <ListItemText primary={link.text} />
            </ListItemButton>

            {i < viewModel.links.length - 1 && <Divider />}
          </Fragment>
        ))}
      </ListSection>
    </List>
  );
});
