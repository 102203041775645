import { SxProps } from '@mui/material';
import { DialogResult } from '@shared/services';
import { DialogActionButtons, DialogButton, ResponsiveDialog } from '@studyo/components';
import { OptionMenuView } from '@studyo/views';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { DialogHeader } from '../../../components';

export interface DailyOptionMenuScreenProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
}

export const DailyOptionMenuScreen = observer((props: DailyOptionMenuScreenProps) => {
  const { localizationService, viewModelFactory } = useStudyoServices();
  const { className, sx = [], onSuccess, onCancel } = props;
  const viewModel = useMemo(() => viewModelFactory.createDailyOptionMenu(onSuccess!, onCancel!), [onSuccess, onCancel]);
  const strings = localizationService.localizedStrings.studyo.agenda.daily.optionMenu;

  return (
    <ResponsiveDialog
      sx={sx}
      className={className}
      maxWidth="xs"
      fullWidth={true}
      open={true}
      onClose={() => viewModel.dismiss()}
      maxHeight="large"
    >
      <DialogHeader title={strings.title} onClose={() => viewModel.dismiss()} />

      <OptionMenuView sx={{ flex: 1 }} viewModel={viewModel} />

      <DialogActionButtons minimumScreenSize="sm">
        <DialogButton title={strings.close} type="normal" onPress={() => viewModel.dismiss()} />
      </DialogActionButtons>
    </ResponsiveDialog>
  );
});
