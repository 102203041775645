import { Box, ListItemButton, ListItemText, SxProps, useTheme } from '@mui/material';
import { TintedImage } from '@shared/rxp/tinted-image';
import { observer } from 'mobx-react-lite';
import { useStudyoServices } from '../../../UseStudyoServicesHook';

export interface ProfileMenuCreateConfigRowProps {
  sx?: SxProps;
  className?: string;
  action: () => void;
}

export const ProfileMenuCreateConfigRow = observer((props: ProfileMenuCreateConfigRowProps) => {
  const { imageService, localizationService } = useStudyoServices();
  const { sx = [], className, action } = props;
  const title = localizationService.localizedStrings.studyo.agenda.setting.profileMenu.useCode;
  const icon = imageService.studyoImages.settings.profileMenu.useSchoolCode;
  const theme = useTheme();

  return (
    <ListItemButton sx={sx} className={className} onClick={() => action()}>
      <Box
        sx={{
          mx: 2
        }}
      >
        <TintedImage source={icon} sx={{ width: 40, height: 40 }} color={theme.palette.text.primary} />
      </Box>
      <ListItemText primary={title} />
    </ListItemButton>
  );
});
