import { WorkloadImpactSummary } from '@buf/studyo_studyo.bufbuild_es/studyo/type_workload_pb';
import { Edit } from '@mui/icons-material';
import { Box, Button, Divider, List, ListItemText, Menu, MenuItem, Popover, Stack, SxProps } from '@mui/material';
import { AutoSizer } from '@shared/components/layout';
import { isSxArray, ScrollShadowWrapper } from '@shared/components/utils';
import { SchoolDay } from '@shared/models/calendar';
import { Day } from '@shared/models/types';
import {
  ListSection,
  ObservablePresenter,
  SchoolDayPicker,
  TaskDateIconBoxType,
  TaskDueBox,
  TaskEditAttachmentsCell,
  TaskEditIsPrivateStateCell,
  TaskEditNotesCell,
  TaskEditPublishingImpactCell,
  TaskEditPublishOnCreationCell,
  TaskEditSectionCell,
  TaskEditTitleCell,
  TaskEditWorkloadCell,
  TaskStepList
} from '@studyo/components';
import { AvailablePeriodTag, TaskEditViewModel } from '@studyo/viewmodels';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useStudyoServices } from '../../UseStudyoServicesHook';

export interface TaskEditViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: TaskEditViewModel;
}

export const TaskEditView = observer((props: TaskEditViewProps) => {
  const { localizationService, viewModelFactory } = useStudyoServices();
  const { sx = [], className, viewModel } = props;
  const strings = localizationService.localizedStrings.studyo.contents.taskEdit;

  const datesContainerWidth = useRef(0);
  const [datesMenusCenterAnchor, setDatesMenusCenterAnchor] = useState(0);
  const taskDueBoxRef = useRef<HTMLDivElement | null>();

  const schoolDayPickerViewModel = useMemo(() => viewModelFactory.createSchoolDayPicker(), []);
  const [dateSelectionType, setDateSelectionType] = useState<TaskDateIconBoxType | undefined>(undefined);
  const [displayPeriodTagMenu, setDisplayPeriodTagMenu] = useState(false);
  const [selectedDay, setSelectedDay] = useState<Day | undefined>(undefined);

  useEffect(() => {
    viewModel.onInit();

    const highlightedDaysUpdateDisposer = autorun(() => {
      schoolDayPickerViewModel.setHighlightedDays(viewModel.daysWithSectionOccurring);
    });

    return () => {
      highlightedDaysUpdateDisposer();
      viewModel.onDestroy();
    };
  }, [viewModel]);

  useEffect(() => {
    if (selectedDay != null) {
      schoolDayPickerViewModel.setCurrentDay(selectedDay);
      schoolDayPickerViewModel.setSelectedDay(selectedDay);
    }
  }, [selectedDay]);

  const onAssignmentDatePressed = () => {
    setDatesMenusCenterAnchor(datesContainerWidth.current * 0.125);
    setDateSelectionType('announced');

    const dates = viewModel.datesViewModel;
    setSelectedDay(dates.assignmentDay);
    schoolDayPickerViewModel.setMinimumAndMaximumDays(undefined, dates.dueDay);
  };

  const onPlannedDatePressed = () => {
    setDatesMenusCenterAnchor(datesContainerWidth.current * 0.375);
    setDateSelectionType('planned');

    const dates = viewModel.datesViewModel;
    setSelectedDay(dates.plannedDay);
    schoolDayPickerViewModel.setMinimumAndMaximumDays(dates.assignmentDay, dates.dueDay);
  };

  const onDueDatePressed = () => {
    setDatesMenusCenterAnchor(datesContainerWidth.current * 0.625);
    setDateSelectionType('due');

    const dates = viewModel.datesViewModel;
    setSelectedDay(dates.dueDay);
    schoolDayPickerViewModel.setMinimumAndMaximumDays(undefined, viewModel.maximumDueDate);
  };

  const onPeriodTagPressed = () => {
    setDatesMenusCenterAnchor(datesContainerWidth.current * 0.875);
    setDisplayPeriodTagMenu(true);
  };

  const didSelectDate = (schoolDay: SchoolDay) => {
    const vm = viewModel.datesViewModel;

    switch (dateSelectionType) {
      case 'announced':
        vm.assignmentDay = schoolDay.day;
        break;

      case 'planned':
        vm.plannedDay = schoolDay.day;
        break;

      case 'due':
        vm.dueDay = schoolDay.day;
        break;
    }

    dismissDateSelection();
  };

  const didSelectTagAtIndex = (index: number) => {
    viewModel.periodTag = periodTagOptions[index].tag;
    dismissPeriodTagSelection();
  };

  const dismissDateSelection = () => {
    setDatesMenusCenterAnchor(0);
    setDateSelectionType(undefined);
  };

  const dismissPeriodTagSelection = () => {
    setDisplayPeriodTagMenu(false);
  };

  const copyTeacherStepsPressed = () => {
    viewModel.copyTeacherSteps();
  };

  const periodTagOptions = useMemo<AvailablePeriodTag[]>(() => {
    return [
      {
        tag: '',
        displayValue: localizationService.localizedStrings.studyo.contents.taskEdit.noPeriodTag,
        isHidden: false
      },
      ...viewModel.availablePeriodTags
    ];
  }, [viewModel.availablePeriodTags]);

  const selectedPeriodTagIndex = useMemo(() => {
    if (viewModel.periodTag.length === 0) {
      return 0;
    } else {
      return periodTagOptions.findIndex((t) => t.tag === viewModel.periodTag);
    }
  }, [periodTagOptions, viewModel.periodTag]);

  return (
    <Stack
      className={className}
      sx={[
        {
          overflow: 'hidden'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <ScrollShadowWrapper>
        <List disablePadding>
          <ListSection>
            <TaskEditTitleCell viewModel={viewModel} />
            <Divider />
            <TaskEditNotesCell viewModel={viewModel} />
            <Divider />
            <TaskEditWorkloadCell viewModel={viewModel} />
            <Divider />
            <TaskEditIsPrivateStateCell viewModel={viewModel} />
            <Divider />
            <TaskEditSectionCell viewModel={viewModel} />
            <Divider />
            {viewModel.showPublishOnCreation && (
              <>
                <TaskEditPublishOnCreationCell viewModel={viewModel} />
                <Divider />
              </>
            )}

            <TaskEditAttachmentsCell viewModel={viewModel} />
            <Divider />

            <Box sx={{ height: 100, m: 1, display: 'flex' }}>
              <AutoSizer>
                {({ width, height }) => {
                  datesContainerWidth.current = width;

                  return (
                    <div ref={(ref) => (taskDueBoxRef.current = ref)} style={{ width, height }}>
                      <TaskDueBox
                        viewModel={viewModel.datesViewModel}
                        componentWidth={width}
                        onAssignmentDatePress={!viewModel.isSlaveTask ? onAssignmentDatePressed : undefined}
                        onPlannedDatePress={onPlannedDatePressed}
                        onDueDatePress={!viewModel.isSlaveTask ? onDueDatePressed : undefined}
                        onDuePeriodTagPress={!viewModel.isSlaveTask ? onPeriodTagPressed : undefined}
                      />
                    </div>
                  );
                }}
              </AutoSizer>
            </Box>
          </ListSection>

          {viewModel.teacherSteps != null && (
            <ListSection title={localizationService.localizedStrings.studyo.contents.taskEdit.teacherSteps}>
              <TaskStepList viewModel={viewModel.teacherSteps} />
              <Stack
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Button onClick={copyTeacherStepsPressed} variant="contained-grey">
                  {strings.copySteps}
                </Button>
              </Stack>
            </ListSection>
          )}

          <ListSection
            title={localizationService.localizedStrings.studyo.contents.taskEdit.steps}
            icon={viewModel.userSteps.steps.length > 1 ? <Edit fontSize="small" /> : null}
            onIconPressed={() => void viewModel.orderSteps()}
          >
            <TaskStepList viewModel={viewModel.userSteps} />
          </ListSection>

          <Popover
            id="task-edit-date-selection"
            open={dateSelectionType != null}
            anchorEl={taskDueBoxRef.current}
            onClose={dismissDateSelection}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: datesMenusCenterAnchor
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            slotProps={{ paper: { sx: { height: 322, width: { xs: '100%', sm: 450 }, py: 1.5, display: 'flex' } } }}
          >
            <SchoolDayPicker sx={{ flex: 1 }} viewModel={schoolDayPickerViewModel} onSelection={didSelectDate} />
          </Popover>

          <Menu
            id="task-edit-date-selection"
            open={displayPeriodTagMenu}
            anchorEl={taskDueBoxRef.current}
            onClose={dismissPeriodTagSelection}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: datesMenusCenterAnchor
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            slotProps={{ paper: { sx: { width: 200 } } }}
          >
            {periodTagOptions.map((tag, index) => (
              <MenuItem
                key={tag.tag}
                value={tag.tag}
                onClick={() => didSelectTagAtIndex(index)}
                selected={index === selectedPeriodTagIndex}
              >
                <ListItemText
                  primary={tag.displayValue}
                  primaryTypographyProps={{ fontStyle: tag.isHidden ? 'italic' : undefined }}
                />
              </MenuItem>
            ))}
          </Menu>
        </List>
      </ScrollShadowWrapper>
      <ObservablePresenter
        data={viewModel.publishingImpact}
        renderData={(data) =>
          data != null && (
            <TaskEditPublishingImpactCell
              sx={{ position: 'relative', bottom: 0 }}
              viewModel={data}
              visibleImpacts={[WorkloadImpactSummary.DAILY_EXCEEDED, WorkloadImpactSummary.WEEKLY_EXCEEDED]}
            />
          )
        }
      />
    </Stack>
  );
});
