import { ContentAttachmentModel, EditableContentAttachment } from '@shared/models/content';
import { OnDestroy, OnInit } from '@shared/services';
import { AccountData } from '@shared/services/stores';
import { AccountAutoSyncService, AccountService, AttachmentManager } from '@studyo/services';
import { computed, makeObservable, observable } from 'mobx';

export interface ContentAttachmentAddViewModel extends OnInit, OnDestroy {
  url: string;

  createURLAttachment: () => Promise<void>;
  createFileAttachment: (filename: string, data: unknown) => Promise<void>;

  createGoogleDriveLinkAttachment: () => Promise<void>;
  close: (success: boolean) => void;
}

export class AppContentAttachmentAddViewModel implements ContentAttachmentAddViewModel {
  @observable private _url = '';
  private readonly _data: AccountData;

  constructor(
    accountService: AccountService,
    private readonly _accountAutoSyncService: AccountAutoSyncService,
    private readonly _attachmentManager: AttachmentManager,
    private readonly _addAttachment: (attachment: ContentAttachmentModel) => Promise<void>,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void
  ) {
    makeObservable(this);
    this._data = accountService.displayedAccountData;
  }

  @computed
  get url() {
    return this._url;
  }

  set url(value: string) {
    this._url = value;
  }

  onInit() {
    this._accountAutoSyncService.suspend();
  }

  onDestroy() {
    this._accountAutoSyncService.resume();
  }

  async createURLAttachment() {
    let attachment = EditableContentAttachment.createNew();
    attachment.kind = 'url';
    attachment.externalUrl = this.url;

    // We call updateContentAttachment to get a title and a thumbnail from the website
    // embed resources.
    const response = await this._data.updateContentAttachment(attachment);
    attachment = new EditableContentAttachment(response, true);

    // Adding attachment to content.
    await this._addAttachment(attachment);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async createFileAttachment(filename: string, data: any) {
    const extension = this.getFileExtension(filename);
    // We deal with image file as a different attachment kind.
    const isPhoto = ['png', 'jpg', 'jpeg'].includes(extension);

    let attachment = EditableContentAttachment.createNew();
    attachment.kind = isPhoto ? 'photo' : 'document';
    attachment.fileName = filename;
    attachment.title = filename;

    // Getting the URL where we can upload the data.
    const uploadUrl = await this._data.getUploadURL(attachment);
    attachment.fileUrl = uploadUrl.downloadUrl;

    // Upload the file data to the url we got from the backend.
    await fetch(uploadUrl.uploadUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': uploadUrl.contentType
      },
      body: this.dataUrlToBlob(data)
    });

    // By calling updateContentAttachment, the backend will generate the thumbnail for the
    // attachment. The url will be set in the attachment we get as a response.
    const response = await this._data.updateContentAttachment(attachment);
    attachment = new EditableContentAttachment(response, true);

    // Adding attachment to content.
    await this._addAttachment(attachment);
  }

  async createGoogleDriveLinkAttachment(): Promise<void> {
    const result = await this._attachmentManager.getGoogleDriveFile();

    if (result != null) {
      await this.createExternalDocumentAttachment(result.filename, result.url, undefined);
    }
  }

  close(success: boolean) {
    if (success) {
      this._onSuccess();
    } else {
      this._onCancel();
    }
  }

  private getFileExtension(filename: string) {
    return filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2);
  }

  // See https://stackoverflow.com/a/27781331
  // Important: do not use the XMLHttpRequest-based solution, as it is not compatible with Safari
  private dataUrlToBlob(dataUrl: unknown) {
    if (typeof dataUrl !== 'string') {
      throw new Error('Invalid argument: dataURI must be a string');
    }

    const newDataUrl = dataUrl.split(',');
    const type = newDataUrl[0].split(':')[1].split(';')[0],
      byteString = atob(newDataUrl[1]),
      byteStringLength = byteString.length,
      arrayBuffer = new ArrayBuffer(byteStringLength),
      intArray = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteStringLength; i++) {
      intArray[i] = byteString.charCodeAt(i);
    }
    return new Blob([intArray], {
      type
    });
  }

  private async createExternalDocumentAttachment(
    filename: string,
    url: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any
  ) {
    const isURLType = data == null;

    const attachment = EditableContentAttachment.createNew();
    attachment.fileName = filename;
    attachment.title = filename;

    if (isURLType) {
      attachment.kind = 'document-url';
      attachment.externalUrl = url;
    } else {
      // Getting the URL where we can upload the data.
      attachment.kind = 'document';
      const uploadUrl = await this._data.getUploadURL(attachment);
      attachment.fileUrl = uploadUrl.downloadUrl;

      // Upload the file data to the url we got from the backend.
      await fetch(uploadUrl.uploadUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': uploadUrl.contentType
        },
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        body: data
      });
    }

    // Adding attachment to content.
    await this._addAttachment(attachment);
  }
}
