import { ListItem, ListItemText, Switch, SxProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { TaskEditViewModel } from '../../../viewmodels';

export interface TaskEditIsPrivateStateCellProps {
  sx?: SxProps;
  className?: string;
  viewModel: TaskEditViewModel;
}

export const TaskEditIsPrivateStateCell = observer((props: TaskEditIsPrivateStateCellProps) => {
  const { localizationService } = useStudyoServices();
  const { sx = [], className, viewModel } = props;
  const strings = localizationService.localizedStrings.studyo.contents.taskEdit;

  return (
    <ListItem sx={sx} className={className}>
      <ListItemText primary={strings.private} />
      <Switch
        checked={viewModel.isPrivate}
        onChange={(e) => (viewModel.isPrivate = e.currentTarget.checked)}
        disabled={viewModel.isSlaveTask}
      />
    </ListItem>
  );
});
