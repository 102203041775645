import { ListItemButton, ListItemText, SxProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useStudyoServices } from '../../../UseStudyoServicesHook';

export interface ContentListNoneItemProps {
  sx?: SxProps;
  className?: string;
  onSelection?: () => void;
}

export const ContentListNoneItem = observer((props: ContentListNoneItemProps) => {
  const { localizationService } = useStudyoServices();
  const { sx = [], className, onSelection } = props;

  return (
    <ListItemButton sx={sx} className={className} onClick={onSelection}>
      <ListItemText
        primary={localizationService.localizedStrings.studyo.contents.lists.noContentCellTitle(onSelection != null)}
        primaryTypographyProps={{ color: 'textSecondary' }}
      />
    </ListItemButton>
  );
});
