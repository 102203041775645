import { AccountUtils } from '@shared/components/utils';
import { getRoleWeight } from '@shared/models/AccountRoleComparer';
import { AccountSummary, SchoolYearConfigurationSummary } from '@shared/models/config';
import { AuthorizationRole, Role } from '@shared/models/types';
import { authorizationRoleFromRole } from '@shared/models/types/EnumConversion';
import { UserProfile } from '@shared/models/user';
import {
  AuthenticationService,
  CompleteLoginResult,
  KillSwitchService,
  NetworkService,
  Storage,
  dateService
} from '@shared/services';
import { AccountData, UserStore } from '@shared/services/stores';
import { AppAccountData } from '@shared/services/stores/implementations/AppAccountData';
import { ContentTransport, GeneratorTransport, SchoolTransport } from '@shared/services/transports';
import { chain, head } from 'lodash';
import { action, autorun, computed, makeObservable, observable, runInAction } from 'mobx';
import { NavigationService } from './NavigationService';
import { UISettingsStore } from './UISettingsStore';
import { StudyoAnalyticsService } from './analytics';
import { StudyoSettingsStore } from './settings';
import { LastDisplayedAccountKeys } from './settings/LastDisplayedAccountKeys';

interface FindAccountResult {
  displayedAccount: AccountSummary;
  account: AccountSummary | 'super-admin';
}

export interface AccountService {
  readonly authInitErrorMessage?: string;
  readonly isLoggingIn: boolean;
  readonly isLoggedIn: boolean;

  readonly currentAccount: AccountSummary | 'super-admin' | undefined;
  readonly currentDisplayedAccount: AccountSummary | undefined;
  readonly currentDisplayedConfiguration: SchoolYearConfigurationSummary | undefined;
  readonly displayedAccountData: AccountData;
  readonly currentUserEmail: string;
  readonly isParentAndNoCurrentConfig: boolean;
  readonly isStudentAndNoCurrentConfig: boolean;
  readonly isTeacherAndNoCurrentConfig: boolean;

  readonly accounts: AccountSummary[];

  startSilentLogin: () => Promise<void>;
  login: (referrer?: string) => Promise<boolean>;
  completeLogin: () => Promise<CompleteLoginResult>;
  logout: () => Promise<void>;
  completeLogout: () => Promise<void>;
  refreshAccounts: () => Promise<void>;

  isAllowed: (allowedRoles: AuthorizationRole[]) => boolean;

  setCurrentDisplayedAccount: (displayedAccountId: string, forceRefresh?: boolean) => Promise<void>;
  setCurrentDisplayedAccountToDefault: () => Promise<void>;

  requestAccountDeletion: () => Promise<void>;
}

export class AppAccountService implements AccountService {
  @observable private _userProfile?: UserProfile;
  @observable private _currentAccount?: AccountSummary | 'super-admin';
  @observable private _currentDisplayedAccount?: AccountSummary;
  @observable private _currentDisplayedAccountData?: AccountData;

  @observable private _isLoggingInSilently = false;
  @observable private _isLoggingIn = false;
  @observable private _isCompletingLogin = false;
  private _lastDisplayedAccountId: string | undefined;

  constructor(
    private readonly _userStore: UserStore,
    private readonly _storage: Storage,
    private readonly _settingsStore: StudyoSettingsStore,
    private readonly _uiSettingsStore: UISettingsStore,
    private readonly _authenticationService: AuthenticationService,
    private readonly _navigationService: NavigationService,
    private readonly _networkService: NetworkService,
    private readonly _analyticsService: StudyoAnalyticsService,
    private readonly _killSwitchService: KillSwitchService,
    private readonly _configTransport: SchoolTransport,
    private readonly _generatorTransport: GeneratorTransport,
    private readonly _contentTransport: ContentTransport
  ) {
    makeObservable(this);
    // We need to monitor the authentication service since it can log us
    // out by itself. In that case, we need to reset our states.
    autorun(() => {
      if (!this._authenticationService.isAuthenticated) {
        this.resetStates();
      }
    });
  }

  @computed
  get authInitErrorMessage(): string | undefined {
    return this._authenticationService.initializationErrorMessage;
  }

  @computed
  get isLoggingIn(): boolean {
    return this._isLoggingIn || this._authenticationService.isLoggingIn;
  }

  @computed
  get isLoggedIn(): boolean {
    return this._userProfile != null && this._authenticationService.isAuthenticated;
  }

  @computed
  get currentAccount(): AccountSummary | 'super-admin' | undefined {
    return this._currentAccount;
  }

  @computed
  get displayedAccountData() {
    if (this._currentDisplayedAccountData == null) {
      throw new Error('Trying to access account data when no account is being displayed.');
    }

    return this._currentDisplayedAccountData;
  }

  @computed
  get accounts(): AccountSummary[] {
    if (this._userProfile == null) {
      return [];
    }

    return this._userProfile.accountSummaries.filter((a) => a.role !== 'unknown');
  }

  @computed
  get currentDisplayedAccount(): AccountSummary | undefined {
    return this._currentDisplayedAccount;
  }

  @computed
  get currentDisplayedConfiguration(): SchoolYearConfigurationSummary | undefined {
    return (
      // If this first value is null, it means we're impersonating a user (e.g. parent viewing child)
      this._currentDisplayedAccount?.configurationSummary ?? // We still want to favor the displayed account's school summary in case we're a root admin.
      (this._currentAccount as AccountSummary)?.configurationSummary
    );
  }

  @computed
  get currentUserEmail() {
    return this._userProfile?.email ?? '';
  }

  @computed
  get isParentAndNoCurrentConfig() {
    if (this.currentAccount == null || this.currentAccount === 'super-admin' || this.currentAccount.role !== 'parent') {
      return false;
    }

    const currentYearConfig = this.accounts.find(
      (account) => account.role === 'parent' && account.configurationSummary?.endDay.isAfter(dateService.today) === true
    );

    return currentYearConfig == null;
  }

  @computed
  get isStudentAndNoCurrentConfig() {
    return this.isOfRoleWithNoCurrentConfig('student');
  }

  @computed
  get isTeacherAndNoCurrentConfig() {
    return this.isOfRoleWithNoCurrentConfig('teacher');
  }

  @action
  public async setCurrentDisplayedAccount(displayedAccountId: string, forceRefresh = false): Promise<void> {
    let findAccountResult = this.findAccount(displayedAccountId);

    if (findAccountResult == null) {
      // First, fetch the account from the server
      const displayedAccountPb = await this._configTransport.fetchAccountSummary(displayedAccountId, false);

      if (displayedAccountPb != null) {
        const displayedAccount = new AccountSummary(displayedAccountPb);

        if (this._userProfile && this._userProfile.userRole === 'root-admin-user') {
          // Set the currentAccount to 'super-admin' and fetch the displayed account
          findAccountResult = {
            account: 'super-admin',
            displayedAccount: displayedAccount
          };
        } else if (displayedAccount.role === 'student' || displayedAccount.role === 'teacher') {
          // Look for either an admin account or teacher account for that student's school.
          const account = this.findAdminOrTeacherAccountForDisplayedAccount(displayedAccount);
          if (account != null) {
            findAccountResult = { account, displayedAccount };
          }
        }
      }
    }

    if (findAccountResult == null) {
      throw new Error('This account does not belong to the current user.');
    }

    this.updateCurrentAccount(findAccountResult.account, forceRefresh);
    this.updateCurrentDisplayedAccount(findAccountResult.displayedAccount, forceRefresh);
  }

  public async setCurrentDisplayedAccountToDefault(): Promise<void> {
    if (this._userProfile == null) {
      return;
    }

    if (this._lastDisplayedAccountId != null) {
      // There was an ID saved in the storage. Try to find this account.
      try {
        await this.setCurrentDisplayedAccount(this._lastDisplayedAccountId);

        // This was successful, just return.
        return;
      } catch {
        console.log('We tried to set the current account to the last displayed account but it failed.');
      }
    }

    // Fallback on using the latest account for the user.
    let defaultAccount = this.getDefaultAccountForUser();
    let displayedAccount = defaultAccount;

    if (defaultAccount && defaultAccount.role === 'parent') {
      displayedAccount = head(defaultAccount.childrenAccountSummaries);

      if (displayedAccount == null) {
        defaultAccount = undefined;
      }
    }

    runInAction(() => {
      this.updateCurrentAccount(defaultAccount);
      this.updateCurrentDisplayedAccount(displayedAccount);
    });
  }

  public isAllowed(allowedRoles: AuthorizationRole[]): boolean {
    if (this._currentAccount == null) {
      return false;
    }

    const currentRoles: AuthorizationRole[] =
      this._currentAccount === 'super-admin'
        ? ['super-admin']
        : this._currentAccount.isAdmin
          ? ['admin', authorizationRoleFromRole(this._currentAccount.role)]
          : [authorizationRoleFromRole(this._currentAccount.role)];

    return currentRoles.find((r) => allowedRoles.includes(r)) != null;
  }

  public async startSilentLogin(): Promise<void> {
    if (this._isLoggingInSilently) {
      throw new Error('Cannot call startSilentLogin while logging in');
    }

    try {
      runInAction(() => {
        this._isLoggingInSilently = true;
      });

      await this._authenticationService.startSilentSigninFlow();

      if (this._authenticationService.isAuthenticated) {
        // Make sure to load last account first, it doesn't trigger any observables.
        await this.loadLastDisplayedAccountId();
        await this.loadUserProfile();
      }
    } catch (error) {
      console.error(`An error occurred while starting the silent login`, error);
    } finally {
      runInAction(() => {
        this._isLoggingInSilently = false;
      });
      this.updateAnalyticsUserInfo();
    }
  }

  public async login(referrer?: string): Promise<boolean> {
    if (this._isLoggingIn) {
      throw new Error('Cannot call login while logging in');
    }

    try {
      runInAction(() => {
        this._isLoggingIn = true;
      });

      const loginResult = await this._authenticationService.login(referrer);

      if (loginResult) {
        // Make sure to load last account first, it doesn't trigger any observables.
        await this.loadLastDisplayedAccountId();
        await this.loadUserProfile();
      }

      return loginResult;
    } catch (error) {
      console.error(`An error occurred while logging in...`, error);

      // If an error occurred while loading the profile, ensure we are logged out.
      if (this._authenticationService.isAuthenticated) {
        await this.logout();
      }

      return false;
    } finally {
      runInAction(() => {
        this._isLoggingIn = false;
      });
      this.updateAnalyticsUserInfo();
    }
  }

  public async completeLogin(): Promise<CompleteLoginResult> {
    if (this._isCompletingLogin) {
      return { success: false };
    }

    try {
      runInAction(() => {
        this._isCompletingLogin = true;
      });

      const loginResult = await this._authenticationService.completeLogin();

      if (loginResult) {
        // Make sure to load last account first, it doesn't trigger any observables.
        await this.loadLastDisplayedAccountId();
        await this.loadUserProfile();
      }

      return loginResult;
    } catch (error) {
      console.error(`An error occurred while logging in...`, error);

      // If an error occurred while loading the profile, ensure we are logged out.
      if (this._authenticationService.isAuthenticated) {
        await this.logout();
      }

      return { success: false };
    } finally {
      runInAction(() => {
        this._isCompletingLogin = false;
      });
      this.updateAnalyticsUserInfo();
    }
  }

  public async logout(): Promise<void> {
    // IMPORTANT: We must keep the current account id before logging out since _currentAccount will be null afterward.
    const accountId = this._currentAccount === 'super-admin' ? 'super-admin' : (this._currentAccount?.id ?? undefined);

    await this._authenticationService.logout();

    this.updateAnalyticsUserInfo();
    this.updateAnalyticsConfigInfo();

    this.resetStates();
    this.clearPreferences(accountId);
    this._uiSettingsStore.resetAll();
    this.clearLastDisplayedAccountId();
  }

  public async completeLogout(): Promise<void> {
    await this._authenticationService.completeLogout();
  }

  public async refreshAccounts() {
    await this.loadUserProfile();
  }

  async requestAccountDeletion(): Promise<void> {
    if (this._userProfile != null) {
      await this._userStore.requestPermanentUserDataDeletion(this._userProfile.userId);
    }
  }

  @action
  private updateCurrentAccount(newValue: AccountSummary | 'super-admin' | undefined, forceRefresh = false) {
    if (this._currentAccount === 'super-admin' && newValue === 'super-admin') {
      return;
    }

    if (
      !forceRefresh &&
      (this._currentAccount && this._currentAccount !== 'super-admin' && this._currentAccount.id) ===
        (newValue && newValue !== 'super-admin' && newValue.id)
    ) {
      return;
    }

    this._currentAccount = newValue;
    this.updateAnalyticsConfigInfo();
  }

  @action
  private updateCurrentDisplayedAccount(newValue: AccountSummary | undefined, forceRefresh = false) {
    if (!forceRefresh && this._currentDisplayedAccount?.id === newValue?.id) {
      return;
    }

    this._uiSettingsStore.resetAll();

    this._currentDisplayedAccount = newValue;
    this._currentDisplayedAccountData =
      (newValue && this.createAccountData(newValue.configId, newValue.id)) ?? undefined;

    void this.saveLastDisplayedAccountId(newValue?.id ?? undefined);
  }

  private getDefaultAccountForUser(): AccountSummary | undefined {
    if (this._userProfile == null) {
      return undefined;
    }

    const sortedAccounts = this.getSortedAccounts();

    return head(sortedAccounts);
  }

  private getSortedAccounts(): AccountSummary[] {
    return chain(this.accounts)
      .orderBy(
        [(a) => a.configurationSummary?.startDay.asDateString ?? '', (a) => getRoleWeight(a.role)],
        ['desc', 'desc']
      )
      .value();
  }

  private async loadUserProfile(): Promise<void> {
    const userProfile = await this._userStore.getUserProfile();
    runInAction(() => (this._userProfile = userProfile));
  }

  private async loadLastDisplayedAccountId(): Promise<void> {
    this._lastDisplayedAccountId = await this._storage.get(LastDisplayedAccountKeys.lastDisplayedAccountId);
  }

  private async saveLastDisplayedAccountId(accountId: string | undefined): Promise<void> {
    await this._storage.set(LastDisplayedAccountKeys.lastDisplayedAccountId, accountId);
    this._lastDisplayedAccountId = accountId;
  }

  private findAccount(accountId: string): FindAccountResult | undefined {
    if (this._userProfile == null) {
      return undefined;
    }

    // First, try to find the account in the accounts of the user
    const account = this.accounts.find((a) => a.id === accountId);
    if (account != null) {
      return { displayedAccount: account, account: account };
    }

    // If it is not found, try to find the account in the sub-accounts of the user
    let findAccountResult: FindAccountResult | undefined = undefined;
    this.accounts.forEach((a) => {
      const subAccount = a.childrenAccountSummaries.find((sa) => sa.id === accountId);
      if (subAccount != null) {
        findAccountResult = { displayedAccount: subAccount, account: a };
      }
    });

    return findAccountResult;
  }

  private findAdminOrTeacherAccountForDisplayedAccount(displayedAccount: AccountSummary): AccountSummary | undefined {
    const sortedAccounts = this.getSortedAccounts();

    return chain(sortedAccounts)
      .filter((a) => a.configId === displayedAccount.configId)
      .head()
      .value();
  }

  @action
  private resetStates() {
    this._isLoggingInSilently = false;
    this._isLoggingIn = false;
    this._isCompletingLogin = false;
    this._userProfile = undefined;

    this.updateCurrentAccount(undefined);
    this.updateCurrentDisplayedAccount(undefined);
  }

  private clearPreferences(accountId: string | undefined) {
    if (accountId == null) {
      return;
    }

    const preferences = this._settingsStore.getPreferences(accountId);
    if (preferences.hasData) {
      preferences.clear();
    }
  }

  private clearLastDisplayedAccountId() {
    void this._storage.delete(LastDisplayedAccountKeys.lastDisplayedAccountId);
  }

  private updateAnalyticsUserInfo() {
    if (this._userProfile == null) {
      this._analyticsService.clearUserInfo();
      return;
    }

    this._analyticsService.setUserInfo({
      userId: this._userProfile.userId,
      intercomHash: this._userProfile.intercomHash,
      email: this._userProfile.email,
      userName: this._userProfile.username
    });
  }

  private updateAnalyticsConfigInfo() {
    if (this._currentAccount == null) {
      this._analyticsService.clearConfigInfo();
      return;
    }

    this._analyticsService.setConfigInfo({
      configId:
        this._currentAccount === 'super-admin'
          ? (this._currentDisplayedAccount?.id ?? 'super-admin')
          : this._currentAccount.configId,
      accountId: this._currentAccount === 'super-admin' ? 'super-admin' : this._currentAccount.id,
      accountRole: this._currentAccount === 'super-admin' ? 'super-admin' : this._currentAccount.role,
      accountFullName:
        this._currentAccount === 'super-admin'
          ? this._userProfile!.email
          : AccountUtils.getDisplayFirstLastName(this._currentAccount),
      schoolName:
        this._currentAccount !== 'super-admin' && this._currentAccount.configurationSummary != null
          ? this._currentAccount.configurationSummary.schoolName
          : ''
    });
  }

  private createAccountData(configId: string, accountId: string): AccountData {
    return new AppAccountData(
      configId,
      accountId,
      this._configTransport,
      this._contentTransport,
      this._generatorTransport,
      this._networkService,
      this._killSwitchService,
      this._analyticsService,
      this.getIsImpersonating(),
      this.getImpersonatingRole(),
      this._storage,
      // Demo mode allowed for demo schools. It doesn't mean demo mode is "on",
      // just that if a demo school is loaded, it can kick in. Passing false here
      // would disable the whole demo mode support, in case it's required.
      true
    );
  }

  private getIsImpersonating(): boolean {
    // NOTE: This will be used when creating the AccountData
    return (
      this._currentAccount === 'super-admin' ||
      (this._currentAccount?.id ?? undefined) != (this._currentDisplayedAccount?.id ?? undefined)
    );
  }

  private getImpersonatingRole(): AuthorizationRole | undefined {
    if (this._currentAccount == null || !this.getIsImpersonating) {
      return undefined;
    }

    return this._currentAccount === 'super-admin'
      ? 'super-admin'
      : this._currentAccount.isAdmin
        ? 'admin'
        : authorizationRoleFromRole(this._currentAccount.role);
  }

  private isOfRoleWithNoCurrentConfig(role: Role): boolean {
    if (this.currentAccount == null || this.currentAccount === 'super-admin' || this.currentAccount.role !== role) {
      return false;
    }

    const today = dateService.today;

    if (this.currentAccount.configurationSummary?.endDay.isSameOrAfter(today) ?? false) {
      return false;
    }

    const currentYearAccount = this.accounts.find(
      (account) => account.role === role && account.configurationSummary?.endDay.isSameOrAfter(today) === true
    );

    return (
      currentYearAccount != null &&
      currentYearAccount.configurationSummary?.state === 'active' &&
      currentYearAccount.configurationSummary.id !== this.currentAccount.configurationSummary?.id
    );
  }
}
