import { Add } from '@mui/icons-material';
import { Button, List, Stack, SxProps, Typography, useTheme } from '@mui/material';
import { ScrollShadowWrapper } from '@shared/components/utils';
import { SectionColors } from '@shared/models/Colors';
import { observer } from 'mobx-react-lite';
import { useStudyoServices } from '../../UseStudyoServicesHook';
import {
  ContentListGroup,
  ContentListNoneItem,
  ListSection,
  ListSectionHeaderHeight,
  ListSectionHeaderIcon
} from '../../components';
import { SchoolDayContentListSectionViewModel, SchoolDayContentListViewModel } from '../../viewmodels';

export interface SchoolDayContentListViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: SchoolDayContentListViewModel;
}

export const SchoolDayContentListView = observer((props: SchoolDayContentListViewProps) => {
  const { localizationService } = useStudyoServices();
  const { sx = [], className, viewModel } = props;
  const theme = useTheme();
  const getTitleForSection = (section: SchoolDayContentListSectionViewModel) => {
    if (section.hasSection) {
      if (section.periodTag != null) {
        return `${section.periodTag} - ${section.sectionTitle}`;
      } else {
        return section.sectionTitle;
      }
    } else if (section.periodTag != null) {
      return `${section.periodTag} - ${localizationService.localizedStrings.studyo.contents.lists.noSectionPeriodTitle}`;
    } else {
      return localizationService.localizedStrings.studyo.contents.lists.allDayTasksTitle;
    }
  };

  return (
    <ScrollShadowWrapper sx={sx} className={className}>
      <List disablePadding>
        {viewModel.sections.map((section, sectionIndex) => {
          const title = getTitleForSection(section);

          const tintColor = section.hasSection
            ? theme.studyo.periods.periodWithSectionTextColor
            : theme.studyo.periods.freePeriodTextColor;

          return (
            <ListSection key={`school-day-content-list-section-${sectionIndex}`}>
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  mt: 1,
                  pl: 2,
                  pr: 1,
                  alignItems: 'center',
                  height: ListSectionHeaderHeight(title),
                  backgroundColor: SectionColors.get(section.sectionColor!) ?? theme.studyo.periods.freePeriodColor,
                  color: tintColor
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    flex: 1,
                    color: 'inherit'
                  }}
                >
                  {title}
                </Typography>

                {section.canEdit && section.canPaste && (
                  <Button
                    size="small"
                    color="inherit"
                    sx={{ color: tintColor }}
                    onClick={() => void section.pasteTask()}
                  >
                    {localizationService.localizedStrings.studyo.contents.lists.paste}
                  </Button>
                )}

                {section.canEdit && (
                  <ListSectionHeaderIcon
                    icon={<Add fontSize="medium" color="inherit" />}
                    inheritColor
                    onIconPressed={() => section.addTask()}
                  />
                )}
              </Stack>
              <ContentListGroup contents={section.contents} />
              {section.contents.length === 0 && (
                <ContentListNoneItem onSelection={section.canEdit ? () => section.addTask() : undefined} />
              )}
            </ListSection>
          );
        })}
      </List>
    </ScrollShadowWrapper>
  );
});
