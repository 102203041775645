import { CalendarMonth, EmailOutlined } from '@mui/icons-material';
import CopyIcon from '@mui/icons-material/FileCopy';
import {
  CardActionArea,
  Chip,
  IconButton,
  ListItem,
  ListItemText,
  Stack,
  Switch,
  SxProps,
  Typography
} from '@mui/material';
import { Observer, observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useStudyoServices } from '../../UseStudyoServicesHook';
import { GearMenuShareViewModel } from '../../viewmodels';
import { Toast } from '../utils';

export interface ShareCalendarProps {
  sx?: SxProps;
  className?: string;
  viewModel: GearMenuShareViewModel;
}

export const ShareCalendar = observer((props: ShareCalendarProps) => {
  const { localizationService } = useStudyoServices();
  const { sx = [], className, viewModel } = props;
  const strings = localizationService.localizedStrings.studyo.agenda.setting.gearMenuShare;
  const [showCopyToast, setShowCopyToast] = useState(false);

  const renderFreePeriods = () => {
    return (
      <Observer>
        {() => (
          <Switch
            checked={viewModel.includeFreePeriods}
            onChange={(e) => (viewModel.includeFreePeriods = e.currentTarget.checked)}
          />
        )}
      </Observer>
    );
  };

  const openMailto = () => {
    window.open(viewModel.mailToUrl);
  };

  const openShareCalendar = () => {
    window.open(viewModel.webcalUrl);
  };

  const copyToClipboard = () => {
    viewModel.copyToClipboard();
    showToast();
  };

  const showToast = () => {
    setShowCopyToast(true);
  };

  const hideToast = () => {
    setShowCopyToast(false);
  };

  return (
    <Stack sx={sx} className={className} spacing={2}>
      <ListItem>
        <ListItemText primary={strings.includeFreePeriods} />
        {renderFreePeriods()}
      </ListItem>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          justifyContent: 'center'
        }}
      >
        <CardActionArea sx={{ width: 150, p: 2, borderRadius: 1 }} onClick={openMailto}>
          <Stack
            spacing={1}
            sx={{
              alignItems: 'center'
            }}
          >
            <EmailOutlined color="primary" sx={{ width: 54, height: 54 }} />
            <Typography
              variant="subtitle2"
              sx={{
                textAlign: 'center'
              }}
            >
              {strings.sendByEmail}
            </Typography>
          </Stack>
        </CardActionArea>

        <CardActionArea sx={{ width: 150, p: 2, borderRadius: 0.5 }} onClick={openShareCalendar}>
          <Stack
            spacing={1}
            sx={{
              alignItems: 'center'
            }}
          >
            <CalendarMonth color="primary" sx={{ width: 54, height: 54 }} />
            <Typography
              variant="subtitle2"
              sx={{
                textAlign: 'center'
              }}
            >
              {strings.addToMyCalendar}
            </Typography>
          </Stack>
        </CardActionArea>
      </Stack>
      <Stack
        spacing={1}
        sx={{
          alignItems: 'center',
          width: '100%'
        }}
      >
        <Typography variant="body2">{strings.copyUrl}</Typography>

        <Stack
          direction="row"
          spacing={2}
          sx={{
            alignItems: 'center',
            width: '100%',
            justifyContent: 'center'
          }}
        >
          <Chip variant="filled" label={viewModel.httpUrl} sx={{ width: '65%' }} onClick={copyToClipboard} />

          <IconButton onClick={copyToClipboard} color="primary">
            <CopyIcon />
          </IconButton>
        </Stack>
      </Stack>
      <Toast
        open={showCopyToast}
        message={strings.copyToClipboardSuccess}
        variant="info"
        onClose={hideToast}
        duration="short"
      />
    </Stack>
  );
});
