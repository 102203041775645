import { Box, CardActionArea, SxProps, Typography, useTheme } from '@mui/material';
import { TaskIcon, TaskIconPublishKind } from '@shared/components/contents';
import { DateUtils } from '@shared/components/utils';
import { ContentIcon, ContentState, ContentWorkloadLevel, Day, Integration } from '@shared/models/types';
import { dateService } from '@shared/services';
import { observer } from 'mobx-react-lite';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { TaskDueChildHeight } from './TaskDueStyles';

export type TaskDateIconBoxType = 'announced' | 'planned' | 'due';

interface TaskDateIconBoxProps {
  sx?: SxProps;
  className?: string;
  title: string;
  icon: ContentIcon;
  day: Day;
  type: TaskDateIconBoxType;
  state: ContentState;
  isSelected: boolean;
  externalSource: Integration | undefined;
  taskIconPublishKind: TaskIconPublishKind;
  isPrivate: boolean;
  workloadLevel: ContentWorkloadLevel;
  hasSteps: boolean;
  onPress?: () => void;
}

export const TaskDateIconBox = observer((props: TaskDateIconBoxProps) => {
  const {
    day,
    icon,
    isPrivate,
    isSelected,
    onPress,
    state,
    sx = [],
    className,
    title,
    type,
    taskIconPublishKind: publishKind,
    externalSource,
    workloadLevel,
    hasSteps
  } = props;
  const { localizationService } = useStudyoServices();
  const theme = useTheme();

  const forcedIconColor =
    onPress != null && isSelected ? theme.studyo.contents.taskDueBox.selectedIconColor : undefined;

  const isAnnounced = type == 'announced';
  const iconSize = type == 'due' ? 30 : undefined;
  const textColor = isSelected
    ? theme.studyo.contents.taskDueBox.selectedTextColor
    : theme.studyo.contents.taskDueBox.normalTextColor;

  // Note: No publish error overlay here.

  return (
    <CardActionArea
      sx={{
        ...sx,
        borderRadius: 1,
        alignItems: 'center',
        height: TaskDueChildHeight,
        overflow: 'visible',
        display: 'flex',
        flexDirection: 'column'
      }}
      className={className}
      onClick={onPress}
      disabled={onPress == null}
    >
      <Typography
        variant="caption"
        sx={{
          textAlign: 'center'
        }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexShrink: 0
        }}
      >
        <TaskIcon
          icon={icon}
          isAssignment={isAnnounced}
          squareSize={iconSize}
          state={state}
          isOpaque={true}
          publishedKind={type === 'due' ? publishKind : 'none'}
          forcedIconColor={forcedIconColor}
          externalSource={externalSource}
          isPrivate={isPrivate}
          workloadLevel={workloadLevel}
          hasSteps={hasSteps}
        />
      </Box>
      <Typography
        variant="caption"
        sx={{
          textAlign: 'center',
          color: textColor
        }}
      >
        {DateUtils.relativeDayLabelBetweenDay(
          day,
          dateService.today,
          localizationService.localizedStrings,
          localizationService.currentLocale
        )}
      </Typography>
    </CardActionArea>
  );
});
