import { Box, SxProps, Typography } from '@mui/material';
import { isSxArray } from '@shared/components/utils';
import { Role } from '@shared/models/types';
import { observer } from 'mobx-react-lite';
import { useStudyoServices } from '../../../UseStudyoServicesHook';

export interface ProfileMenuNewYearMessageRowProps {
  sx?: SxProps;
  className?: string;
  role: Role;
}

export const ProfileMenuNewYearMessageRow = observer((props: ProfileMenuNewYearMessageRowProps) => {
  const { localizationService } = useStudyoServices();
  const { sx = [], className, role } = props;
  const strings = localizationService.localizedStrings.studyo.agenda.setting.profileMenu;

  const message = () => {
    switch (role) {
      case 'parent':
        return strings.parentNewSchoolYearMessage;
      case 'student':
        return strings.studentNewSchoolYearMessage;
      case 'teacher':
        return strings.teacherNewSchoolYearMessage;
      default:
        return '';
    }
  };

  return (
    <Box
      className={className}
      sx={[
        {
          p: 2
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <Typography
        variant="body2"
        sx={{
          textAlign: 'center'
        }}
      >
        {message()}
      </Typography>
    </Box>
  );
});
