import { Capacitor } from '@capacitor/core';
import { css } from '@emotion/css';
import { Button, Divider, Stack, SxProps, Typography, useMediaQuery, useTheme } from '@mui/material';
import { isSxArray } from '@shared/components/utils';
import { Browser, BrowserInfo, detect } from 'detect-browser';
import { observer } from 'mobx-react-lite';
import { ReactNode, useMemo } from 'react';
import { useStudyoServices } from '../UseStudyoServicesHook';
import { OnboardingBackgroundImage, OnboardingContentBackgroundView } from './utils';

const SupportedBrowser: Browser[] = ['chrome', 'crios', 'edge', 'edge-chromium', 'firefox', 'ios', 'safari', 'opera'];

export interface BrowserDetectorProps {
  sx?: SxProps;
  className?: string;
  children: ReactNode;
}

export const BrowserDetector = observer((props: BrowserDetectorProps) => {
  const { localizationService } = useStudyoServices();
  const { children, sx = [], className } = props;
  const strings = localizationService.localizedStrings.studyo.browserDetector;
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));

  const controlClassName = css({
    minWidth: !isExtraSmallScreen ? 350 : undefined
  });

  const browser = useMemo(() => {
    const browser = detect();
    if (browser instanceof BrowserInfo) {
      return browser;
    }

    return null;
  }, []);

  const downloadChrome = () => {
    window.open('https://www.google.com/chrome/', '_blank');
  };

  const downloadFirefox = () => {
    window.open('http://www.mozilla.org/firefox/', '_blank');
  };

  const downloadSafari = () => {
    window.open('https://support.apple.com/downloads/safari/', '_blank');
  };

  const downloadEdge = () => {
    window.open('https://www.microsoft.com/en-us/windows/microsoft-edge/', '_blank');
  };

  if (((browser && SupportedBrowser.includes(browser.name)) ?? false) || Capacitor.isNativePlatform()) {
    return <>{children}</>;
  }

  return (
    <Stack
      className={className}
      sx={[
        {
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <OnboardingBackgroundImage />
      <div className="login-container">
        <Stack
          sx={{
            flex: 1,
            position: 'relative'
          }}
        >
          <OnboardingContentBackgroundView sx={{ zIndex: 1 }} />

          <Stack
            spacing={2}
            sx={{
              flex: 1,
              zIndex: 2,
              alignItems: { xs: 'stretch', sm: 'center' },
              p: { xs: 2, sm: 3 },
              overflowY: 'auto',
              overflowX: 'hidden'
            }}
          >
            <Stack spacing={1}>
              <Typography
                variant="h5"
                sx={{
                  textAlign: 'center'
                }}
              >
                {strings.title}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  textAlign: 'center',
                  px: 4
                }}
              >
                {strings.subtitle}
              </Typography>
            </Stack>

            <Divider sx={{ width: 300 }} />

            <Stack spacing={2}>
              <Button onClick={downloadChrome} className={controlClassName} variant="contained">
                Google Chrome
              </Button>

              <Button onClick={downloadFirefox} className={controlClassName} variant="contained">
                Firefox
              </Button>

              <Button onClick={downloadEdge} className={controlClassName} variant="contained">
                Microsoft Edge
              </Button>

              {browser && (browser.os === 'Mac OS' || browser.os === 'iOS') && (
                <Button onClick={downloadSafari} className={controlClassName} variant="contained">
                  Safari
                </Button>
              )}
            </Stack>
          </Stack>
        </Stack>
      </div>
    </Stack>
  );
});
