import { Box, SxProps, useTheme } from '@mui/material';
import { SectionColors } from '@shared/models/Colors';
import { ContentAttachmentModel } from '@shared/models/content';
import { DialogResult } from '@shared/services';
import { DialogActionButtons, DialogButton, ResponsiveDialog, TaskAttachmentListView } from '@studyo/components';
import { observer } from 'mobx-react-lite';
import { useMemo, useRef } from 'react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { DialogHeader } from '../../../components';

export interface TaskAttachmentListScreenProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  getAttachments: () => ContentAttachmentModel[];
  deleteAttachment: (attachment: ContentAttachmentModel) => Promise<void>;
  addAttachment: (attachment: ContentAttachmentModel) => void;
  isSlaveContent: boolean;
  sectionId: string;
}

export const TaskAttachmentListScreen = observer((props: TaskAttachmentListScreenProps) => {
  const { localizationService, viewModelFactory } = useStudyoServices();
  const {
    getAttachments,
    addAttachment,
    deleteAttachment,
    sx = [],
    className,
    isSlaveContent,
    sectionId,
    onCancel,
    onSuccess
  } = props;
  const theme = useTheme();

  const viewModel = useMemo(
    () =>
      viewModelFactory.createAttachmentList(
        getAttachments,
        deleteAttachment,
        addAttachment,
        isSlaveContent,
        sectionId,
        onSuccess!,
        onCancel!
      ),
    [getAttachments]
  );

  const addButtonRef = useRef<HTMLDivElement | null>();

  const strings = localizationService.localizedStrings.studyo.contents.attachments;

  const { sectionColor } = viewModel;
  const headerColor = sectionColor != null ? SectionColors.get(sectionColor) : theme.studyo.periods.freePeriodColor;

  const onAddButtonClick = () => {
    void viewModel.addAttachment();
  };

  const close = () => {
    viewModel.close();
  };

  return (
    <ResponsiveDialog
      sx={sx}
      className={className}
      maxWidth="sm"
      fullWidth={true}
      open={true}
      maxHeight="large"
      onClose={close}
      disableEscapeKeyDown={true}
    >
      <DialogHeader
        onClose={close}
        title={localizationService.localizedStrings.studyo.contents.taskEdit.attachmentsTitle}
        color={headerColor}
        tintColor={sectionColor != null ? theme.studyo.periods.periodWithSectionTextColor : undefined}
      />
      <TaskAttachmentListView sx={{ flex: 1 }} viewModel={viewModel} />
      <DialogActionButtons minimumScreenSize={!viewModel.canAddAttachment ? 'sm' : undefined}>
        {viewModel.canAddAttachment && (
          <div ref={(ref) => (addButtonRef.current = ref)}>
            <DialogButton title={strings.addButton} type="normal" onPress={onAddButtonClick} />
          </div>
        )}

        <Box
          sx={{
            flex: 1
          }}
        />

        <DialogButton title={strings.listScreenDone} type="normal" onPress={close} minimumScreenSize="sm" />
      </DialogActionButtons>
    </ResponsiveDialog>
  );
});
