import { CardActionArea, Stack, SxProps, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Color } from '@shared/models/types';
import { observer } from 'mobx-react-lite';
import { AvatarView } from '../../settings';

export interface CalendarHeaderConfigInformationBoxProps {
  sx?: SxProps;
  className?: string;
  title?: string;
  subTitle?: string;
  firstName: string;
  lastName: string;
  isImpersonating: boolean;
  isDemoSchool?: boolean;
  isPreventingChanges?: boolean;
  avatarColor: Color;
  isSyncing: boolean;
  onPress: () => void;
}

export const CalendarHeaderConfigInformationBox = observer((props: CalendarHeaderConfigInformationBoxProps) => {
  const {
    sx = [],
    className,
    title,
    subTitle,
    firstName,
    lastName,
    isImpersonating,
    isDemoSchool,
    isPreventingChanges,
    avatarColor,
    onPress
  } = props;
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));

  return (
    <CardActionArea sx={{ ...sx, p: 0.5, borderRadius: 1 }} className={className} onClick={onPress}>
      <Stack
        direction="row"
        className={className}
        spacing={1}
        sx={{
          alignItems: 'center',
          ...sx,
          cursor: 'pointer'
        }}
      >
        {!isExtraSmallScreen && (title != null || subTitle != null) && (
          <Stack
            sx={{
              alignItems: 'flex-end'
            }}
          >
            {title != null && (
              <Typography variant="body1" sx={{ userSelect: 'none' }}>
                {title}
              </Typography>
            )}
            {subTitle != null && (
              <Typography
                variant="caption"
                sx={{
                  color: (theme) => theme.palette.text.secondary,
                  userSelect: 'none'
                }}
              >
                {subTitle}
              </Typography>
            )}
          </Stack>
        )}

        <AvatarView
          firstName={firstName}
          lastName={lastName}
          isImpersonating={isImpersonating}
          size={isExtraSmallScreen ? 36 : 42}
          color={avatarColor}
          isDemoSchool={isDemoSchool}
          isPreventingChanges={isPreventingChanges}
        />
      </Stack>
    </CardActionArea>
  );
});
