import { Checkbox, ListItem, ListItemIcon, ListItemText, SxProps } from '@mui/material';
import { LinkedTasksDeleteOccurrenceViewModel } from '@studyo/viewmodels';
import { observer } from 'mobx-react-lite';
import { useStudyoServices } from '../../UseStudyoServicesHook';

export interface LinkedTasksDeleteOccurrenceCellProps {
  sx?: SxProps;
  className?: string;
  viewModel: LinkedTasksDeleteOccurrenceViewModel;
  onStateChange: () => void;
}

export const LinkedTasksDeleteOccurrenceCell = observer((props: LinkedTasksDeleteOccurrenceCellProps) => {
  const { localizationService } = useStudyoServices();
  const { sx = [], className, onStateChange, viewModel } = props;
  const strings = localizationService.localizedStrings.studyo.contents.linkedTasksDelete;

  return (
    <ListItem sx={sx} className={className}>
      <ListItemIcon>
        <Checkbox checked={viewModel.isSelected} onChange={() => onStateChange()} />
      </ListItemIcon>

      <ListItemText
        primary={viewModel.date.formattedString(localizationService.localizedStrings.models.dateFormats.long)}
        secondary={viewModel.periodTag.length > 0 ? strings.periodTag(viewModel.periodTag) : undefined}
      />
    </ListItem>
  );
});
