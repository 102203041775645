import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Checkbox,
  Divider,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  SxProps,
  TextField,
  Typography
} from '@mui/material';
import { AccountUtils, isSxArray } from '@shared/components/utils';
import { AvatarView, ContentActionHeader, DropDownSelector } from '@studyo/components';
import { AllContentPublishKind, ContentPublishKind, ContentPublishStudentSelectionViewModel } from '@studyo/viewmodels';
import { observer } from 'mobx-react-lite';
import { Fragment, useEffect, useMemo } from 'react';
import { useStudyoServices } from '../../UseStudyoServicesHook';

export interface ContentPublishStudentSelectionViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: ContentPublishStudentSelectionViewModel;
}

export const ContentPublishStudentSelectionView = observer((props: ContentPublishStudentSelectionViewProps) => {
  const { localizationService } = useStudyoServices();
  const { sx = [], className, viewModel } = props;
  const strings = localizationService.localizedStrings.studyo.contents.contentPublish;

  useEffect(() => {
    viewModel.onInit();
    return () => viewModel.onDestroy();
  }, [viewModel]);

  const publishKindDescription = useMemo(() => {
    switch (viewModel.publishKind) {
      case 'none':
        return strings.publishToNoneDescription;

      case 'all':
        return strings.publishToAllDescription;

      case 'some':
        return strings.publishToSomeDescription;
    }
  }, [viewModel.publishKind, localizationService.currentLocale]);

  const didSelectPublishKind = (value: string) => {
    switch (value as ContentPublishKind) {
      case 'none':
        viewModel.setPublishTargetNone();
        break;

      case 'all':
        viewModel.setPublishTargetAll();
        break;

      case 'some':
        viewModel.setPublishTargetSpecific();
        break;
    }
  };

  const getSelectionKindDisplayValue = (kind: ContentPublishKind): string => {
    switch (kind) {
      case 'none':
        return strings.publishToNone;

      case 'all':
        return strings.publishToAll;

      case 'some':
        return strings.publishToSome;
    }
  };

  return (
    <Stack
      className={className}
      sx={[
        {
          overflow: 'hidden'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <ContentActionHeader content={viewModel.content.content} />
      <Divider />
      <ListItem>
        <ListItemText primary={strings.publishTo} />
        <DropDownSelector
          options={AllContentPublishKind}
          selectedIndex={AllContentPublishKind.indexOf(viewModel.publishKind)}
          didSelectOption={(index) => didSelectPublishKind(AllContentPublishKind[index])}
          displayValueForOption={(option) => getSelectionKindDisplayValue(option as ContentPublishKind)}
        />
      </ListItem>
      <Typography
        variant="caption"
        sx={{
          px: 2,
          color: (theme) => theme.palette.text.secondary
        }}
      >{`*${publishKindDescription}`}</Typography>
      {viewModel.publishKind == 'some' && (
        <Stack
          sx={{
            flex: 1,
            overflow: 'hidden'
          }}
        >
          <ListItem>
            <ListItemIcon>
              <Checkbox
                checked={viewModel.visibleToParentsAndTeachers}
                onChange={(e) => (viewModel.visibleToParentsAndTeachers = e.currentTarget.checked)}
              />
            </ListItemIcon>

            <ListItemText primary={strings.publishToSomeVisibleToParentsTeachersDescription} />
          </ListItem>

          <Box
            sx={{
              px: 2
            }}
          >
            <TextField
              variant="filled"
              fullWidth
              value={viewModel.searchString}
              onChange={(e) => (viewModel.searchString = e.currentTarget.value)}
              label={strings.searchStudents}
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  )
                }
              }}
            />
          </Box>

          <Typography
            variant="caption"
            sx={{
              px: 2,
              py: 1,
              color: (theme) => theme.palette.text.secondary,
              textAlign: 'center'
            }}
          >
            {strings.numberOfSelectedStudent(viewModel.publishedToStudentWithIds.length, viewModel.students.length)}
          </Typography>

          <List sx={{ flex: 1, overflow: 'auto' }} disablePadding dense>
            {viewModel.filteredStudents.map((student, i) => (
              <Fragment key={student.id}>
                <ListItem>
                  <Checkbox
                    checked={viewModel.publishedToStudentWithIds.includes(student.id)}
                    onChange={() => viewModel.toggleStudentSelection(student)}
                  />

                  <AvatarView
                    firstName={student.firstName}
                    lastName={student.lastName}
                    color={student.profile.avatarColor}
                    isImpersonating={false}
                    size={34}
                    sx={{ mr: 1 }}
                  />

                  <ListItemText primary={AccountUtils.getDisplayLastFirstName(student, student.visibleEmail)} />
                </ListItem>

                {i < viewModel.students.length - 1 && <Divider />}
              </Fragment>
            ))}
          </List>
        </Stack>
      )}
    </Stack>
  );
});
