import { Box, Button, Stack, SxProps, Typography, useMediaQuery, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate } from 'react-router-dom';

import { RouteParamNames, RouteTemplates } from '../../Routes.ts';
import { useStudyoServices } from '../../UseStudyoServicesHook';
import { ConfigCreationViewModel } from '../../viewmodels';

export interface ConfigCreationViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: ConfigCreationViewModel;
}

export const ConfigCreationView = observer((props: ConfigCreationViewProps) => {
  const { localizationService, navigationService, reactRouterRouteService } = useStudyoServices();
  const { sx = [], className, viewModel } = props;
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));
  const strings = localizationService.localizedStrings.studyo.onboarding.createConfig;
  const navigate = useNavigate();
  const location = useLocation();

  const navigateToUseCode = () => {
    const location = reactRouterRouteService.resolveLocation(RouteTemplates.onboarding.useCode, [
      { name: RouteParamNames.isInitialConfig, value: viewModel.isInitialCreation ? '1' : '0' }
    ]);
    navigate(location);
  };

  const logout = () => {
    void viewModel.logout();
  };

  const goBack = () => {
    void navigationService.redirectToReferrerOrLanding(location, navigate);
  };

  const learnAboutToday = () => {
    navigate(reactRouterRouteService.resolveLocation(RouteTemplates.onboarding.aboutToday));
  };

  return (
    <Stack
      className={className}
      spacing={2}
      sx={{
        alignItems: { xs: 'stretch', sm: 'center' },
        p: { xs: 2, sm: 3 },
        ...sx,
        overflowY: 'auto',
        overflowX: 'hidden'
      }}
    >
      <Stack spacing={1}>
        <Typography
          variant="h5"
          sx={{
            textAlign: 'center'
          }}
        >
          {viewModel.isInitialCreation ? strings.titleInitialConfig : strings.titleNewConfig}
        </Typography>

        <Stack>
          <Typography
            variant="body1"
            whiteSpace="pre-line"
            sx={{
              textAlign: 'center'
            }}
          >
            {strings.subtitle}
          </Typography>
        </Stack>
      </Stack>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: { xs: 'stretch', sm: 'center' }
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={navigateToUseCode}
          sx={{ flex: 1, maxWidth: !isExtraSmallScreen ? 350 : undefined }}
        >
          {strings.enterCode}
        </Button>
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: { xs: 'stretch', sm: 'center' }
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={learnAboutToday}
          sx={{ flex: 1, maxWidth: !isExtraSmallScreen ? 350 : undefined }}
        >
          {strings.learnAboutToday}
        </Button>
      </Box>
      <Box
        sx={{
          height: '1px',
          backgroundColor: theme.studyo.onboarding.separatorLineColor,
          width: !isExtraSmallScreen ? 300 : undefined
        }}
      />
      {viewModel.isInitialCreation && (
        <Stack>
          <Typography
            variant="body1"
            sx={{
              textAlign: 'center'
            }}
          >
            {strings.teacherOrParentSubtitle}
          </Typography>
          <Typography
            variant="body2"
            whiteSpace="pre-line"
            sx={{
              textAlign: 'center',
              mt: 1
            }}
          >
            {strings.teacherOrParentNotice}
          </Typography>
          <Typography variant="caption" color="textDisabled" sx={{ textAlign: 'center', my: 2 }}>
            ({strings.loggedInAs}&nbsp;{viewModel.email})
          </Typography>
        </Stack>
      )}
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: { xs: 'stretch', sm: 'center' }
        }}
      >
        <Button
          variant="contained-grey"
          color="primary"
          onClick={viewModel.isInitialCreation ? logout : goBack}
          sx={{ flex: 1, maxWidth: !isExtraSmallScreen ? 350 : undefined }}
        >
          {viewModel.isInitialCreation ? strings.logout : strings.goBack}
        </Button>
      </Box>
    </Stack>
  );
});
