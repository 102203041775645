import { Box, Stack, SxProps, useMediaQuery, useTheme } from '@mui/material';
import { BaseAgendaHeaderViewModel, DateSwitcherContext } from '@studyo/viewmodels';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';
import { CalendarHeaderActionButtonOptions } from './CalendarHeaderActionButton.tsx';
import { CalendarHeaderActionButtons } from './CalendarHeaderActionButtons.tsx';
import { CalendarHeaderConfigInformationBox } from './CalendarHeaderConfigInformationBox.tsx';
import { CalendarHeaderFilterButtons } from './CalendarHeaderFilterButtons.tsx';
import { CalendarHeaderNavigationButtons } from './CalendarHeaderNavigationButtons.tsx';

export interface CalendarHeaderProps {
  sx?: SxProps;
  className?: string;
  viewModel: BaseAgendaHeaderViewModel;
  renderDate: (sx: SxProps) => ReactNode;
  renderOptionalView?: (sx: SxProps) => ReactNode;
  goToNextPage?: () => void;
  goToPreviousPage?: () => void;
  dateSwitcherContext?: DateSwitcherContext;
  actionButtons?: CalendarHeaderActionButtonOptions[];
  hasContentFilters?: boolean;
  onContentFiltersPress?: () => void;
  hasSectionFilters?: boolean;
  onSectionFiltersPress?: () => void;
}

export const CalendarHeader = observer(
  ({
    sx = [],
    className,
    viewModel,
    renderDate,
    renderOptionalView,
    goToNextPage,
    goToPreviousPage,
    dateSwitcherContext,
    actionButtons,
    hasContentFilters,
    onContentFiltersPress,
    hasSectionFilters,
    onSectionFiltersPress
  }: CalendarHeaderProps) => {
    const theme = useTheme();
    const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));

    return (
      <Box
        className={className}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          pl: { xs: dateSwitcherContext == null ? 2 : 1, sm: 2 },
          pr: { xs: 0.5, sm: 2 },
          py: { xs: 0.5, sm: 1 },
          overflow: 'hidden',
          ...sx,
          backgroundColor: theme.studyo.agenda.header.backgroundColor
        }}
      >
        {dateSwitcherContext != null && (
          <CalendarHeaderNavigationButtons
            sx={{ mr: { xs: 1, sm: 2 } }}
            navigateToNext={goToNextPage}
            navigateToPrevious={goToPreviousPage}
            context={dateSwitcherContext}
            showArrows={!isExtraSmallScreen}
          />
        )}
        {renderDate({ flex: 1 })}
        <Stack
          direction="row"
          spacing={{ xs: 1, sm: 2 }}
          sx={{
            alignItems: 'center'
          }}
        >
          <Stack
            direction="row"
            spacing={{ xs: 1, sm: 2 }}
            sx={{
              alignItems: 'center'
            }}
          >
            {renderOptionalView != null && <Box>{renderOptionalView({})}</Box>}

            {(hasContentFilters != null || hasSectionFilters != null) && (
              <CalendarHeaderFilterButtons
                hasContentFilters={hasContentFilters}
                hasSectionFilters={hasSectionFilters}
                onFilterContentsPress={onContentFiltersPress}
                onFilterSectionsPress={onSectionFiltersPress}
              />
            )}

            <CalendarHeaderActionButtons options={actionButtons ?? []} />
          </Stack>

          <CalendarHeaderConfigInformationBox
            title={viewModel.profileDisplayName}
            subTitle={viewModel.schoolName}
            isSyncing={viewModel.isSyncing}
            firstName={viewModel.firstName}
            lastName={viewModel.lastName}
            isImpersonating={viewModel.isImpersonating}
            avatarColor={viewModel.profileColor}
            onPress={() => void viewModel.displaySettings()}
            isDemoSchool={viewModel.isDemoSchool}
            isPreventingChanges={viewModel.isPreventingChanges}
          />
        </Stack>
      </Box>
    );
  }
);
