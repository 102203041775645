import { CalendarMonth } from '@mui/icons-material';
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  Stack,
  SxProps,
  Typography
} from '@mui/material';
import { SchoolDay } from '@shared/models/calendar';
import { AllDayOfWeek, DayOfWeek } from '@shared/models/types';
import { DropDownSelector, MultiDropDownSelector, SchoolDayPicker } from '@studyo/components';
import { ContentRepeatKind, ContentRepeatViewModel } from '@studyo/viewmodels';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useStudyoServices } from '../../UseStudyoServicesHook';

export interface ContentRepeatViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: ContentRepeatViewModel;
}

export const ContentRepeatView = observer((props: ContentRepeatViewProps) => {
  const { localizationService, viewModelFactory } = useStudyoServices();
  const { viewModel, sx = [], className } = props;
  const strings = localizationService.localizedStrings.studyo.contents.contentRepeat;
  const dayFormat = localizationService.localizedStrings.models.dateFormats.short;
  const repeatingDays = viewModel.repeatingDays;
  const firstDays = _.take(repeatingDays, 5);
  const lastDay = firstDays.length < repeatingDays.length ? repeatingDays[repeatingDays.length - 1] : undefined;

  const dateCellRef = useRef<HTMLDivElement | null>(null);
  const [displaySchoolDayPicker, setDisplaySchoolDayPicker] = useState(false);

  useEffect(() => {
    viewModel.onInit();
    return () => viewModel.onDestroy();
  }, [viewModel]);

  const repeatKindOptions = useMemo<ContentRepeatKind[]>(() => {
    return viewModel.canRepeatByCycleDay
      ? ['occurrence', 'day', 'day-of-week', 'cycle-day']
      : ['occurrence', 'day', 'day-of-week'];
  }, [viewModel.canRepeatByCycleDay]);

  const getRepeatKindTitle = (value: ContentRepeatKind) => {
    const strings = localizationService.localizedStrings.studyo.contents.contentRepeat;

    switch (value) {
      case 'occurrence':
        return strings.repeatEveryOccurrence;

      case 'day':
        return strings.repeatEveryDay;

      case 'day-of-week':
        return strings.repeatEveryDayOfWeek;

      case 'cycle-day':
        return strings.repeatEveryCycleDay;
    }
  };

  const showDatePicker = () => {
    setDisplaySchoolDayPicker(true);
  };

  const hideDatePicker = () => {
    setDisplaySchoolDayPicker(false);
  };

  const didSelectSchoolDay = (schoolDay: SchoolDay) => {
    viewModel.untilDate = schoolDay.day;
    hideDatePicker();
  };

  const createSchoolDayPickerViewModel = () => {
    const vm = viewModelFactory.createSchoolDayPicker();
    vm.setCurrentDay(viewModel.untilDate);
    vm.setSelectedDay(viewModel.untilDate);
    vm.setMinimumAndMaximumDays(viewModel.minimumUntilDate, viewModel.maximumUntilDate);
    return vm;
  };

  return (
    <List sx={{ ...sx, overflow: 'auto' }} className={className} disablePadding>
      <ListItem>
        <ListItemText primary={strings.repeatKind} />
        <DropDownSelector
          options={repeatKindOptions}
          selectedIndex={repeatKindOptions.indexOf(viewModel.repeatKind)}
          didSelectOption={(index) => (viewModel.repeatKind = repeatKindOptions[index])}
          displayValueForOption={(option) => getRepeatKindTitle(option as ContentRepeatKind)}
        />
      </ListItem>
      <Divider />
      {viewModel.repeatKind === 'day-of-week' && (
        <>
          <ListItem>
            <ListItemText
              primary={strings.weekCountLabel}
              secondary={viewModel.selectedWeekCount > 1 ? strings.weekStart : undefined}
            />
            <DropDownSelector
              options={_.range(1, 5).map((w) => strings.weekCount(w))}
              selectedIndex={viewModel.selectedWeekCount - 1}
              didSelectOption={(index) => (viewModel.selectedWeekCount = index + 1)}
              displayValueForOption={(_, index) => strings.weekCount(index + 1)}
            />
          </ListItem>
          <Divider />
        </>
      )}
      {viewModel.repeatKind === 'day-of-week' && (
        <>
          <ListItem>
            <ListItemText
              primary={strings.daysOfWeek}
              secondary={
                firstDays.map((day) => day.formattedString(dayFormat)).join(', ') +
                (lastDay != null ? `… ${lastDay.formattedString(dayFormat)}` : '')
              }
            />

            <MultiDropDownSelector
              options={AllDayOfWeek}
              selectedIndexes={viewModel.selectedDaysOfWeek.map((dow) => AllDayOfWeek.indexOf(dow))}
              didSelectOptions={(values) => (viewModel.selectedDaysOfWeek = values.map((v) => AllDayOfWeek[v]))}
              renderPreview={() => (
                <Typography variant="body2">
                  {viewModel.selectedDaysOfWeek.length === 0
                    ? strings.noDaysOfWeek
                    : viewModel.selectedDaysOfWeek
                        .map((dow) => localizationService.localizedStrings.models.dayOfWeek.localizedDayOfWeek(dow))
                        .join(', ')}
                </Typography>
              )}
              displayValueForOption={(option) =>
                localizationService.localizedStrings.models.dayOfWeek.localizedDayOfWeek(option as DayOfWeek)
              }
            />
          </ListItem>

          <Divider />
        </>
      )}
      {viewModel.repeatKind === 'cycle-day' && (
        <ListItem>
          <ListItemText primary={strings.cycleDay} />
          <DropDownSelector
            options={viewModel.cycleDays}
            selectedIndex={viewModel.selectedCycleDay - 1}
            didSelectOption={(index) => (viewModel.selectedCycleDay = index + 1)}
          />
        </ListItem>
      )}
      <ListItemButton onClick={showDatePicker}>
        <ListItemText primary={strings.untilDate} />

        <Stack
          ref={dateCellRef}
          direction="row"
          spacing={1}
          sx={{
            alignItems: 'center'
          }}
        >
          <Typography variant="body2">
            {viewModel.untilDate.formattedString(localizationService.localizedStrings.models.dateFormats.medium)}
          </Typography>

          <CalendarMonth fontSize="small" color="primary" />
        </Stack>
      </ListItemButton>
      <Popover
        open={displaySchoolDayPicker}
        onClose={hideDatePicker}
        anchorEl={dateCellRef.current}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        slotProps={{ paper: { elevation: 2 } }}
      >
        <Box
          sx={{
            display: 'flex',
            py: 1.5,
            width: 400,
            height: 330
          }}
        >
          <SchoolDayPicker
            sx={{ flex: 1 }}
            viewModel={createSchoolDayPickerViewModel()}
            onSelection={didSelectSchoolDay}
          />
        </Box>
      </Popover>
    </List>
  );
});
