import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, Stack, SxProps, Typography, useMediaQuery, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { StudyoThemeService } from '../../services';
import { DialogHeaderButton, DialogHeaderButtonOptions } from './DialogHeaderButton';

export interface DialogHeaderProps {
  sx?: SxProps;
  className?: string;
  title: string;
  subtitle?: string;
  color?: string;
  tintColor?: string;
  rightButton?: DialogHeaderButtonOptions;
  bandColor?: string;
  themeService?: StudyoThemeService;
  onClose?: () => void;
}

export const DialogHeader = observer((props: DialogHeaderProps) => {
  const { sx = [], className, subtitle, title, color, tintColor, rightButton, bandColor, onClose } = props;
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));

  const resolvedTintColor = tintColor ?? (color != null ? theme.palette.getContrastText(color) : undefined);
  const showCloseButton = onClose != null && isExtraSmallScreen;
  const showRightButton =
    rightButton != null && !(rightButton.onlyShowOnExtraSmallScreen === true && !isExtraSmallScreen);

  return (
    <Stack sx={{ ...sx, backgroundColor: color, color: resolvedTintColor }} className={className}>
      <Stack
        direction="row"
        sx={{
          pl: { xs: 1, sm: 2 },
          pr: { xs: 1, sm: showRightButton ? 1 : 2 },
          py: { xs: 1, sm: showCloseButton || showRightButton ? 1 : 1.5 },
          alignItems: 'center'
        }}
      >
        {showCloseButton && (
          <DialogHeaderButton
            options={{
              action: () => onClose(),
              icon: (size) => <CloseIcon sx={{ color: resolvedTintColor ?? 'inherit' }} fontSize={size} />
            }}
            defaultColor={tintColor}
          />
        )}

        <Stack
          sx={{
            ml: showCloseButton ? 1 : 0.5,
            mr: rightButton != null ? 2 : 0,
            flex: 1,
            overflow: 'hidden'
          }}
        >
          <Typography
            variant={subtitle != null && isExtraSmallScreen ? 'body1' : 'h6'}
            noWrap
            sx={{
              color: 'inherit',
              fontWeight: isExtraSmallScreen && subtitle == null ? '400' : '500'
            }}
          >
            {title}
          </Typography>

          {subtitle != null && (
            <Typography
              variant="body2"
              noWrap
              sx={{
                color: 'inherit'
              }}
            >
              {subtitle}
            </Typography>
          )}
        </Stack>

        {showRightButton && <DialogHeaderButton options={rightButton} defaultColor={tintColor} />}
      </Stack>
      {bandColor != null && <Box sx={{ backgroundColor: bandColor, height: 4 }} />}
      {color == null && <Divider />}
    </Stack>
  );
});
