import { Stack, SxProps, Typography } from '@mui/material';
import { TaskIcon } from '@shared/components/contents';
import { ContentDefinitionUtils, isSxArray } from '@shared/components/utils';
import { ContentDefinitionModel } from '@shared/models/content';
import { observer } from 'mobx-react-lite';
import { useStudyoServices } from '../../UseStudyoServicesHook';

export interface ContentActionHeaderProps {
  sx?: SxProps;
  className?: string;
  content: ContentDefinitionModel;
}

export const ContentActionHeader = observer((props: ContentActionHeaderProps) => {
  const { localizationService } = useStudyoServices();
  const { sx = [], className, content } = props;

  // Note: No publish error overlay here.

  return (
    <Stack
      direction="row"
      className={className}
      spacing={2}
      sx={[
        {
          p: 2,
          alignItems: 'center'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <TaskIcon
        icon={content.icon}
        workloadLevel={content.workloadLevel}
        squareSize={36}
        externalSource={content.externalContent != null ? content.externalContent.sourceIntegration : undefined}
        hasSteps={content.steps.length > 0}
      />
      <Stack
        sx={{
          flex: 1
        }}
      >
        <Typography
          noWrap
          variant="body1"
          sx={{
            fontWeight: '500'
          }}
        >
          {ContentDefinitionUtils.getDisplayTitleForContent(content, localizationService.localizedStrings)}
        </Typography>

        {content.notes.length > 0 && (
          <Typography noWrap variant="body2">
            {content.notes}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
});
