import { ContentDefinitionUtils, SectionUtils } from '@shared/components/utils';
import { SchoolDay, SchoolDayPeriod } from '@shared/models/calendar';
import { AccountModel, SectionModel } from '@shared/models/config';
import { Color } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { AccountData } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';
import { ContentPasteboardStore, NavigationService } from '../../../services';
import { ContentListItemViewModel } from './ContentListItemViewModel';

export interface SchoolDayContentListSectionViewModel {
  readonly canEdit: boolean;
  readonly canPaste: boolean;

  readonly hasSection: boolean;
  readonly periodTag: string | undefined;
  readonly sectionTitle: string | undefined;
  readonly sectionColor: Color | undefined;
  readonly contents: ContentListItemViewModel[];

  addTask: () => void;
  pasteTask: () => Promise<void>;
}

export class AppSchoolDayContentListSectionViewModel implements SchoolDayContentListSectionViewModel {
  constructor(
    private readonly _contentPasteboardStore: ContentPasteboardStore,
    private readonly _navigationService: NavigationService,
    private readonly _localizationService: LocalizationService,
    private readonly _configId: string,
    private readonly _account: AccountModel,
    private readonly _section: SectionModel | undefined,
    private readonly _schoolDay: SchoolDay,
    private readonly _period: SchoolDayPeriod | undefined,
    private readonly _data: AccountData,
    private readonly _contents: () => ContentListItemViewModel[],
    public readonly canEdit: boolean
  ) {
    makeObservable(this);
  }

  @computed
  get canPaste() {
    return this._contentPasteboardStore.storedContent != null;
  }

  @computed
  get hasSection(): boolean {
    return this._section != null;
  }

  @computed
  get periodTag() {
    if (this._period == null || this._data.config.scheduleKind === 'class-times') {
      return undefined;
    }

    return this._period.tag;
  }

  @computed
  get sectionTitle(): string | undefined {
    return this._section != null ? this._section.title : undefined;
  }

  @computed
  get sectionColor(): Color | undefined {
    return SectionUtils.getSectionColor(this._section, this._account, undefined);
  }

  @computed
  get contents(): ContentListItemViewModel[] {
    return this._contents()
      .slice()
      .sort((c1, c2) =>
        ContentDefinitionUtils.compareLocalizedContents(
          { content: c1.content, isAssignment: c1.isAssignment },
          { content: c2.content, isAssignment: c2.isAssignment },
          this._localizationService.localizedStrings,
          this._localizationService.currentLocale
        )
      );
  }

  addTask() {
    if (this.canEdit) {
      void this._navigationService.navigateToTaskEditModal(
        undefined,
        this._schoolDay.day,
        this.periodTag,
        this._section != null ? this._section.id : undefined
      );
    }
  }

  async pasteTask() {
    if (this.canEdit) {
      const savedContent = this._contentPasteboardStore.storedContent;
      if (savedContent != null) {
        try {
          if (this.periodTag != null && this.periodTag.length > 0) {
            await this._contentPasteboardStore.pasteContentInPeriod(
              savedContent,
              this._schoolDay.day,
              this.periodTag,
              this._data,
              undefined,
              false,
              false
            );
          } else {
            await this._contentPasteboardStore.pasteContentInDay(
              savedContent,
              this._schoolDay.day,
              this._section != null ? this._section.id : undefined,
              this._data,
              false
            );
          }
        } catch {
          console.warn(`Unable to paste task with id ${savedContent.id}`);
        }
      }
    }
  }
}
