import { Check } from '@mui/icons-material';
import { Checkbox, FormControlLabel, Stack, SxProps, TextField, useTheme } from '@mui/material';
import { SectionColors } from '@shared/models/Colors.ts';
import { DataPresenter, DialogHeader } from '@studyo/components';
import { PlannerPeriodEditViewModel } from '@studyo/viewmodels';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useStudyoServices } from '../../UseStudyoServicesHook';

export interface PlannerPeriodEditViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: PlannerPeriodEditViewModel;
}

export const PlannerPeriodEditView = observer((props: PlannerPeriodEditViewProps) => {
  const { localizationService } = useStudyoServices();
  const { sx = [], className, viewModel } = props;
  const theme = useTheme();
  const strings = localizationService.localizedStrings.studyo.agenda.planner.periodEditStrings;

  useEffect(() => {
    viewModel.onInit();
    return () => viewModel.onDestroy();
  }, [viewModel]);

  return (
    <DataPresenter
      sx={sx}
      className={className}
      dataLoaders={[viewModel.data]}
      renderData={() => (
        <Stack
          sx={{
            height: '100%',
            width: '100%',
            overflow: 'hidden'
          }}
        >
          <DialogHeader
            title={strings.title}
            color={SectionColors.get(viewModel.sectionColor)}
            tintColor={theme.studyo.periods.periodWithSectionTextColor}
            rightButton={{
              icon: (size) => <Check color="inherit" fontSize={size} />,
              color: 'inherit',
              action: () => void viewModel.save(),
              onlyShowOnExtraSmallScreen: true
            }}
          />

          <Stack
            spacing={2}
            sx={{
              flex: 1,
              overflow: 'auto',
              p: 2
            }}
          >
            <FormControlLabel
              value="includeTitles"
              control={
                <Checkbox
                  checked={!viewModel.isSkipped}
                  onChange={(event) => (viewModel.isSkipped = !event.target.checked)}
                />
              }
              label={strings.classIsOccurringOption}
              labelPlacement="end"
            />

            <TextField
              value={viewModel.customOccurrenceTitle}
              label={strings.periodTitleLabel}
              placeholder={viewModel.defaultOccurrenceTitle}
              onChange={(event) => (viewModel.customOccurrenceTitle = event.target.value)}
              variant="filled"
            />
            <TextField
              value={viewModel.customOccurrenceRoomName}
              label={strings.roomNameLabel}
              onChange={(event) => (viewModel.customOccurrenceRoomName = event.target.value)}
              variant="filled"
            />
          </Stack>
        </Stack>
      )}
    />
  );
});
