import { CircularProgress, ListItemButton, ListItemIcon, ListItemText, SxProps } from '@mui/material';
import { intlFormatDistance } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { now } from 'mobx-utils';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { ProfileMenuSynchronizeOptionViewModel } from '../../../viewmodels';
import { TintedListItemIcon } from '../../lists';

export interface ProfileMenuSynchronizeRowProps {
  sx?: SxProps;
  className?: string;
  viewModel: ProfileMenuSynchronizeOptionViewModel;
}

export const ProfileMenuSynchronizeRow = observer((props: ProfileMenuSynchronizeRowProps) => {
  const { imageService, localizationService } = useStudyoServices();
  const { viewModel, sx = [], className } = props;
  const { isSyncing, isOffline } = viewModel;

  const strings = localizationService.localizedStrings.studyo.agenda.setting.profileMenu;

  const synchronize = () => {
    viewModel.synchronize();
  };

  const subtitle = () => {
    const { lastSynchronizationError, lastSynchronizationTimestamp } = viewModel;

    const strings = localizationService.localizedStrings.studyo.agenda.setting.profileMenu;
    return lastSynchronizationError != null
      ? strings.synchronizationError
      : lastSynchronizationTimestamp != null
        ? strings.getLastSynchronizationString(
            intlFormatDistance(lastSynchronizationTimestamp, new Date(now()), {
              locale: localizationService.currentLocale
            })
          )
        : undefined;
  };

  return (
    <>
      <ListItemButton sx={sx} className={className} onClick={synchronize} disabled={isSyncing || isOffline}>
        {isSyncing ? (
          <ListItemIcon>
            <CircularProgress size={24} color="inherit" />
          </ListItemIcon>
        ) : (
          <TintedListItemIcon icon={imageService.studyoImages.settings.profileMenu.synchronize} isTextPrimaryColor />
        )}

        <ListItemText
          primary={isSyncing ? strings.synchronizing : strings.synchronize}
          secondary={isSyncing ? undefined : subtitle()}
        />
      </ListItemButton>
    </>
  );
});
