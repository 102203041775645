import SearchIcon from '@mui/icons-material/Search';
import { Box, InputAdornment, Stack, SxProps, TextField, Typography } from '@mui/material';
import { isSxArray } from '@shared/components/utils';
import { CourseSelectionFilterCell, CourseSelectionSectionList } from '@studyo/components';
import { CourseSelectionViewModel } from '@studyo/viewmodels';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useStudyoServices } from '../../UseStudyoServicesHook';

export interface CourseSelectionViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: CourseSelectionViewModel;
}

export const CourseSelectionView = observer((props: CourseSelectionViewProps) => {
  const { localizationService } = useStudyoServices();
  const { sx = [], className, viewModel } = props;
  const { courseSelection: strings } = localizationService.localizedStrings.studyo.agenda.setting;

  useEffect(() => {
    viewModel.onInit();
    return () => viewModel.onDestroy();
  }, []);

  const didSelectNewGradeLevel = (index: number | undefined) => {
    viewModel.didSelectNewGradeLevel(index);
    viewModel.gradeLevels.selectedIndex = index;
  };

  const didSelectNewSectionNumber = (index: number | undefined) => {
    viewModel.didSelectNewSectionNumber(index);
    viewModel.sectionNumbers.selectedIndex = index;
  };

  const didSelectNewTeacher = (index: number | undefined) => {
    viewModel.didSelectNewTeacher(index);
    viewModel.teachers.selectedIndex = index;
  };

  const didChangeSelectedForSectionId = (sectionId: string, selected: boolean) => {
    viewModel.didChangeSelectedForSectionId(sectionId, selected);
  };

  const hasGradeLevelFilter = viewModel.gradeLevels.selectedIndex != null;
  const hasSectionNumberFilter = viewModel.sectionNumbers.selectedIndex != null;
  const hasTeachersFilters = viewModel.teachers.selectedIndex != null;

  return (
    <Stack
      className={className}
      spacing={2}
      sx={[
        {
          overflow: 'hidden',
          py: 2
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <Stack
        spacing={2}
        sx={{
          px: 2
        }}
      >
        <TextField
          variant="filled"
          value={viewModel.searchText}
          onChange={(e) => (viewModel.searchText = e.currentTarget.value)}
          label={strings.searchPlaceholder}
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              )
            }
          }}
        />

        <Stack
          direction="row"
          spacing={1}
          sx={{
            flexWrap: 'wrap',
            alignItems: 'center'
          }}
        >
          <Typography variant="body2">{strings.filtersLabel}</Typography>

          {!hasTeachersFilters && (
            <CourseSelectionFilterCell
              includeNoneValue={true}
              title={strings.gradeLevelTitle}
              didSelectNewIndex={didSelectNewGradeLevel}
              viewModel={viewModel.gradeLevels}
            />
          )}

          {!hasTeachersFilters && (
            <CourseSelectionFilterCell
              includeNoneValue={viewModel.showNoneValueForSectionNumberFilter}
              title={strings.sectionNumberTitle}
              didSelectNewIndex={didSelectNewSectionNumber}
              viewModel={viewModel.sectionNumbers}
            />
          )}

          {!hasGradeLevelFilter && !hasSectionNumberFilter && (
            <CourseSelectionFilterCell
              includeNoneValue={false}
              title={strings.teacherTitle}
              didSelectNewIndex={didSelectNewTeacher}
              viewModel={viewModel.teachers}
            />
          )}
        </Stack>
      </Stack>
      {viewModel.searchResultsViewModel != null && (
        <Box
          sx={{
            overflow: 'auto',
            flex: 1,
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <CourseSelectionSectionList
            sx={{ flex: 1 }}
            viewModel={viewModel.searchResultsViewModel}
            isSearchResults
            didChangeSelectedForSectionId={didChangeSelectedForSectionId}
          />
        </Box>
      )}
      {viewModel.searchResultsViewModel == null && (
        <Box
          sx={{
            overflow: 'auto',
            flex: 1
          }}
        >
          <Stack spacing={2}>
            <Typography
              variant="caption"
              sx={{
                color: (theme) => theme.palette.text.secondary,
                px: 2
              }}
            >
              {strings.filterSubtitle}
            </Typography>

            <Typography
              variant="subtitle2"
              sx={{
                textAlign: 'center',
                px: 2
              }}
            >
              {strings.selectedCoursesTitle}
            </Typography>

            <CourseSelectionSectionList
              sx={{ flex: 1 }}
              viewModel={viewModel.selectedCourses}
              didChangeSelectedForSectionId={didChangeSelectedForSectionId}
            />
          </Stack>
        </Box>
      )}
    </Stack>
  );
});
