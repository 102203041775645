import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import { IconButton, Stack, SxProps } from '@mui/material';
import { isSxArray } from '@shared/components/utils';
import { observer } from 'mobx-react-lite';
import { DateSwitcherContext } from '../../../viewmodels';
import { DateSwitcher } from '../../utils';

export interface CalendarHeaderNavigationButtonsProps {
  sx?: SxProps;
  className?: string;
  showArrows?: boolean;
  disableNextArrow?: boolean; // defaults to false
  disablePreviousArrow?: boolean; // defaults to false
  navigateToNext?: () => void;
  navigateToPrevious?: () => void;
  context: DateSwitcherContext;
}

export const CalendarHeaderNavigationButtons = observer((props: CalendarHeaderNavigationButtonsProps) => {
  const {
    sx = [],
    className,
    showArrows = true,
    disableNextArrow = false,
    disablePreviousArrow = false,
    navigateToNext,
    navigateToPrevious,
    context
  } = props;

  return (
    <Stack
      direction="row"
      className={className}
      sx={[
        {
          alignItems: 'center'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      {showArrows && navigateToPrevious != null && (
        <IconButton onClick={() => navigateToPrevious()} disabled={disablePreviousArrow} size="small">
          <ArrowBackIosNew fontSize="medium" />
        </IconButton>
      )}
      <DateSwitcher context={context} />
      {showArrows && navigateToNext != null && (
        <IconButton onClick={() => navigateToNext()} disabled={disableNextArrow} size="small">
          <ArrowForwardIos fontSize="medium" />
        </IconButton>
      )}
    </Stack>
  );
});
