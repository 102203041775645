import { InputBase, ListItem, Paper, SxProps } from '@mui/material';
import { TaskEditViewModel } from '@studyo/viewmodels';
import { observer } from 'mobx-react-lite';
import { useStudyoServices } from '../../../UseStudyoServicesHook';

export interface TaskEditNotesCellProps {
  sx?: SxProps;
  className?: string;
  viewModel: TaskEditViewModel;
}

export const TaskEditNotesCell = observer((props: TaskEditNotesCellProps) => {
  const { localizationService } = useStudyoServices();
  const { sx = [], className, viewModel } = props;

  return (
    <ListItem sx={sx} className={className}>
      <Paper
        sx={{
          height: 150,
          flex: 1,
          borderWidth: 0,
          borderRadius: 0,
          overflow: 'auto',
          backgroundColor: 'transparent'
        }}
        elevation={0}
      >
        <InputBase
          sx={{ height: '100%', width: '100%', fontSize: '1em', alignItems: 'flex-start' }}
          placeholder={localizationService.localizedStrings.studyo.contents.taskEdit.notesPlaceholder}
          value={viewModel.notes}
          onChange={(e) => (viewModel.notes = e.currentTarget.value)}
          multiline
          minRows={0}
        />
      </Paper>
    </ListItem>
  );
});
