import { Check } from '@mui/icons-material';
import { SxProps, useMediaQuery, useTheme } from '@mui/material';
import { SectionColors } from '@shared/models/Colors.ts';
import { Day } from '@shared/models/types';
import { DialogResult } from '@shared/services';
import {
  ConfirmDialog,
  DialogActionButtons,
  DialogButton,
  DialogHeader,
  ResponsiveDialog,
  SaveDialog,
  useSaveDialog
} from '@studyo/components';
import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { NoteEditView } from '../../../views';

export interface NoteEditScreenProps extends DialogResult<string> {
  sx?: SxProps;
  className?: string;
  day: Day;
  periodTag: string;
  sectionId?: string;
}

export const NoteEditScreen = observer((props: NoteEditScreenProps) => {
  const { localizationService, viewModelFactory } = useStudyoServices();
  const { day, sx = [], className, periodTag, sectionId, onSuccess, onCancel } = props;
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));

  const viewModel = useMemo(
    () => viewModelFactory.createNoteEdit(day, periodTag, sectionId, onSuccess!, onCancel!),
    [day, sectionId, periodTag]
  );
  const strings = localizationService.localizedStrings.studyo.contents.noteEdit;

  const [displayCancelChangesDialog, setDisplayCancelChangesDialog] = useState(false);
  const [savePromise, startSave, resetSave, retrySave] = useSaveDialog(() => viewModel.save());

  const cancelButtonPressed = () => {
    if (viewModel.hasChanges) {
      showCancelConfirmationDialog();
    } else {
      void viewModel.cancel();
    }
  };

  const cancel = () => {
    hideCancelConfirmationDialog();
    void viewModel.cancel();
  };

  const showCancelConfirmationDialog = () => {
    setDisplayCancelChangesDialog(true);
  };

  const hideCancelConfirmationDialog = () => {
    setDisplayCancelChangesDialog(false);
  };

  const saveDialogClose = (success: boolean) => {
    resetSave();
    if (success) {
      viewModel.closeAllModals();
    }
  };

  const closeButtonPressed = () => {
    void viewModel.cancel();
  };

  const headerColor = viewModel.isSkipped
    ? theme.studyo.periods.skippedPeriodColor
    : viewModel.sectionColor != null
      ? SectionColors.get(viewModel.sectionColor)
      : theme.studyo.periods.freePeriodColor;

  const headerTintColor =
    viewModel.sectionColor != null && !viewModel.isSkipped
      ? theme.studyo.periods.periodWithSectionTextColor
      : theme.studyo.periods.freePeriodTextColor;

  return (
    <ResponsiveDialog
      sx={sx}
      className={className}
      maxWidth="sm"
      fullWidth={true}
      open={true}
      maxHeight="large"
      PaperProps={{
        style: {
          backgroundColor: theme.studyo.contents.icons.notesBackgroundColor
        }
      }}
      onClose={viewModel.canEdit ? cancelButtonPressed : closeButtonPressed}
    >
      {isExtraSmallScreen && (
        <DialogHeader
          title={strings.title}
          color={headerColor}
          tintColor={headerTintColor}
          onClose={viewModel.canEdit ? cancelButtonPressed : closeButtonPressed}
          rightButton={
            viewModel.canEdit
              ? {
                  color: 'inherit',
                  icon: (size) => <Check color="inherit" fontSize={size} />,
                  onlyShowOnExtraSmallScreen: true,
                  action: startSave
                }
              : undefined
          }
        />
      )}

      <NoteEditView viewModel={viewModel} sx={{ flex: 1 }} />

      <DialogActionButtons minimumScreenSize="sm">
        {viewModel.canEdit && <DialogButton title={strings.cancel} type="cancel" onPress={cancelButtonPressed} />}

        {viewModel.canEdit && (
          <DialogButton title={strings.save} type="normal" variant="contained" onPress={startSave} />
        )}

        {!viewModel.canEdit && <DialogButton title={strings.close} type="normal" onPress={closeButtonPressed} />}
      </DialogActionButtons>

      <ConfirmDialog
        open={displayCancelChangesDialog}
        title={strings.unsavedChangesTitle}
        description={strings.unsavedChangesDescription}
        cancelTitle={strings.unsavedChangesStayTitle}
        onCancelPress={hideCancelConfirmationDialog}
        confirmTitle={strings.unsavedChangesDiscardTitle}
        onConfirmPress={cancel}
        onClose={hideCancelConfirmationDialog}
      />

      <SaveDialog
        promise={savePromise}
        retryCall={retrySave}
        onClose={saveDialogClose}
        titles={{
          saving: strings.savingTitle,
          saved: strings.savedTitle,
          error: strings.saveErrorTitle
        }}
        descriptions={{
          saving: strings.savingDescription,
          saved: strings.savedDescription,
          error: strings.saveErrorDescription,
          permanentError: strings.savePermanentErrorDescription
        }}
      />
    </ResponsiveDialog>
  );
});
