import { ContentAttachmentModel } from '@shared/models/content';
import { LocalizationService } from '@shared/resources/services';
import { AttachmentManager, GoogleDriveFileInfo } from './AttachmentManager';
import { WebEnvironmentService } from './WebEnvironmentService';

export class WebAttachmentManager implements AttachmentManager {
  //
  // GoogleDrive properties
  //
  private readonly _googleDeveloperKey: string;
  private readonly _googleClientId: string;
  private _googleDriveScope = 'https://www.googleapis.com/auth/drive.file';
  private _googleDriveOauthToken: string | undefined = undefined;

  constructor(
    environmentServiceResolver: () => WebEnvironmentService,
    private readonly _localizationServiceResolver: () => LocalizationService
  ) {
    const env = environmentServiceResolver();
    this._googleDeveloperKey = env.googleDrivePickerKey;
    this._googleClientId = env.googleClientId;
  }

  openUrl = (url: string) => {
    window.open(url);
  };

  openAttachment = (attachment: ContentAttachmentModel) => {
    window.open(attachment.externalUrl || attachment.fileUrl);
  };

  takePicture = () => {
    throw new Error('Selecting photo attachments is not implemented on the web.');
  };

  openCameraRoll = () => {
    throw new Error('Selecting photo attachments is not implemented on the web.');
  };

  getGoogleDriveFile = async () => {
    try {
      await this.loadGoogleAPIs();
      await this.presentGoogleAuth();
      return await this.presentGoogleDrivePicker();
    } catch (e) {
      console.warn(e);
      return Promise.reject(e as Error);
    }
  };

  private loadGoogleAPIs = (): Promise<void> => {
    return new Promise((resolveLoad, reject) => {
      gapi.load('picker', {
        callback: () => resolveLoad(),
        onerror: (error: Error) => {
          console.warn(error);
          reject(error);
        }
      });
    });
  };

  private presentGoogleAuth = async () => {
    return new Promise((resolveAuth, reject) => {
      const client = google.accounts.oauth2.initTokenClient({
        client_id: this._googleClientId,
        scope: this._googleDriveScope,
        callback: (response) => {
          if (response.error !== undefined) {
            reject(new Error(response.error_description));
          } else {
            this._googleDriveOauthToken = response.access_token;
            resolveAuth(response.access_token);
          }
        }
      });

      if (this._googleDriveOauthToken == null) {
        // Prompt the user to select a Google Account and ask for consent to share their data
        // when establishing a new session.
        client.requestAccessToken({ prompt: 'consent' });
      } else {
        resolveAuth(this._googleDriveOauthToken);
      }
    });
  };

  /* eslint-disable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-explicit-any */

  private presentGoogleDrivePicker = (): Promise<GoogleDriveFileInfo | undefined> => {
    return new Promise((resolvePicker, reject) => {
      if (this._googleDriveOauthToken != null) {
        // eslint-disable-next-line prefer-const
        let picker: google.picker.Picker;

        const callback = (data: any) => {
          if (data.action === google.picker.Action.PICKED) {
            const doc = data[google.picker.Response.DOCUMENTS][0];
            const fileId = doc[google.picker.Document.ID];
            const url = doc[google.picker.Document.URL];
            const filename = doc[google.picker.Document.NAME];

            picker.dispose();
            resolvePicker({ fileId, url, filename });
          } else if (data.action === google.picker.Action.CANCEL) {
            picker.dispose();
            resolvePicker(undefined);
          }
        };

        picker = new google.picker.PickerBuilder()
          .addView(new google.picker.DocsView().setIncludeFolders(true).setOwnedByMe(true))
          .setOAuthToken(this._googleDriveOauthToken)
          .setLocale(this._localizationServiceResolver().currentLocale)
          .setDeveloperKey(this._googleDeveloperKey)
          .setCallback(callback)
          .setMaxItems(1)
          .build();
        picker.setVisible(true);
      } else {
        reject(new Error('Trying to present GoogleDrive picker when no oauthToken set'));
      }
    });
  };
}
