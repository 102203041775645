import { AuthorizationRole } from '@shared/models/types';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';
import { useStudyoServices } from '../../UseStudyoServicesHook';

export interface AuthorizationRoleConditionProps {
  /**
   * An array of all the allowed roles.
   *
   * **NOTE:** If you are interested in roles other that `super-admin`,
   * you must specify the `configId` or else, you will always have
   * `false` as a result.
   */
  allowedRoles: AuthorizationRole[];
  children?: ReactNode;
}

export const AuthorizationRoleCondition = observer((props: AuthorizationRoleConditionProps) => {
  const { accountService } = useStudyoServices();
  const { allowedRoles, children } = props;

  const isAllowed = accountService.isAllowed(allowedRoles);

  if (!isAllowed) {
    return null;
  }

  return <>{children}</>;
});
