import { Box, Divider, Stack, SxProps } from '@mui/material';
import { isSxArray } from '@shared/components/utils';
import { SchoolDay } from '@shared/models/calendar';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { PeriodsHeader, PeriodsList, PeriodsSectionList, SchoolDayPicker } from '../../components';
import { AgendaPeriodsViewModel } from '../../viewmodels';

export interface PeriodsViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: AgendaPeriodsViewModel;
}

export const PeriodsView = observer((props: PeriodsViewProps) => {
  const { sx = [], className, viewModel } = props;
  useEffect(() => {
    viewModel.setSchoolDayPickerDay();
  }, []);

  const didSelectSchoolDay = (schoolDay: SchoolDay) => {
    viewModel.didSelectSchoolDay(schoolDay);
  };

  return (
    <Stack
      className={className}
      sx={[
        {
          overflow: 'hidden'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <Box>
        <PeriodsHeader viewModel={viewModel.headerViewModel} />
      </Box>
      <Divider />
      <Stack
        direction="row"
        sx={{
          flex: 1,
          overflow: 'hidden'
        }}
      >
        <Stack
          sx={{
            minWidth: 200,
            maxWidth: 400,
            flexGrow: 1,
            flexBasis: 0,
            overflow: 'hidden'
          }}
        >
          <PeriodsSectionList
            sx={{ flex: 1 }}
            selectedSection={viewModel.selectedSection}
            viewModel={viewModel.sectionsListViewModel}
          />

          <Divider />

          <Box
            sx={{
              pt: 1
            }}
          >
            <SchoolDayPicker
              sx={{ height: 300 }}
              viewModel={viewModel.schoolDayPickerViewModel}
              onSelection={didSelectSchoolDay}
            />
          </Box>
        </Stack>

        <Divider orientation="vertical" />

        <PeriodsList sx={{ flexGrow: 3, flexBasis: 0, minWidth: 300 }} viewModel={viewModel.periodsListViewModel} />
      </Stack>
    </Stack>
  );
});
