import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, ListItemButton, ListItemText, SxProps, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { AttachmentListItemViewModel } from '../../../viewmodels';

export interface TaskAttachmentListItemProps {
  sx?: SxProps;
  className?: string;
  viewModel: AttachmentListItemViewModel;
}

export const TaskAttachmentListItem = observer((props: TaskAttachmentListItemProps) => {
  const { viewModel, sx = [], className } = props;
  const theme = useTheme();

  return (
    <ListItemButton sx={sx} className={className} onClick={() => viewModel.display()} component="div">
      <img
        src={viewModel.iconSource}
        style={{
          width: 40,
          height: 40,
          objectFit: viewModel.isPhoto ? 'cover' : 'contain',
          marginRight: theme.spacing(2),
          borderRadius: theme.shape.borderRadius
        }}
        alt={viewModel.displayTitle}
      />

      <ListItemText primary={viewModel.displayTitle} />

      {viewModel.canDelete && (
        <IconButton
          color="error"
          onClick={(e) => {
            e.stopPropagation();
            viewModel.delete();
          }}
        >
          <DeleteIcon />
        </IconButton>
      )}
    </ListItemButton>
  );
});
