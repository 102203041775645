import { Box, CardActionArea, SxProps, Typography, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { TaskDueChildHeight } from './TaskDueStyles';

interface TaskDuePeriodBoxProps {
  sx?: SxProps;
  className?: string;
  periodNumber: string;
  onPress?: () => void;
}

export const TaskDuePeriodBox = observer((props: TaskDuePeriodBoxProps) => {
  const { localizationService } = useStudyoServices();
  const { periodNumber, sx = [], className, onPress } = props;
  const theme = useTheme();
  const strings = localizationService.localizedStrings.studyo.contents.taskDueBox;

  const periodNumberLength = periodNumber.length;
  const isLargePeriodNumer = periodNumberLength === 0 || periodNumberLength > 6;

  const dueTagColor = periodNumber
    ? theme.studyo.contents.taskDueBox.duePeriodBoxTagColor
    : theme.studyo.contents.taskDueBox.duePeriodBoxEmptyTagColor;

  return (
    <CardActionArea
      sx={{
        ...sx,
        borderRadius: 1,
        justifyContent: 'center',
        alignItems: 'center',
        height: TaskDueChildHeight,
        overflow: 'visible',
        display: 'flex',
        flexDirection: 'column'
      }}
      className={className}
      onClick={onPress}
      disabled={onPress == null}
    >
      <Typography
        variant="caption"
        noWrap
        sx={{
          textAlign: 'center'
        }}
      >
        {strings.period}
      </Typography>
      <Box sx={{ display: 'flex', pt: 0.75, alignItems: 'center', justifyContent: 'center' }}>
        <Typography
          noWrap={!isLargePeriodNumer}
          variant={isLargePeriodNumer ? 'subtitle2' : 'h5'}
          sx={{
            color: dueTagColor,
            textAlign: 'center',
            fontWeight: '500'
          }}
        >
          {periodNumber || localizationService.localizedStrings.studyo.contents.taskDueBox.none}
        </Typography>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          flexBasis: 0
        }}
      />
    </CardActionArea>
  );
});
