import { Box, Divider, Stack, SxProps, useMediaQuery, useTheme } from '@mui/material';
import { AgendaTimelineCollectionView, DataPresenter, TimelineHeader } from '@studyo/components';
import { AgendaTimelineViewModel } from '@studyo/viewmodels';
import { observer } from 'mobx-react-lite';
import { TimelineListView } from './TimelineListView';

export interface AgendaTimelineViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: AgendaTimelineViewModel;
}

export const AgendaTimelineView = observer((props: AgendaTimelineViewProps) => {
  const { sx = [], viewModel } = props;
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));

  const goToToday = () => {
    viewModel.goToToday();
  };

  const goToPreviousWeek = () => {
    viewModel.goToPreviousWeek();
  };

  const goToNextWeek = () => {
    viewModel.goToNextWeek();
  };

  return (
    <DataPresenter
      sx={{ ...sx, backgroundColor: theme.studyo.agenda.weekly.backgroundColor }}
      dataLoaders={[viewModel.data, viewModel.preferences]}
      renderData={() => (
        <Stack
          sx={{
            width: '100%',
            height: '100%',
            overflow: 'hidden'
          }}
        >
          <Box>
            <TimelineHeader
              viewModel={viewModel.header}
              onScrollToTodayPressed={goToToday}
              goToPreviousWeek={goToPreviousWeek}
              goToNextWeek={goToNextWeek}
            />
          </Box>

          <Divider />

          <Stack
            direction="row"
            sx={{
              flex: 1,
              overflow: 'hidden'
            }}
          >
            {!isExtraSmallScreen && (
              <>
                <AgendaTimelineCollectionView sx={{ flexGrow: 4, flexBasis: 0 }} viewModel={viewModel.collectionView} />
                <Divider orientation="vertical" />
              </>
            )}

            <TimelineListView
              sx={{ flexGrow: 1, flexBasis: 0, minWidth: !isExtraSmallScreen ? 370 : undefined }}
              viewModel={viewModel.list}
            />
          </Stack>
        </Stack>
      )}
    />
  );
});
