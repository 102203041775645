import { Check } from '@mui/icons-material';
import {
  Box,
  CardActionArea,
  Checkbox,
  ListItem,
  ListItemIcon,
  ListItemText,
  SxProps,
  Tooltip,
  useTheme
} from '@mui/material';
import { SectionColors } from '@shared/models/Colors.ts';
import { AllColors } from '@shared/models/types';
import { CourseSelectionStrings } from '@shared/resources/strings/studyo/agenda/settings/CourseSelectionStrings';
import { CourseSelectionSectionListItemViewModel } from '@studyo/viewmodels';
import { observer } from 'mobx-react-lite';
import { useStudyoServices } from '../../UseStudyoServicesHook';
import { DropDownSelector } from '../utils';

export interface CourseSelectionSectionListItemProps {
  sx?: SxProps;
  className?: string;
  viewModel: CourseSelectionSectionListItemViewModel;
  didChangeSelected: (sectionId: string, isSelected: boolean) => void;
}

export const CourseSelectionSectionListItem = observer((props: CourseSelectionSectionListItemProps) => {
  const { localizationService } = useStudyoServices();
  const { viewModel, className, sx = [], didChangeSelected } = props;
  const theme = useTheme();
  const strings = localizationService.localizedStrings.studyo.agenda.setting.courseSelection;

  let subtitle = `${viewModel.groupNumber}`;

  if (viewModel.teacherName != null) {
    subtitle += `${subtitle.length > 0 ? ' - ' : ''}${viewModel.teacherName}`;
  }

  if (viewModel.isTeacherAsStudent) {
    subtitle =
      subtitle.length > 0 ? `${strings.teacherAsStudentWarning} (${subtitle})` : strings.teacherAsStudentWarning;
  }

  const onSelectionChange = (value: boolean) => {
    didChangeSelected(viewModel.sectionId, value);
  };

  const resolveColorForOption = (option: string) => SectionColors.get(viewModel.colorFromStringValue(option));

  return (
    <ListItem dense sx={sx} className={className}>
      <Tooltip disableInteractive title={tooltipForCourse(viewModel, strings)}>
        <ListItemIcon>
          <Checkbox
            checked={viewModel.isSelected}
            onChange={(e) => onSelectionChange(e.target.checked)}
            disabled={viewModel.isReadonly}
          />
        </ListItemIcon>
      </Tooltip>
      <ListItemText
        primary={viewModel.title}
        secondary={subtitle}
        secondaryTypographyProps={viewModel.isTeacherAsStudent ? { color: 'error' } : undefined}
      />
      <DropDownSelector
        options={AllColors}
        selectedIndex={AllColors.indexOf(viewModel.color)}
        didSelectOption={(index) => {
          if (index == null) {
            return;
          }

          const color = AllColors[index];
          viewModel.didSelectCustomColor(color);
        }}
        renderPreview={(option) => (
          <Box
            sx={{
              width: 20,
              height: 20,
              borderRadius: '50%',
              backgroundColor: resolveColorForOption(option)
            }}
          />
        )}
        renderOptions={(options, onSelectItem) => (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              width: 296,
              p: 1
            }}
          >
            {options.map((option, index) => {
              const bgColor = resolveColorForOption(option)!;
              const fgColor = theme.palette.getContrastText(bgColor);

              return (
                <CardActionArea
                  sx={{
                    width: 32,
                    height: 32,
                    borderRadius: 1,
                    backgroundColor: resolveColorForOption(option),
                    m: 0.5,
                    position: 'relative'
                  }}
                  onClick={() => onSelectItem(index)}
                  key={`course-selection-item-option-${index}`}
                >
                  {option === viewModel.color && (
                    <Check
                      sx={{ width: '60%', height: '60%', bottom: 2, right: 2, position: 'absolute', color: fgColor }}
                    />
                  )}
                </CardActionArea>
              );
            })}
          </Box>
        )}
      />
    </ListItem>
  );
});

function tooltipForCourse(viewModel: CourseSelectionSectionListItemViewModel, strings: CourseSelectionStrings): string {
  if (viewModel.isReadonly && viewModel.isSelected) {
    if (viewModel.isTaughtSection) {
      return strings.taughtCourseSectionTooltip;
    } else if (viewModel.isAutoEnrolled) {
      return strings.autoEnrolledCourseSectionTooltip;
    }
  }

  return '';
}
