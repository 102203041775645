import { Stack, SxProps, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';
import ListValues from '../ListValues';
import { ListSectionHeaderIcon } from './ListSectionHeaderIcon';

export function ListSectionHeaderHeight(title: string | undefined) {
  return title != null ? ListValues.sectionHeaderHeight : ListValues.sectionHeaderHeightNoTitle;
}

export interface ListSectionHeaderProps {
  sx?: SxProps;
  className?: string;
  title?: string;
  titleColor?: string;
  color?: string;
  icon?: ReactNode;
  isIconColorInherited?: boolean;
  onIconPressed?: () => void;
  iconIsDisabled?: boolean;
}

export const ListSectionHeader = observer((props: ListSectionHeaderProps) => {
  const {
    title,
    color,
    sx = [],
    className,
    titleColor,
    icon,
    isIconColorInherited,
    iconIsDisabled,
    onIconPressed
  } = props;
  const headerHeight = ListSectionHeaderHeight(title);

  return (
    <Stack
      direction="row"
      className={className}
      sx={{
        mt: 1,
        pl: 2,
        alignItems: 'center',
        ...sx,
        height: headerHeight,
        backgroundColor: color,
        color: titleColor
      }}
    >
      {title != null && (
        <Typography
          variant="subtitle2"
          sx={{
            flex: 1
          }}
        >
          {title}
        </Typography>
      )}
      {icon != null && (
        <ListSectionHeaderIcon
          sx={{ mx: 1 }}
          icon={icon}
          onIconPressed={onIconPressed}
          disabled={iconIsDisabled}
          inheritColor={isIconColorInherited}
        />
      )}
    </Stack>
  );
});
