import {
  ContentDefinition_Attachment as PBAttachment,
  ContentDefinition as PBContentDefinition,
  ContentDefinition_ContentKind as PBContentKind,
  ContentDefinition_PublishTarget as PBPublishTarget,
  ContentDefinition_Step as PBStep,
  ContentDefinition_WorkloadLevel as PBWorkloadLevel
} from '@buf/studyo_studyo.bufbuild_es/studyo/type_contents_pb';
import _ from 'lodash';
import { action, computed, makeObservable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import {
  EditableChildNullableProperty,
  EditableDayProperty,
  EditableListProperty,
  EditableModel,
  EditableNullableTimeProperty,
  EditableValueProperty
} from '../EditableModel';
import { ContentIcon, ContentState, ContentWorkloadLevel, Day, Time } from '../types';
import {
  protobufFromContentIcon,
  protobufFromContentState,
  protobufFromContentWorkloadLevel
} from '../types/EnumConversion';
import { ContentDefinition, ContentDefinitionModel } from './ContentDefinition';
import { EditableContentAttachment } from './EditableContentAttachment';
import { EditableContentPublishTarget } from './EditableContentPublishTarget';
import { EditableContentStep } from './EditableContentStep';

export class EditableContentDefinition extends EditableModel<PBContentDefinition> implements ContentDefinitionModel {
  private _sectionId: EditableValueProperty<string, PBContentDefinition>;
  private _title: EditableValueProperty<string, PBContentDefinition>;
  private _notes: EditableValueProperty<string, PBContentDefinition>;
  private _icon: EditableValueProperty<ContentIcon, PBContentDefinition>;
  private _isPrivate: EditableValueProperty<boolean, PBContentDefinition>;
  private _workloadLevel: EditableValueProperty<ContentWorkloadLevel, PBContentDefinition>;
  private _assignmentDay: EditableDayProperty<PBContentDefinition>;
  private _plannedDay: EditableDayProperty<PBContentDefinition>;
  private _dueDay: EditableDayProperty<PBContentDefinition>;
  private _duePeriodTag: EditableValueProperty<string, PBContentDefinition>;
  private _contentsGroupIdentifier: EditableValueProperty<string, PBContentDefinition>;
  private _isUnread: EditableValueProperty<boolean, PBContentDefinition>;
  private _reminderDays: EditableValueProperty<number, PBContentDefinition>;
  private _reminderTime: EditableNullableTimeProperty<PBContentDefinition>;
  private _publishTarget: EditableChildNullableProperty<
    PBPublishTarget,
    EditableContentPublishTarget,
    PBContentDefinition
  >;
  private _attachments: EditableListProperty<PBAttachment, EditableContentAttachment, PBContentDefinition>;
  private _state: EditableValueProperty<ContentState, PBContentDefinition>;
  private readonly _steps: EditableListProperty<PBStep, EditableContentStep, PBContentDefinition>;

  static createNewTask(
    configId: string,
    accountId: string,
    day: Day,
    announcementDay: Day,
    periodTag: string | undefined,
    sectionId: string | undefined
  ): EditableContentDefinition {
    const cd = new PBContentDefinition();
    cd.syncLocalId = uuidv4();

    cd.workload = PBWorkloadLevel.REGULAR;
    cd.dueDay = day.asPB;

    const assignmentDay = announcementDay;
    cd.assignmentDay = assignmentDay.asPB;
    cd.plannedDay = assignmentDay.asPB;

    cd.configId = configId;
    cd.ownerId = accountId;
    cd.reminderDays = -1;
    cd.reminderTime = Time.create({ hour: 16, minute: 30 }).asPB;

    cd.isPrivate = false;

    if (periodTag != null) {
      cd.duePeriodTag = periodTag;
    }
    if (sectionId != null) {
      cd.sectionId = sectionId;
    }

    return new EditableContentDefinition(new ContentDefinition(cd), true);
  }

  static createNewNote(configId: string, accountId: string, day: Day, periodTag: string): EditableContentDefinition {
    const cd = new PBContentDefinition();
    cd.syncLocalId = uuidv4();

    cd.workload = PBWorkloadLevel.REGULAR;
    cd.dueDay = day.asPB;
    cd.assignmentDay = day.asPB;
    cd.plannedDay = day.asPB;
    cd.duePeriodTag = periodTag;
    cd.kind = PBContentKind.NOTE;

    cd.configId = configId;
    cd.ownerId = accountId;
    cd.reminderDays = -1;
    cd.reminderTime = Time.create({ hour: 16, minute: 30 }).asPB;

    cd.isPrivate = false;

    return new EditableContentDefinition(new ContentDefinition(cd), true);
  }

  static applyModificationToOtherTask(target: EditableContentDefinition, original: EditableContentDefinition) {
    target.title = original.title;
    target.notes = original.notes;
    target.isPrivate = original.isPrivate;
    target.workloadLevel = original.workloadLevel;
    target.icon = original.icon;

    // Removing all attachments as we don't have a way to know which are the changes between the two contents.
    for (let i = target.attachments.length - 1; i >= 0; i--) {
      target.deleteAttachment(target.attachments[i]);
    }

    original.attachments.forEach((originalAttachment) => {
      if (!originalAttachment.shouldBeDeleted) {
        const targetAttachment = EditableContentAttachment.createNew();
        targetAttachment.externalUrl = originalAttachment.externalUrl;
        targetAttachment.fileName = originalAttachment.fileName;
        targetAttachment.fileUrl = originalAttachment.fileUrl;
        targetAttachment.kind = originalAttachment.kind;
        targetAttachment.thumbUrl = originalAttachment.thumbUrl;
        targetAttachment.title = originalAttachment.title;
        target.addAttachment(targetAttachment);
      }
    });

    const stepIds: string[] = [];
    original.steps.forEach((originalStep) => {
      if (!originalStep.shouldBeDeleted) {
        stepIds.push(originalStep.stepId);

        let targetStep = target.steps.find((s) => s.stepId == originalStep.stepId);
        if (targetStep == null) {
          targetStep = EditableContentStep.createNew(target, originalStep.stepId);
          targetStep.stepId = originalStep.stepId;
          target.addStep(targetStep);
        }

        targetStep.title = originalStep.title;
        targetStep.sortOrder = originalStep.sortOrder;
      }
    });

    // Remove steps which were deleted in the original content.
    for (let i = target.steps.length - 1; i >= 0; i--) {
      const step = target.steps[i];

      if (!stepIds.includes(step.stepId)) {
        target.deleteStep(step);
      }
    }
  }

  constructor(
    private readonly _originalContent: ContentDefinitionModel,
    public readonly isNew = false
  ) {
    super(_originalContent.toProtobuf(), isNew);

    makeObservable(this);

    this.setFields([
      (this._sectionId = new EditableValueProperty(_originalContent.sectionId, (pb, value) => (pb.sectionId = value))),
      (this._title = new EditableValueProperty(_originalContent.title, (pb, value) => (pb.title = value))),
      (this._notes = new EditableValueProperty(_originalContent.notes, (pb, value) => (pb.notes = value))),
      (this._icon = new EditableValueProperty(
        _originalContent.icon,
        (pb, value) => (pb.icon = protobufFromContentIcon(value))
      )),
      (this._workloadLevel = new EditableValueProperty(
        _originalContent.workloadLevel,
        (pb, value) => (pb.workload = protobufFromContentWorkloadLevel(value))
      )),
      (this._isPrivate = new EditableValueProperty(_originalContent.isPrivate, (pb, value) => (pb.isPrivate = value))),
      (this._assignmentDay = new EditableDayProperty(
        _originalContent.assignmentDay,
        (pb, value) => (pb.assignmentDay = value.asPB)
      )),
      (this._plannedDay = new EditableDayProperty(
        _originalContent.plannedDay,
        (pb, value) => (pb.plannedDay = value.asPB)
      )),
      (this._dueDay = new EditableDayProperty(_originalContent.dueDay, (pb, value) => (pb.dueDay = value.asPB))),
      (this._duePeriodTag = new EditableValueProperty(
        _originalContent.duePeriodTag,
        (pb, value) => (pb.duePeriodTag = value)
      )),
      (this._contentsGroupIdentifier = new EditableValueProperty(
        _originalContent.contentsGroupIdentifier,
        (pb, value) => (pb.contentsGroupIdentifier = value)
      )),
      (this._isUnread = new EditableValueProperty(_originalContent.isUnread, (pb, value) => (pb.isUnread = value))),
      (this._reminderDays = new EditableValueProperty(
        _originalContent.reminderDays,
        (pb, value) => (pb.reminderDays = value)
      )),
      (this._reminderTime = new EditableNullableTimeProperty(
        _originalContent.reminderTime,
        (pb, value) => (pb.reminderTime = value?.asPB)
      )),
      (this._state = new EditableValueProperty(
        _originalContent.state,
        (pb, value) => (pb.state = protobufFromContentState(value))
      )),
      (this._publishTarget = new EditableChildNullableProperty(
        _originalContent.publishTarget && new EditableContentPublishTarget(_originalContent.publishTarget),
        (pb, value) => (pb.publishTarget = value),
        () => false
      )),
      (this._attachments = new EditableListProperty(
        _originalContent.attachments.map((a) => new EditableContentAttachment(a)),
        (pb, values) => (pb.attachments = values)
      )),
      (this._steps = new EditableListProperty(
        _originalContent.steps.map((s) => new EditableContentStep(s)),
        (pb, values) => (pb.steps = values)
      ))
    ]);
  }

  //
  // Read-only properties
  //

  get id() {
    return this._originalContent.id;
  }

  get syncToken() {
    return this._originalContent.syncToken;
  }

  get editableSteps() {
    return this._steps;
  }

  // Not related to "shouldBeDeleted".
  get isDeleted() {
    return this._originalContent.isDeleted;
  }

  get syncLocalId() {
    return this._originalContent.syncLocalId;
  }

  get configId() {
    return this._originalContent.configId;
  }

  get ownerId() {
    return this._originalContent.ownerId;
  }

  get kind() {
    return this._originalContent.kind;
  }

  get masterContent() {
    return this._originalContent.masterContent;
  }

  get externalContent() {
    return this._originalContent.externalContent;
  }

  get isMasterPublishedToAccounts(): boolean {
    return (
      this.publishTarget != null &&
      this.publishTarget.kind === 'accounts' &&
      this.publishTarget.targetAccountIds.length > 0
    );
  }

  get isMasterPublishedToSection(): boolean {
    return this.publishTarget != null && this.publishTarget.kind === 'section';
  }

  get isMaster(): boolean {
    return this.isMasterPublishedToAccounts || this.isMasterPublishedToSection;
  }

  get isSlave(): boolean {
    return this._originalContent.masterContent != null;
  }

  get isPublished(): boolean {
    return this.isMaster || this.isSlave;
  }

  //
  // Editable properties
  //

  @computed
  get sectionId() {
    return this._sectionId.value;
  }

  set sectionId(value: string) {
    this._sectionId.value = value;
  }

  @computed
  get title() {
    return this._title.value;
  }

  set title(value: string) {
    this._title.value = value;
  }

  @computed
  get notes() {
    return this._notes.value;
  }

  set notes(value: string) {
    this._notes.value = value;
  }

  @computed
  get icon() {
    return this._icon.value;
  }

  set icon(value: ContentIcon) {
    this._icon.value = value;
  }

  @computed
  get isPrivate() {
    return this._isPrivate.value;
  }

  set isPrivate(value: boolean) {
    this._isPrivate.value = value;
  }

  @computed
  get workloadLevel() {
    return this._workloadLevel.value;
  }

  set workloadLevel(value: ContentWorkloadLevel) {
    this._workloadLevel.value = value;
  }

  @computed
  get assignmentDay() {
    return this._assignmentDay.value;
  }

  set assignmentDay(value: Day) {
    this._assignmentDay.value = value;
  }

  @computed
  get plannedDay() {
    return this._plannedDay.value;
  }

  set plannedDay(value: Day) {
    this._plannedDay.value = value;
  }

  @computed
  get dueDay() {
    return this._dueDay.value;
  }

  set dueDay(value: Day) {
    this._dueDay.value = value;
  }

  @computed
  get duePeriodTag() {
    return this._duePeriodTag.value;
  }

  set duePeriodTag(value: string) {
    this._duePeriodTag.value = value;
  }

  @computed
  get contentsGroupIdentifier() {
    return this._contentsGroupIdentifier.value;
  }

  set contentsGroupIdentifier(value: string) {
    this._contentsGroupIdentifier.value = value;
  }

  @computed
  get isUnread() {
    return this._isUnread.value;
  }

  set isUnread(value: boolean) {
    this._isUnread.value = value;
  }

  @computed
  get reminderDays() {
    return this._reminderDays.value;
  }

  set reminderDays(value: number) {
    this._reminderDays.value = value;
  }

  @computed
  get reminderTime() {
    return this._reminderTime.value;
  }

  set reminderTime(value: Time | undefined) {
    this._reminderTime.value = value;
  }

  @computed
  get state() {
    return this._state.value;
  }

  set state(value: ContentState) {
    this._state.value = value;
  }

  @computed
  get publishTarget() {
    return this._publishTarget.value;
  }

  set publishTarget(value: EditableContentPublishTarget | undefined) {
    this._publishTarget.value = value;
  }

  @action
  toggleState() {
    this._state.value = this.state === 'active' ? 'completed' : 'active';
  }

  @action
  toggleDeleteState() {
    this._state.value = this.state === 'cancelled' ? 'active' : 'cancelled';
  }

  @computed
  get attachments() {
    return this._attachments.values;
  }

  @action
  addAttachment(attachment: EditableContentAttachment) {
    this._attachments.addItem(attachment);
  }

  @action
  deleteAttachment(attachment: EditableContentAttachment) {
    attachment.markAsDeleted();
  }

  @computed
  get steps() {
    // When editing content steps, we don't display steps to be deleted in red.
    return this._steps.values.filter((step) => !step.shouldBeDeleted);
  }

  // These are not in "ContentDefinitionUtils" so ContentDefinition can cache once. We mimic the behavior.
  @computed
  get sortedSteps() {
    return _.sortBy(this.steps, ['sortOrder']);
  }

  @computed
  get notCompletedSteps() {
    return _.filter(this.sortedSteps, (s) => !s.completed);
  }

  @computed
  get hasChangedLinkedTasksProperties() {
    let hasChangeProperties = false;

    if (this._title.isChanged) {
      hasChangeProperties = true;
    }
    if (this._notes.isChanged) {
      hasChangeProperties = true;
    }
    if (this._workloadLevel.isChanged) {
      hasChangeProperties = true;
    }
    if (this._icon.isChanged) {
      hasChangeProperties = true;
    }
    if (this._attachments.isChanged) {
      hasChangeProperties = true;
    }
    if (this._steps.isChanged) {
      hasChangeProperties = true;
    }

    return hasChangeProperties;
  }

  @action
  addStep(step: EditableContentStep) {
    this._steps.addItem(step);
  }

  @action
  deleteStep(step: EditableContentStep) {
    step.markAsDeleted();
  }

  clone() {
    const pb = this.toProtobuf();
    return new EditableContentDefinition(new ContentDefinition(pb));
  }
}
