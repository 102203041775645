import { Box, SxProps } from '@mui/material';
import { DialogResult } from '@shared/services';
import { DialogActionButtons, DialogButton, ResponsiveDialog } from '@studyo/components';
import { OptionMenuView } from '@studyo/views';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { DialogHeader } from '../../../components';

export interface TimelineFiltersOptionsScreenProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
}

export const TimelineFiltersOptionsScreen = observer((props: TimelineFiltersOptionsScreenProps) => {
  const { localizationService, viewModelFactory } = useStudyoServices();
  const { sx = [], className, onCancel, onSuccess } = props;
  const viewModel = useMemo(
    () => viewModelFactory.createTimelineFiltersOptions(onSuccess!, onCancel!),
    [onSuccess, onCancel]
  );

  const strings = localizationService.localizedStrings.studyo.agenda.timeline;

  const dismissModal = () => {
    viewModel.dismiss();
  };

  const resetFilters = () => {
    viewModel.resetFilters();
  };

  return (
    <ResponsiveDialog
      sx={sx}
      className={className}
      maxWidth="xs"
      fullWidth={true}
      open={true}
      maxHeight="large"
      onClose={dismissModal}
    >
      <DialogHeader title={strings.filterOptions.title} onClose={dismissModal} />
      <OptionMenuView sx={{ flex: 1 }} viewModel={viewModel} />
      <DialogActionButtons>
        <DialogButton
          title={strings.filterOptions.reset}
          type="destructive"
          disabled={!viewModel.isFiltered}
          onPress={resetFilters}
        />

        <Box
          sx={{
            flex: 1
          }}
        />

        <DialogButton title={strings.filterOptions.close} type="normal" onPress={dismissModal} minimumScreenSize="sm" />
      </DialogActionButtons>
    </ResponsiveDialog>
  );
});
