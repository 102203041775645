import { SxProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useStudyoServices } from '../../UseStudyoServicesHook';
import { DataPresenter } from '../../components';
import { WeeklyViewModel } from '../../viewmodels';
import { WeeklyView } from './WeeklyView';

export interface AgendaWeeklyViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: WeeklyViewModel;
}

export const AgendaWeeklyView = observer((props: AgendaWeeklyViewProps) => {
  const { localizationService } = useStudyoServices();
  const { sx = [], className, viewModel } = props;

  return (
    <DataPresenter
      sx={sx}
      className={className}
      dataLoaders={[viewModel.data]}
      defaultErrorMessage={localizationService.localizedStrings.studyo.agenda.errorMessage}
      renderData={() => <WeeklyView sx={{ width: '100%', height: '100%' }} viewModel={viewModel} />}
    />
  );
});
