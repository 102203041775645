import { css } from '@emotion/css';
import { alpha, Box, SxProps, useTheme } from '@mui/material';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

export interface OnboardingContentBackgroundViewProps {
  sx?: SxProps;
  className?: string;
}

export const OnboardingContentBackgroundView = observer(
  ({ sx = [], className }: OnboardingContentBackgroundViewProps) => {
    const theme = useTheme();

    const containerBackgroundClassName = css([
      {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        backdropFilter: 'blur(12px)',
        borderRadius: 10,
        backgroundColor: alpha(theme.studyo.onboarding.login.containerBackgroundColor, 0.85)
      }
    ]);

    return <Box sx={sx} className={clsx(className, containerBackgroundClassName)} />;
  }
);
