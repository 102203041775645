import { Check } from '@mui/icons-material';
import { Box, SxProps, useTheme } from '@mui/material';
import { SectionColors } from '@shared/models/Colors';
import { ContentDefinitionModel } from '@shared/models/content';
import { DialogResult } from '@shared/services';
import {
  ConfirmDialog,
  DialogActionButtons,
  DialogButton,
  ResponsiveDialog,
  SaveDialog,
  useSaveDialog
} from '@studyo/components';
import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { DialogHeader } from '../../../components';
import { ContentPublishStudentSelectionView } from '../../../views';

export interface ContentPublishStudentSelectionScreenProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  content: string | (() => ContentDefinitionModel);
}

export const ContentPublishStudentSelectionScreen = observer((props: ContentPublishStudentSelectionScreenProps) => {
  const { localizationService, viewModelFactory } = useStudyoServices();
  const { sx = [], className, content, onCancel, onSuccess } = props;
  const theme = useTheme();

  const viewModel = useMemo(
    () => viewModelFactory.createContentPublishStudentSelection(content, onSuccess!, onCancel!),
    [content]
  );

  const [displayCancelChangesDialog, setDisplayCancelChangesDialog] = useState(false);
  const [displayLongTimeSpanDialog, setDisplayLongTimeSpanDialog] = useState(false);
  const [publishPromise, startPublish, resetPublish, retryPublish] = useSaveDialog(() => viewModel.publish());

  const strings = localizationService.localizedStrings.studyo.contents.contentPublish;

  const cancelButtonPressed = () => {
    if (viewModel.hasChanges) {
      if (viewModel.isPublishing) {
        showCancelConfirmationDialog();
      } else {
        viewModel.cancel();
      }
    } else {
      viewModel.cancel();
    }
  };

  const publishButtonPressed = () => {
    if (viewModel.isPublishing) {
      if (viewModel.contentHasLongTimeSpan) {
        showLongTimeSpanDialog();
      } else {
        startPublish();
      }
    } else {
      viewModel.applyChanges();
      viewModel.dismiss();
    }
  };

  const cancel = () => {
    hideCancelConfirmationDialog();
    viewModel.cancel();
  };

  const showCancelConfirmationDialog = () => {
    setDisplayCancelChangesDialog(true);
  };

  const hideCancelConfirmationDialog = () => {
    setDisplayCancelChangesDialog(false);
  };

  const longTimeSpanConfirm = () => {
    hideLongTimeSpanDialog();
    startPublish();
  };

  const hideLongTimeSpanDialog = () => {
    setDisplayLongTimeSpanDialog(false);
  };

  const showLongTimeSpanDialog = () => {
    setDisplayLongTimeSpanDialog(true);
  };

  const saveDialogClose = (success: boolean) => {
    resetPublish();
    if (success) {
      viewModel.dismiss();
    }
  };

  return (
    <ResponsiveDialog
      sx={sx}
      className={className}
      maxWidth="sm"
      fullWidth={true}
      open={true}
      maxHeight="large"
      onClose={cancelButtonPressed}
    >
      <DialogHeader
        onClose={cancelButtonPressed}
        title={viewModel.sectionTitle ?? strings.noSection}
        subtitle={viewModel.date}
        color={
          viewModel.sectionColor != null
            ? SectionColors.get(viewModel.sectionColor)
            : theme.studyo.periods.freePeriodColor
        }
        tintColor={
          viewModel.sectionColor != null
            ? theme.studyo.periods.periodWithSectionTextColor
            : theme.studyo.periods.freePeriodTextColor
        }
        rightButton={{
          title: viewModel.isPublishing ? strings.publish : undefined,
          icon: !viewModel.isPublishing ? (size) => <Check fontSize={size} color="inherit" /> : undefined,
          color: 'inherit',
          action: () => publishButtonPressed(),
          onlyShowOnExtraSmallScreen: true
        }}
      />
      <ContentPublishStudentSelectionView sx={{ flex: 1 }} viewModel={viewModel} />
      <DialogActionButtons>
        {viewModel.publishKind === 'some' && (
          <DialogButton title={strings.selectAllStudents} type="normal" onPress={() => viewModel.selectAllStudents()} />
        )}

        <Box
          sx={{
            flex: 1
          }}
        />

        {viewModel.isPublishing && (
          <DialogButton title={strings.cancel} type="cancel" onPress={cancelButtonPressed} minimumScreenSize="sm" />
        )}

        <DialogButton
          title={viewModel.isPublishing ? strings.publish : strings.close}
          variant={viewModel.isPublishing ? 'contained' : 'text'}
          type="normal"
          minimumScreenSize={viewModel.isPublishing ? 'sm' : undefined}
          onPress={publishButtonPressed}
        />
      </DialogActionButtons>
      <ConfirmDialog
        open={displayCancelChangesDialog}
        title={strings.unsavedChangesTitle}
        description={strings.unsavedChangesDescription}
        cancelTitle={strings.unsavedChangesStayTitle}
        onCancelPress={hideCancelConfirmationDialog}
        confirmTitle={strings.unsavedChangesDiscardTitle}
        onConfirmPress={cancel}
        onClose={hideCancelConfirmationDialog}
      />
      <ConfirmDialog
        open={displayLongTimeSpanDialog}
        title={strings.longTimeSpanAlertTitle}
        description={strings.longTimeSpanAlertDescription}
        cancelTitle={localizationService.localizedStrings.studyo.utils.alertDialogCancel}
        onCancelPress={hideLongTimeSpanDialog}
        confirmTitle={strings.publish}
        onConfirmPress={longTimeSpanConfirm}
        onClose={hideLongTimeSpanDialog}
      />
      <SaveDialog
        promise={publishPromise}
        retryCall={retryPublish}
        onClose={saveDialogClose}
        titles={{
          saving: strings.savingTitle,
          saved: strings.savedTitle,
          error: strings.saveErrorTitle
        }}
        descriptions={{
          saving: strings.savingDescription,
          saved: strings.savedDescription,
          error: strings.saveErrorDescription,
          permanentError: strings.savePermanentErrorDescription
        }}
      />
    </ResponsiveDialog>
  );
});
