import { AddCircle, AddCircleOutline, Pending, PendingOutlined } from '@mui/icons-material';
import { Box, SxProps, useTheme } from '@mui/material';
import { withoutPropagation } from '@shared/components/utils';
import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';

export type DisplayableContentBoxActionButtonKind = 'add' | 'more' | 'more-overflow';
const DisplayableContentBoxActionButtonSize = 20;

export interface DisplayableContentBoxActionButtonProps {
  sx?: SxProps;
  className?: string;
  kind: DisplayableContentBoxActionButtonKind;
  onPress: () => void;
  backgroundColor?: string;
  invertActionButtonColors?: boolean;
}

export const DisplayableContentBoxActionButton = observer((props: DisplayableContentBoxActionButtonProps) => {
  const { sx = [], className, backgroundColor, invertActionButtonColors, onPress, kind } = props;
  const theme = useTheme();
  const [isHovered, setIsHovered] = useState(false);

  const resolvedBackgroundColor = backgroundColor ?? theme.studyo.contents.displayableContentBoxBackgroundColor;

  const onHoverStart = () => {
    setIsHovered(true);
  };

  const onHoverEnd = () => {
    setIsHovered(false);
  };

  const hoveredBackgroundColor = useMemo(() => {
    switch (kind) {
      case 'add':
        return theme.studyo.contents.displayableContentPlusBackgroundColor;
      case 'more':
        return theme.studyo.contents.displayableContentMoreBackgroundColor;
      case 'more-overflow':
        return theme.studyo.contents.displayableContentMoreWithOverflowBackgroundColor;
    }
  }, [theme, kind]);

  const icon = () => {
    const iconStyle: SxProps = {
      color: hoveredBackgroundColor,
      width: DisplayableContentBoxActionButtonSize,
      height: DisplayableContentBoxActionButtonSize,
      margin: '1px',
      zIndex: 2
    };

    switch (kind) {
      case 'add':
        return isHovered ? <AddCircle sx={iconStyle} /> : <AddCircleOutline sx={iconStyle} />;
      case 'more':
        return isHovered ? <Pending sx={iconStyle} /> : <PendingOutlined sx={iconStyle} />;
      case 'more-overflow':
        return isHovered ? <PendingOutlined sx={iconStyle} /> : <Pending sx={iconStyle} />;
    }
  };

  return (
    <Box
      className={className}
      onClick={withoutPropagation(() => onPress())}
      onMouseEnter={onHoverStart}
      onMouseLeave={onHoverEnd}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...sx,
        cursor: 'pointer',
        position: 'relative',
        width: DisplayableContentBoxActionButtonSize + 2,
        height: DisplayableContentBoxActionButtonSize + 2
      }}
    >
      <Box
        sx={{
          backgroundColor: invertActionButtonColors ? hoveredBackgroundColor : resolvedBackgroundColor,
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          borderRadius: (DisplayableContentBoxActionButtonSize + 2) / 2,
          zIndex: 1
        }}
      />
      {icon()}
    </Box>
  );
});
