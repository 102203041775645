import { Stack, SxProps, Typography } from '@mui/material';
import { isSxArray } from '@shared/components/utils';
import { observer } from 'mobx-react-lite';
import { PeriodsHeaderViewModel } from '../../../viewmodels';
import { CalendarHeader } from '../header';

export interface PeriodsHeaderProps {
  sx?: SxProps;
  className?: string;
  viewModel: PeriodsHeaderViewModel;
}

export const PeriodsHeader = observer(({ sx = [], viewModel, className }: PeriodsHeaderProps) => {
  const onSectionFiltersPress = () => {
    void viewModel.displayFilterSections();
  };

  const onContentFiltersPress = () => {
    void viewModel.displayFilterContents();
  };

  return (
    <CalendarHeader
      sx={sx}
      className={className}
      viewModel={viewModel}
      dateSwitcherContext="periods"
      actionButtons={[{ kind: 'options', action: () => void viewModel.displayOptions() }]}
      hasContentFilters={viewModel.hasContentFiltersSet}
      onContentFiltersPress={onContentFiltersPress}
      hasSectionFilters={viewModel.hasSectionFiltersSet}
      onSectionFiltersPress={onSectionFiltersPress}
      renderDate={(sx) => (
        <Stack
          direction="row"
          sx={[
            {
              alignItems: 'center'
            },
            ...(isSxArray(sx) ? sx : [sx])
          ]}
        >
          <Typography sx={{ userSelect: 'none' }} variant="h6">
            {viewModel.periodsDateTitle}
          </Typography>
        </Stack>
      )}
    />
  );
});
