import { Box, Divider, Stack, SxProps } from '@mui/material';
import { ObserverAutoSizer } from '@shared/components/layout';
import { AgendaPlannerGrid, DataPresenter, PlannerConstants, PlannerHeader } from '@studyo/components';
import { PlannerViewModel } from '@studyo/viewmodels';
import { observer } from 'mobx-react-lite';
import { useStudyoServices } from '../../UseStudyoServicesHook';

export interface AgendaPlannerViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: PlannerViewModel;
}

export const AgendaPlannerView = observer((props: AgendaPlannerViewProps) => {
  const { localizationService } = useStudyoServices();
  const { viewModel, sx = [], className } = props;

  const goToNextWeek = () => {
    viewModel.goToNextWeek();
  };

  const goToPreviousWeek = () => {
    viewModel.goToPreviousWeek();
  };

  return (
    <DataPresenter
      sx={sx}
      className={className}
      dataLoaders={[viewModel.data, viewModel.preferences]}
      defaultErrorMessage={localizationService.localizedStrings.studyo.agenda.errorMessage}
      renderData={() => (
        <Stack sx={{ width: '100%', height: '100%', overflow: 'hidden' }}>
          <Box>
            <PlannerHeader
              viewModel={viewModel.header}
              goToNextPage={goToNextWeek}
              goToPreviousPage={goToPreviousWeek}
            />
          </Box>

          <Divider />

          <Box
            sx={{
              flex: 1,
              display: 'flex',
              overflow: 'hidden'
            }}
          >
            <ObserverAutoSizer>
              {({ width, height }) => {
                viewModel.numberOfDaysPerPage = Math.floor(width / PlannerConstants.columnWidth);
                return <AgendaPlannerGrid viewModel={viewModel.grid} height={height} width={width} />;
              }}
            </ObserverAutoSizer>
          </Box>
        </Stack>
      )}
    />
  );
});
