import LinkIcon from '@mui/icons-material/Link';
import { Box, CardActionArea, CircularProgress, Stack, SxProps, Typography, useTheme } from '@mui/material';
import { ContentAttachmentModel } from '@shared/models/content';
import { ImageService } from '@shared/resources/services';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';

const boxSideSize = 78;
const borderWidth = 1;

export interface AttachmentsListItemBoxProps {
  sx?: SxProps;
  className?: string;
  index: number;
  attachment: ContentAttachmentModel;
  didClickOnAttachmentWithIndex: (index: number) => void;
  isLoading: boolean;
}

export type ThumbnailState = 'fetching' | 'value' | 'error';

export const AttachmentsListItemBox = observer((props: AttachmentsListItemBoxProps) => {
  const { imageService } = useStudyoServices();
  const { attachment, sx = [], didClickOnAttachmentWithIndex, index, className, isLoading } = props;

  const loadThumbnailImage = () => {
    if (shouldShowThumbnail) {
      try {
        setThumbnailUrl(attachment.thumbUrl);
        setThumbnailState('value');
      } catch {
        setThumbnailState('error');
      }
    }
  };

  const [thumbnailState, setThumbnailState] = useState<ThumbnailState>('fetching');
  const [thumbnailUrl, setThumbnailUrl] = useState('');

  useEffect(() => {
    loadThumbnailImage();
  }, []);

  const shouldShowThumbnail = useMemo(() => {
    return ['photo', 'url'].find((k) => k === attachment.kind) != null && attachment.thumbUrl != '';
  }, [attachment]);

  const imageSource = useMemo(() => {
    let _source: string | undefined;

    if (shouldShowThumbnail && thumbnailState === 'value') {
      _source = thumbnailUrl;
    } else {
      _source = imageForAttachment(attachment, imageService);
    }

    return _source;
  }, [shouldShowThumbnail, thumbnailUrl, thumbnailState, attachment]);

  const showActivityIndicator = useMemo(() => {
    let show = false;

    if (isLoading) {
      show = true;
    }

    if (shouldShowThumbnail) {
      show = thumbnailState === 'fetching';
    }

    return show;
  }, [isLoading, thumbnailState, shouldShowThumbnail]);

  const theme = useTheme();
  const isUrl = attachment.kind === 'url' || attachment.kind === 'document-url';
  const isImage = attachment.kind === 'photo';
  const isDocument = attachment.kind === 'document';

  const borderColor = theme.studyo.contents.attachments.boxBorderColor;

  return (
    <CardActionArea
      sx={{
        ...sx,
        borderColor,
        borderWidth,
        borderStyle: 'solid',
        display: 'flex',
        flexDirection: 'column',
        height: boxSideSize,
        width: boxSideSize,
        borderRadius: 1.5,
        position: 'relative',
        overflow: 'hidden'
      }}
      className={className}
      onClick={() => didClickOnAttachmentWithIndex(index)}
    >
      <Stack
        sx={{
          p: '2px',
          overflow: 'hidden',
          aspectRatio: 1
        }}
      >
        {isImage && (
          <Box
            sx={{
              flex: 1,
              overflow: 'hidden',
              borderRadius: 1
            }}
          >
            <img src={imageSource} style={{ width: '100%', height: '100%', objectFit: 'cover' }} alt="Attachment" />
          </Box>
        )}
        {(isUrl || isDocument) && (
          <Stack
            spacing={1}
            sx={{
              flex: 1,
              overflow: 'hidden'
            }}
          >
            <img
              src={imageSource}
              style={{ height: '55%', width: '55%', alignSelf: 'center', objectFit: 'contain' }}
              alt={undefined}
            />

            <Typography
              variant="caption"
              noWrap
              sx={{
                color: (theme) => theme.palette.text.secondary,
                textAlign: 'center'
              }}
            >
              {attachment.title}
            </Typography>
          </Stack>
        )}
      </Stack>
      {isUrl && (
        <Box
          sx={{
            p: '2px',
            position: 'absolute',
            top: 0,
            right: 0,
            borderBottomLeftRadius: theme.shape.borderRadius,
            borderWidth: 0,
            borderLeftWidth: 1,
            borderBottomWidth: 1,
            borderStyle: 'solid',
            borderColor,
            aspectRatio: 1
          }}
        >
          <Box sx={{ width: 14, height: 14 }}>
            <LinkIcon sx={{ width: 14, height: 14 }} />
          </Box>
        </Box>
      )}
      {showActivityIndicator && (
        <Box
          sx={{
            height: '100%',
            width: '100%',
            position: 'absolute',
            borderWidth: borderWidth,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <CircularProgress sx={{ position: 'absolute' }} size="2em" />
        </Box>
      )}
    </CardActionArea>
  );
});

function imageForAttachment(attachment: ContentAttachmentModel, imageService: ImageService) {
  const images = imageService.studyoImages.tasks.documentTypes;

  switch (attachment.attachmentIcon) {
    case 'document':
      return images.document;
    case 'excel':
      return images.excel;
    case 'image':
      return images.image;
    case 'keynote':
      return images.keynote;
    case 'link':
      return imageService.studyoImages.tasks.addAttachments.baseLink;
    case 'music':
      return images.music;
    case 'numbers':
      return images.numbers;
    case 'pages':
      return images.pages;
    case 'pdf':
      return images.pdf;
    case 'photo-thumb':
      return images.image;
    case 'powerpoint':
      return images.powerpoint;
    case 'presentation':
      return images.presentation;
    case 'spreadsheet':
      return images.spreadsheet;
    case 'text':
      return images.text;
    case 'video':
      return images.video;
    case 'word':
      return images.word;
    case 'zip':
      return images.zip;
    default:
      break;
  }
}
