import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Divider,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Stack,
  SxProps,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { SectionColors } from '@shared/models/Colors';
import { AvatarView, DataPresenter, PlannerSectionInfoEmailButton } from '@studyo/components';
import { PlannerSectionInfoViewModel } from '@studyo/viewmodels';
import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';
import { useStudyoServices } from '../../UseStudyoServicesHook';

export interface PlannerSectionInfoViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: PlannerSectionInfoViewModel;
}

export const PlannerSectionInfoView = observer((props: PlannerSectionInfoViewProps) => {
  const { localizationService } = useStudyoServices();
  const { sx = [], className, viewModel } = props;
  const theme = useTheme();
  const strings = localizationService.localizedStrings.studyo.agenda.planner.sectionInfo;

  return (
    <DataPresenter
      sx={sx}
      className={className}
      dataLoaders={[viewModel.data]}
      renderData={() => {
        const hasStudents = viewModel.studentCount > 0;

        return (
          <Stack
            spacing={2}
            sx={{
              width: '100%',
              height: '100%',
              overflow: 'hidden'
            }}
          >
            <Stack
              direction="row"
              sx={{
                px: 2,
                py: 1.5,
                alignItems: 'center',
                backgroundColor: SectionColors.get(viewModel.sectionColor),
                color: theme.studyo.periods.periodWithSectionTextColor
              }}
            >
              <Stack
                sx={{
                  flex: 1
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: 'inherit'
                  }}
                >
                  {viewModel.section.title}
                </Typography>

                <Typography
                  variant="body2"
                  sx={{
                    color: 'inherit'
                  }}
                >
                  {viewModel.section.sectionNumber}
                </Typography>
              </Stack>

              <Typography
                variant="body2"
                sx={{
                  fontStyle: 'italic',
                  color: 'inherit'
                }}
              >
                {strings.studentCount(viewModel.studentCount)}
              </Typography>
            </Stack>
            <Box
              sx={{
                px: 2,
                width: '100%'
              }}
            >
              <TextField
                variant="filled"
                fullWidth
                value={viewModel.searchValue}
                onChange={(e) => (viewModel.searchValue = e.currentTarget.value)}
                label={strings.searchPlaceholder}
                slotProps={{
                  input: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }
                }}
              />
            </Box>
            {!hasStudents && (
              <Stack
                sx={{
                  flex: 1,
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontStyle: 'italic'
                  }}
                >
                  {strings.noStudents}
                </Typography>
              </Stack>
            )}
            {hasStudents && (
              <List sx={{ flex: 1, overflow: 'auto' }}>
                {viewModel.students.map((student, i) => (
                  <Fragment key={student.id}>
                    <ListItem>
                      <AvatarView
                        sx={{ mr: 2 }}
                        size={40}
                        firstName={student.firstName}
                        lastName={student.lastName}
                        isImpersonating={false}
                        color={student.avatarColor}
                      />

                      <ListItemText primary={student.fullName} />

                      <PlannerSectionInfoEmailButton
                        student={student}
                        sendEmail={(s, c) => viewModel.sendEmail(s, c)}
                      />
                    </ListItem>

                    {i < viewModel.students.length - 1 && <Divider />}
                  </Fragment>
                ))}
              </List>
            )}
          </Stack>
        );
      }}
    />
  );
});
