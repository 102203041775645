import { SxProps } from '@mui/material';
import { DialogResult } from '@shared/services';
import {
  ConfirmDialog,
  DialogActionButtons,
  DialogButton,
  ResponsiveDialog,
  SaveDialog,
  useSaveDialog
} from '@studyo/components';
import { LinkedTasksPublishView } from '@studyo/views';
import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { DialogHeader } from '../../../components';

export interface LinkedTasksPublishScreenProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  contentId: string;
}

export const LinkedTasksPublishScreen = observer((props: LinkedTasksPublishScreenProps) => {
  const { localizationService, viewModelFactory } = useStudyoServices();
  const { sx = [], className, contentId, onSuccess, onCancel } = props;
  const viewModel = useMemo(
    () => viewModelFactory.createLinkedTasksPublish(contentId, onSuccess!, onCancel!),
    [contentId]
  );
  const strings = localizationService.localizedStrings.studyo.contents.linkedTasksPublish;

  const [displayCancelChangesDialog, setDisplayCancelChangesDialog] = useState(false);
  const [displayLongTimeSpanDialog, setDisplayLongTimeSpanDialog] = useState(false);
  const [publishPromise, startPublish, resetPublish, retryPublish] = useSaveDialog(() => viewModel.publish());

  const cancel = () => {
    viewModel.cancel();
  };

  const cancelButtonPressed = () => {
    if (viewModel.hasChanges) {
      showCancelConfirmationDialog();
    } else {
      cancel();
    }
  };

  const showCancelConfirmationDialog = () => {
    setDisplayCancelChangesDialog(true);
  };

  const hideCancelConfirmationDialog = () => {
    setDisplayCancelChangesDialog(false);
  };

  const publishButtonPressed = () => {
    if (viewModel.hasLongTimeSpanContent) {
      showLongTimeSpanDialog();
    } else {
      startPublish();
    }
  };

  const longTimeSpanConfirm = () => {
    hideLongTimeSpanDialog();
    startPublish();
  };

  const hideLongTimeSpanDialog = () => {
    setDisplayLongTimeSpanDialog(false);
  };

  const showLongTimeSpanDialog = () => {
    setDisplayLongTimeSpanDialog(true);
  };

  const saveDialogClose = (success: boolean) => {
    resetPublish();
    if (success) {
      viewModel.dismiss();
    }
  };

  return (
    <ResponsiveDialog
      sx={sx}
      className={className}
      maxWidth="sm"
      fullWidth={true}
      open={true}
      maxHeight="large"
      onClose={cancel}
    >
      <DialogHeader
        title={strings.title}
        onClose={cancel}
        rightButton={{ title: strings.publish, action: publishButtonPressed, onlyShowOnExtraSmallScreen: true }}
      />

      <LinkedTasksPublishView sx={{ flex: 1 }} viewModel={viewModel} />

      <DialogActionButtons minimumScreenSize="sm">
        <DialogButton title={strings.cancel} onPress={cancelButtonPressed} type="cancel" />
        <DialogButton title={strings.publish} onPress={publishButtonPressed} type="normal" variant="contained" />
      </DialogActionButtons>

      <ConfirmDialog
        open={displayCancelChangesDialog}
        title={strings.unsavedChangesTitle}
        description={strings.unsavedChangesDescription}
        cancelTitle={strings.unsavedChangesStayTitle}
        onCancelPress={hideCancelConfirmationDialog}
        confirmTitle={strings.unsavedChangesDiscardTitle}
        onConfirmPress={cancel}
        onClose={hideCancelConfirmationDialog}
      />

      <ConfirmDialog
        open={displayLongTimeSpanDialog}
        title={strings.longTimeSpanAlertTitle}
        description={strings.longTimeSpanAlertDescription}
        cancelTitle={localizationService.localizedStrings.studyo.utils.alertDialogCancel}
        onCancelPress={hideLongTimeSpanDialog}
        confirmTitle={strings.publish}
        onConfirmPress={longTimeSpanConfirm}
        onClose={hideLongTimeSpanDialog}
      />

      <SaveDialog
        promise={publishPromise}
        retryCall={retryPublish}
        onClose={saveDialogClose}
        titles={{
          saving: strings.savingTitle,
          saved: strings.savedTitle,
          error: strings.saveErrorTitle
        }}
        descriptions={{
          saving: strings.savingDescription,
          saved: strings.savedDescription,
          error: strings.saveErrorDescription,
          permanentError: strings.savePermanentErrorDescription
        }}
      />
    </ResponsiveDialog>
  );
});
