import { Stack, SxProps } from '@mui/material';
import { isSxArray } from '@shared/components/utils';
import { OnboardingBackgroundImage, OnboardingContentBackgroundView } from '@studyo/components';
import { useTrackWebPage } from '@studyo/services';
import { PreparingSchoolView } from '@studyo/views';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';

export interface PreparingSchoolScreenProps {
  sx?: SxProps;
  className?: string;
  configId: string;
  accountId: string;
}

export const PreparingSchoolScreen = observer((props: PreparingSchoolScreenProps) => {
  const { viewModelFactory } = useStudyoServices();
  const { sx = [], className, accountId, configId } = props;
  const viewModel = useMemo(() => viewModelFactory.createPreparingSchool(), [configId, accountId]);
  useTrackWebPage('Preparing');

  return (
    <Stack
      className={className}
      sx={[
        {
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <OnboardingBackgroundImage />
      <div className="login-container">
        <Stack
          sx={{
            flex: 1,
            position: 'relative'
          }}
        >
          <OnboardingContentBackgroundView sx={{ zIndex: 1 }} />
          <PreparingSchoolView viewModel={viewModel} sx={{ flex: 1, zIndex: 2 }} />
        </Stack>
      </div>
    </Stack>
  );
});
