import { SchoolDayPeriodUtils } from '@shared/components/utils';
import { CourseOccurrence, SchoolDay, SchoolDayPeriod } from '@shared/models/calendar';
import { Day } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { AccountData } from '@shared/services/stores';
import _ from 'lodash';
import { computed, makeObservable } from 'mobx';
import { AccountService } from '../../services';
import {
  AppPeriodPrioritySelectorElementViewModel,
  PeriodPrioritySelectorElementViewModel
} from './PeriodPrioritySelectorElementViewModel';

export interface PeriodPrioritySelectorViewModel {
  elements: PeriodPrioritySelectorElementViewModel[];
  close: () => void;
}

export class AppPeriodPrioritySelectorViewModel implements PeriodPrioritySelectorViewModel {
  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _accountService: AccountService,
    private readonly _periodTag: string,
    private readonly _day: Day,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void
  ) {
    makeObservable(this);
  }

  @computed
  private get data(): AccountData {
    return this._accountService.displayedAccountData;
  }

  @computed
  private get schoolDay(): SchoolDay {
    return this.data.schoolDaysByDay.get(this._day.asString)!;
  }

  @computed
  private get period(): SchoolDayPeriod {
    return this.schoolDay.periods.find((p) => p.tag === this._periodTag)!;
  }

  @computed
  get elements(): PeriodPrioritySelectorElementViewModel[] {
    const occurrences = SchoolDayPeriodUtils.getDisplayedCourseOcccurrences(this.period, this.data.account);
    const currentOccurrence = this.data.periodPrioritiesStore.getOccurrenceForPeriod(this.period, this._day);
    const selectedPeriodHasPriority = this.data.periodPrioritiesStore.getPeriodHasOverlappingPriority(
      this.period,
      this._day
    );

    if (currentOccurrence == null || occurrences.length === 0) {
      return [
        new AppPeriodPrioritySelectorElementViewModel(
          this._localizationService,
          this.data,
          undefined,
          true,
          this._periodTag,
          false,
          () => this.close()
        )
      ];
    } else {
      const elements = occurrences.map((co) => {
        const isSelected = co.sectionId === currentOccurrence.sectionId && selectedPeriodHasPriority;

        return new AppPeriodPrioritySelectorElementViewModel(
          this._localizationService,
          this.data,
          this.data.sectionsById.get(co.sectionId),
          isSelected,
          this._periodTag,
          co.skipped,
          () => this.selectOccurrence(co, selectedPeriodHasPriority)
        );
      });

      const conflictingPeriods = this.schoolDay.periods.filter((p) => this.period.conflictingPeriodIds.includes(p.id));

      const conflictingElements = _.compact(
        conflictingPeriods.map((p) => {
          const occurrence = this.data.periodPrioritiesStore.getOccurrenceForPeriod(p, this._day);
          if (occurrence != null || currentOccurrence == null) {
            const periodHasPriority = this.data.periodPrioritiesStore.getPeriodHasOverlappingPriority(p, this._day);

            return new AppPeriodPrioritySelectorElementViewModel(
              this._localizationService,
              this.data,
              occurrence != null ? this.data.sectionsById.get(occurrence.sectionId) : undefined,
              periodHasPriority,
              p.tag,
              occurrence?.skipped ?? false,
              () => this.selectPeriod(p)
            );
          }
          return undefined;
        })
      );

      return _.orderBy(elements.concat(conflictingElements), (e) => e.isSkipped);
    }
  }

  close() {
    this._onCancel();
  }

  private selectOccurrence(occurrence: CourseOccurrence, hasPriority: boolean) {
    if (!hasPriority) {
      this.data.periodPrioritiesStore.setPriorityToPeriod(this.period, this._day);
    }
    this.data.periodPrioritiesStore.setPriorityToOccurrenceInPeriod(occurrence, this.period, this._day);
    this._onSuccess();
  }

  private selectPeriod(period: SchoolDayPeriod) {
    this.data.periodPrioritiesStore.setPriorityToPeriod(period, this._day);
    this._onSuccess();
  }
}
