import { Edit } from '@mui/icons-material';
import { IconButton, ListItem, ListItemText, SxProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { ProfileMenuActiveProfileViewModel } from '../../../viewmodels';
import { AvatarView } from '../AvatarView';

export interface ActiveProfileBoxProps {
  sx?: SxProps;
  className?: string;
  viewModel: ProfileMenuActiveProfileViewModel;
}

export const ActiveProfileBox = observer((props: ActiveProfileBoxProps) => {
  const { viewModel, sx = [], className } = props;
  return (
    <ListItem
      sx={sx}
      className={className}
      secondaryAction={
        viewModel.canEditProfile && (
          <IconButton onClick={() => viewModel.editProfile()} size="medium" color="primary" sx={{ ml: 1 }}>
            <Edit fontSize="medium" />
          </IconButton>
        )
      }
    >
      <AvatarView
        sx={{ mr: 2 }}
        size={50}
        firstName={viewModel.firstName}
        lastName={viewModel.lastName}
        color={viewModel.color}
        isImpersonating={viewModel.isImpersonating}
      />

      <ListItemText
        primary={viewModel.displayName}
        secondary={`${viewModel.schoolName} ( ${viewModel.schoolTitle} )`}
      />
    </ListItem>
  );
});
