import { Box, SxProps, Typography, useTheme } from '@mui/material';
import { StudyoTheme } from '@studyo/theme';
import { observer } from 'mobx-react-lite';

const size = 30;
const backgroundSize = Math.sqrt(size * size * 2);

export interface PeriodOverlapIndicatorProps {
  sx?: SxProps;
  className?: string;
  onPress: () => void;
  theme?: StudyoTheme;
}

export const PeriodOverlapIndicator = observer((props: PeriodOverlapIndicatorProps) => {
  const { sx = [], className, onPress } = props;
  const theme = useTheme();

  return (
    <Box
      onClick={onPress}
      sx={{ ...sx, overflow: 'hidden', width: size, height: size, cursor: 'pointer' }}
      className={className}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.error.main,
          position: 'absolute',
          width: backgroundSize,
          height: backgroundSize,
          top: -backgroundSize / 2,
          right: -backgroundSize / 2,
          transform: 'rotate(45deg)'
        }}
      />
      <Typography
        sx={{
          color: 'white',
          position: 'absolute',
          right: 3,
          top: -3,
          userSelect: 'none'
        }}
      >
        +
      </Typography>
    </Box>
  );
});
