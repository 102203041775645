import { SxProps } from '@mui/material';
import { useTrackWebPage } from '@studyo/services';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { AgendaPlannerView } from '../../../views';

export interface AgendaPlannerScreenProps {
  sx?: SxProps;
  className?: string;
}

export const AgendaPlannerScreen = observer((props: AgendaPlannerScreenProps) => {
  const { modalService, viewModelFactory } = useStudyoServices();
  const { sx = [], className } = props;
  const params = useParams();
  const configId = params.configId ?? '';
  const accountId = params.accountId ?? '';

  const viewModel = useMemo(() => viewModelFactory.createAgendaPlanner(), [configId, accountId]);
  useTrackWebPage('Teacher planner view');

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [configId, accountId]);

  const handleKeyPress = (e: KeyboardEvent) => {
    if (!modalService.isDisplayingModal) {
      if (e.code === 'ArrowLeft') {
        viewModel.goToPreviousWeek();
      } else if (e.code === 'ArrowRight') {
        viewModel.goToNextWeek();
      }
    }
  };

  return <AgendaPlannerView sx={sx} className={className} viewModel={viewModel} />;
});
