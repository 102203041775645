import { css } from '@emotion/css';
import { Box, Stack, SxProps, useTheme } from '@mui/material';
import {
  PlannerConstants,
  PlannerDayHeader,
  PlannerNoPeriodCell,
  PlannerPeriodCell,
  PlannerSectionHeader
} from '@studyo/components';
import { PlannerGridViewModel } from '@studyo/viewmodels';
import { times } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';

export interface AgendaPlannerGridProps {
  sx?: SxProps;
  className?: string;
  viewModel: PlannerGridViewModel;
  height: number;
  width: number;
}

export const AgendaPlannerGrid = observer((props: AgendaPlannerGridProps) => {
  const { viewModel, height, width, sx = [], className } = props;
  const theme = useTheme();

  const rowHeight = useMemo(() => {
    switch (viewModel.periodHeightKind) {
      case 'small':
        return PlannerConstants.rowHeightSmall;

      case 'medium':
        return PlannerConstants.rowHeightMedium;

      case 'large':
        return PlannerConstants.rowHeightLarge;
    }
  }, [viewModel.periodHeightKind]);

  const numberOfColumns = useMemo(() => {
    return Math.ceil(width / PlannerConstants.columnWidth);
  }, [width]);

  const renderLeftComponent = (index: number) => {
    if (index === viewModel.sections.length) {
      return <Box sx={{ width: PlannerConstants.sectionHeaderWidth, height: rowHeight, flexShrink: 0 }} />;
    }

    const isLastRow = index === viewModel.sections.length - 1;

    const sectionHeaderVM = viewModel.sections[index];

    return (
      <PlannerSectionHeader
        key={`section-header-${index}`}
        sx={{
          flexShrink: 0,
          width: PlannerConstants.sectionHeaderWidth,
          height: rowHeight,
          borderWidth: 0,
          borderColor: theme.studyo.agenda.planner.grid.gridSeparatorColor,
          borderStyle: 'solid',
          borderBottomWidth: !isLastRow ? 1 : 0
        }}
        viewModel={sectionHeaderVM}
      />
    );
  };

  const renderTopComponent = (index: number) => {
    if (index >= viewModel.schoolDays.length) {
      return (
        <Box
          key={`top-component-${index}`}
          sx={{
            flexShrink: 0,
            width: PlannerConstants.columnWidth,
            height: PlannerConstants.dayHeaderHeight,
            backgroundColor: theme.studyo.agenda.planner.grid.dayHeaderBackgroundColor
          }}
        />
      );
    }

    const dayHeaderVM = viewModel.schoolDays[index].header;

    const { displayWeekendIndicator } = dayHeaderVM;
    const isMonday = dayHeaderVM.day.dayOfWeek === 'monday';
    const isFriday = dayHeaderVM.day.dayOfWeek === 'friday';

    let leftBorderColor: string = theme.studyo.agenda.planner.grid.gridSeparatorColor;
    if (dayHeaderVM.isFirstDayOfCycle) {
      leftBorderColor = theme.studyo.agenda.planner.grid.cycleDayStartColor;
    } else if (displayWeekendIndicator && isMonday) {
      leftBorderColor = theme.studyo.agenda.planner.grid.weekendLineColor;
    }

    return (
      <Stack
        direction="row"
        sx={{
          flexShrink: 0,
          width: PlannerConstants.columnWidth,
          height: PlannerConstants.dayHeaderHeight,
          overflow: 'hidden'
        }}
        key={`top-component-${index}`}
      >
        <Box sx={{ backgroundColor: leftBorderColor, width: '1px' }} />
        {isMonday && displayWeekendIndicator && (
          <Box sx={{ backgroundColor: theme.studyo.agenda.planner.grid.weekendLineColor, width: '2px' }} />
        )}
        <Stack
          sx={{
            flex: 1,
            overflow: 'hidden'
          }}
        >
          <PlannerDayHeader sx={{ flex: 1 }} viewModel={dayHeaderVM} />

          <Box sx={{ backgroundColor: theme.studyo.agenda.planner.grid.gridSeparatorColor, height: '1px' }} />
        </Stack>
        {isFriday && displayWeekendIndicator && (
          <Box sx={{ backgroundColor: theme.studyo.agenda.planner.grid.weekendLineColor, width: '2px' }} />
        )}
      </Stack>
    );
  };

  const renderCell = (rowIndex: number, columnIndex: number) => {
    const baseClassName = css({
      width: PlannerConstants.columnWidth,
      height: rowHeight,
      flexShrink: 0
    });

    // We need to display an empty row if no section so that the scroll isn't disabled.
    if (viewModel.sections.length === 0 && rowIndex === 0) {
      return <Box key={`cell-row-${rowIndex}-column-${columnIndex}`} className={baseClassName} />;
    }

    // We need to display an empty row if no section so that the scroll isn't disabled.
    if (columnIndex >= viewModel.schoolDays.length) {
      return <Box key={`cell-row-${rowIndex}-column-${columnIndex}`} className={baseClassName} />;
    }

    const cellVM = viewModel.schoolDays[columnIndex].cells[rowIndex];

    const periodComponent = cellVM.hasPeriod ? (
      <PlannerPeriodCell sx={{ flex: 1 }} viewModel={cellVM} />
    ) : (
      <PlannerNoPeriodCell sx={{ flex: 1 }} viewModel={cellVM} />
    );

    const { displayWeekendIndicator } = cellVM;
    const isMonday = cellVM.day.dayOfWeek === 'monday';
    const isFriday = cellVM.day.dayOfWeek === 'friday';
    const isLastRow = rowIndex === viewModel.sections.length - 1;

    let leftBorderColor: string = theme.studyo.agenda.planner.grid.gridCellSeparatorColor;
    if (cellVM.isFirstDayOfCycle) {
      leftBorderColor = theme.studyo.agenda.planner.grid.cycleDayStartColor;
    } else if (displayWeekendIndicator && isMonday) {
      leftBorderColor = theme.studyo.agenda.planner.grid.weekendLineColor;
    }

    return (
      <Stack direction="row" className={baseClassName} key={`cell-row-${rowIndex}-column-${columnIndex}`}>
        <Box sx={{ backgroundColor: leftBorderColor, width: '1px' }} />
        {isMonday && displayWeekendIndicator && (
          <Box sx={{ backgroundColor: theme.studyo.agenda.planner.grid.weekendLineColor, width: '2px' }} />
        )}
        <Stack
          sx={{
            flex: 1,
            overflow: 'hidden'
          }}
        >
          {periodComponent}
          {!isLastRow && (
            <Box sx={{ backgroundColor: theme.studyo.agenda.planner.grid.gridSeparatorColor, height: '1px' }} />
          )}
        </Stack>
        {isFriday && displayWeekendIndicator && (
          <Box sx={{ backgroundColor: theme.studyo.agenda.planner.grid.weekendLineColor, width: '2px' }} />
        )}
      </Stack>
    );
  };

  return (
    <Stack
      sx={{
        ...sx,
        height,
        width,
        backgroundColor: theme.studyo.agenda.planner.grid.backgroundColor,
        overflow: 'hidden'
      }}
      className={className}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          backgroundColor: theme.studyo.agenda.planner.grid.dayHeaderBackgroundColor
        }}
      >
        <Box
          sx={{
            flexShrink: 0,
            height: PlannerConstants.dayHeaderHeight,
            width: PlannerConstants.sectionHeaderWidth,
            backgroundColor: theme.studyo.agenda.planner.grid.topLeftEmptyViewColor
          }}
        />

        {times(numberOfColumns).map((_, index) => renderTopComponent(viewModel.currentDayIndex + index))}
      </Box>
      <Stack
        sx={{
          flex: 1,
          overflowY: 'auto',
          overflowX: 'hidden'
        }}
      >
        {viewModel.sections.map((_, index) => (
          <Stack
            direction="row"
            key={`section-row-${index}`}
            sx={{ backgroundColor: theme.studyo.agenda.planner.grid.backgroundColor }}
          >
            {renderLeftComponent(index)}
            {times(numberOfColumns).map((_, columnIndex) => renderCell(index, viewModel.currentDayIndex + columnIndex))}
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
});
