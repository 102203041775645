import { Capacitor } from '@capacitor/core';
import { css } from '@emotion/css';
import { Box, Stack, SxProps, Typography, useMediaQuery, useTheme } from '@mui/material';
import { SpecialDaySymbolGrid } from '@shared/components/special_day_symbols';
import { DateUtils, isSxArray } from '@shared/components/utils';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { DayAndWeekHeaderViewModel } from '../../../viewmodels';
import { CalendarHeader } from '../header';

export interface DailyHeaderProps {
  sx?: SxProps;
  className?: string;
  viewModel: DayAndWeekHeaderViewModel;
  goToNextPage: () => void;
  goToPreviousPage: () => void;
}

export const DailyHeader = observer(
  ({ sx = [], viewModel, goToNextPage, goToPreviousPage, className }: DailyHeaderProps) => {
    const { localizationService } = useStudyoServices();
    const theme = useTheme();
    const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));

    return (
      <CalendarHeader
        sx={sx}
        className={className}
        viewModel={viewModel}
        goToNextPage={goToNextPage}
        goToPreviousPage={goToPreviousPage}
        dateSwitcherContext="daily"
        actionButtons={
          Capacitor.isNativePlatform()
            ? [
                { kind: 'camera', action: () => void viewModel.createNewTask() },
                { kind: 'options', action: () => void viewModel.displayOptions() }
              ]
            : [{ kind: 'options', action: () => void viewModel.displayOptions() }]
        }
        renderDate={(sx) => {
          const isToday = DateUtils.isToday(viewModel.currentDay);
          const onlyOneSpecialDay = viewModel.specialDays.length == 1;
          const hasSymbols = _.some(viewModel.specialDaySymbolAndColors, (s) => s.symbol !== 'none');

          const todaySymbolColorClassName = css({
            backgroundColor: theme.studyo.todaySymbolBackgroundColor,
            position: 'absolute',
            left: 0,
            right: 0,
            top: 4,
            bottom: 4,
            borderRadius: 10,
            opacity: 0.15
          });

          return (
            <Stack
              direction="row"
              spacing={1}
              sx={[
                {
                  alignItems: 'center',
                  overflow: 'hidden'
                },
                ...(isSxArray(sx) ? sx : [sx])
              ]}
            >
              <Stack
                direction="row"
                sx={{
                  alignItems: 'flex-start'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minWidth: isExtraSmallScreen ? 50 : 70,
                    position: 'relative'
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: isExtraSmallScreen ? 40 : 50,
                      fontWeight: 'lighter',
                      userSelect: 'none'
                    }}
                  >
                    {viewModel.currentDay.day}
                  </Typography>
                  {isToday && <Box className={todaySymbolColorClassName} />}
                </Box>

                <Typography
                  variant="caption"
                  sx={{
                    color: (theme) => theme.palette.text.secondary,
                    textAlign: 'center',
                    userSelect: 'none',
                    ml: isToday ? 1 : 0
                  }}
                >
                  {viewModel.currentDayCycleDay}
                </Typography>
              </Stack>
              <Stack
                spacing={{ xs: 0, sm: 0.5 }}
                sx={{
                  flex: 1,
                  overflow: 'hidden',
                  justifyContent: 'flex-start'
                }}
              >
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{
                    alignItems: 'baseline',
                    overflow: 'hidden'
                  }}
                >
                  <Typography
                    variant={isExtraSmallScreen ? 'body2' : 'h6'}
                    noWrap
                    sx={{
                      fontWeight: 'medium',
                      userSelect: 'none'
                    }}
                  >
                    {viewModel.currentDay.formattedString(
                      localizationService.localizedStrings.models.dateFormats.dayOfWeek
                    )}
                  </Typography>

                  <Typography
                    variant={isExtraSmallScreen ? 'body2' : 'h6'}
                    noWrap
                    sx={{
                      flex: 1,
                      fontWeight: 'normal',
                      userSelect: 'none',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {viewModel.currentDay.formattedString(
                      localizationService.localizedStrings.models.dateFormats.monthYearUnabridged
                    )}
                  </Typography>
                </Stack>

                <Stack
                  direction="row"
                  spacing={1}
                  sx={{
                    alignItems: 'center',
                    height: isExtraSmallScreen ? 22 : 26
                  }}
                >
                  {hasSymbols && (
                    <SpecialDaySymbolGrid
                      symbols={viewModel.specialDaySymbolAndColors}
                      squareSize={onlyOneSpecialDay ? (isExtraSmallScreen ? 22 : 26) : isExtraSmallScreen ? 8 : 12}
                      maxSymbolCount={4}
                      displayKind={onlyOneSpecialDay ? 'linear' : 'grid'}
                    />
                  )}

                  <Typography
                    variant="body2"
                    noWrap
                    sx={{
                      flex: 1,
                      fontSize: isExtraSmallScreen ? 12 : undefined,
                      userSelect: 'none',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {viewModel.specialDayTitles}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          );
        }}
      />
    );
  }
);
