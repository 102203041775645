import { Box, Stack, SxProps, Typography } from '@mui/material';
import {
  VirtualizedSectionList,
  VirtualizedSectionListHandle,
  VirtualizedSectionListOnScrollProps
} from '@shared/rxp/virtualized-section-list';
import { reaction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef } from 'react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { PeriodsListItemHeight, PeriodsListViewModel } from '../../../viewmodels';
import { PeriodsListItem } from './PeriodsListItem';

export interface PeriodsListProps {
  sx?: SxProps;
  className?: string;
  viewModel: PeriodsListViewModel;
}

export const PeriodsList = observer((props: PeriodsListProps) => {
  const { localizationService } = useStudyoServices();
  const { sx = [], className, viewModel } = props;
  const strings = localizationService.localizedStrings.studyo.agenda.periods;
  const listRef = useRef<VirtualizedSectionListHandle | null>(null);

  const shouldShowList = viewModel.sections[0].data.length > 0;

  const onScroll = (props: VirtualizedSectionListOnScrollProps) => {
    viewModel.listDidScrollWithProps(props);
  };

  useEffect(() => {
    viewModel.scrollToCurrentDay();

    const virtualListDisposer = reaction(
      () => viewModel.scrollItemIndex,
      (index) => {
        const actualOffset = viewModel.scrollOffset;

        const periodStartBound = index * PeriodsListItemHeight;
        const periodEndBound = periodStartBound + PeriodsListItemHeight;

        if (actualOffset < periodStartBound || actualOffset >= periodEndBound) {
          listRef.current?.scrollToOffset(periodStartBound);
        }
      }
    );

    const lastChangedDisposer = reaction(
      () => viewModel.lastNoteChangedIndexPath,
      (indexPath) => {
        if (indexPath != null) {
          listRef.current?.reloadRowsAtIndexPath(indexPath, true);
        }
      }
    );

    return () => {
      virtualListDisposer();
      lastChangedDisposer();
    };
  }, [viewModel]);

  return (
    <Stack sx={sx} className={className}>
      {shouldShowList && (
        <VirtualizedSectionList
          sx={{ flex: 1 }}
          key={`virtualized-periods-list-${viewModel.sectionId}`}
          ref={(ref) => (listRef.current = ref)}
          sections={viewModel.sections}
          renderRowAtIndexPath={(indexPath) => (
            <PeriodsListItem
              key={`periods-list-item-${viewModel.sectionId}-${indexPath.index}`}
              height={viewModel.heightForRowAtIndexPath(indexPath)}
              viewModel={viewModel.periodsListItemViewModelAtIndexPath(indexPath)}
            />
          )}
          heightForRowAtIndexPath={(indexPath) => viewModel.heightForRowAtIndexPath(indexPath)}
          renderHeaderForSection={() => <Box />}
          heightForSectionHeader={() => 0}
          renderFooterForSection={() => <Box />}
          heightForSectionFooter={() => 0}
          offsetBottom={20}
          initialOffset={(viewModel.scrollItemIndex + 1) * PeriodsListItemHeight}
          onScroll={onScroll}
          overscanCount={15}
        />
      )}
      {!shouldShowList && (
        <Stack
          sx={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Typography>{strings.noOccurrences}</Typography>
        </Stack>
      )}
    </Stack>
  );
});
