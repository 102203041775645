import { Stack, SxProps, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';

export interface BottomTabBarProps {
  sx?: SxProps;
  className?: string;
  children?: ReactNode;
}

export const BottomTabBar = observer((props: BottomTabBarProps) => {
  const { children, sx = [], className } = props;
  const theme = useTheme();

  return (
    <Stack
      direction="row"
      className={className}
      spacing={{ xs: 1, md: 0 }}
      sx={{
        alignItems: 'center',
        justifyContent: { xs: 'stretch', sm: 'center' },
        ...sx,
        backgroundColor: theme.studyo.navigation.bottomTabBarBackgroundColor,
        borderColor: theme.studyo.navigation.separatorColor,
        borderWidth: 0,
        borderTopWidth: 0.5,
        borderStyle: 'solid'
      }}
    >
      {children}
    </Stack>
  );
});
