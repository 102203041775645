import { Breakpoint, Stack, SxProps, useMediaQuery, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';

export interface DialogActionButtonsProps {
  sx?: SxProps;
  className?: string;
  children: ReactNode;
  minimumScreenSize?: Breakpoint;
  backgroundColor?: string;
}

export const DialogActionButtons = observer((props: DialogActionButtonsProps) => {
  const { sx = [], className, children, backgroundColor, minimumScreenSize } = props;
  const theme = useTheme();
  const isVisible = useMediaQuery(() => theme.breakpoints.up(minimumScreenSize ?? 'xs'));

  if (!isVisible) {
    return null;
  }

  return (
    <Stack
      direction="row"
      className={className}
      spacing={1}
      sx={{
        p: 2,
        justifyContent: 'flex-end',
        ...sx,
        backgroundColor: backgroundColor
      }}
    >
      {children}
    </Stack>
  );
});
