import { AccountUtils, SectionUtils } from '@shared/components/utils';
import { SectionModel } from '@shared/models/config';
import { Color } from '@shared/models/types';
import { DataLoader, EmailInfo, LinkingService } from '@shared/services';
import { AccountData } from '@shared/services/stores';
import { orderBy } from 'lodash';
import { computed, makeObservable, observable } from 'mobx';
import { AccountService } from '../../../services';
import {
  AppPlannerSectionInfoStudentRowViewModel,
  PlannerSectionInfoStudentRowViewModel
} from './PlannerSectionInfoStudentRowViewModel';

export type PlannerSectionInfoEmailRole = 'student' | 'parent';

export interface PlannerSectionInfoViewModel {
  searchValue: string;
  readonly data: DataLoader;
  readonly section: SectionModel;
  readonly sectionColor: Color;
  readonly studentCount: number;
  readonly students: PlannerSectionInfoStudentRowViewModel[];
  sendEmail(student: PlannerSectionInfoStudentRowViewModel, toRole: PlannerSectionInfoEmailRole): void;
  sendEmailToAll(role: PlannerSectionInfoEmailRole | undefined): void;
  dismiss(): void;
}

export class AppPlannerSectionInfoViewModel implements PlannerSectionInfoViewModel {
  readonly data: AccountData;

  @observable private _searchValue = '';

  constructor(
    private readonly _sectionId: string,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    private readonly _linkingService: LinkingService,
    accountService: AccountService
  ) {
    makeObservable(this);
    this.data = accountService.displayedAccountData;
  }

  @computed
  private get allStudents() {
    return orderBy(this.data.getStudentsForSection(this.section), [(s) => s.lastName, (s) => s.firstName]);
  }

  @computed
  private get allParents() {
    const studentIds = this.allStudents.map((s) => s.id);
    return this.data.accounts.filter(
      (a) => a.role === 'parent' && a.childrenAccountIds.filter((id) => studentIds.includes(id)).length > 0
    );
  }

  @computed
  private get upperCasedStudents() {
    return this.allStudents.map((student) => ({
      student,
      upperCaseDisplayName: AccountUtils.getDisplayLastFirstName(student).toUpperCase()
    }));
  }

  @computed
  get searchValue(): string {
    return this._searchValue;
  }

  set searchValue(value: string) {
    this._searchValue = value;
  }

  @computed
  get section(): SectionModel {
    const section = this.data.sectionsById.get(this._sectionId);
    if (section == null) {
      throw new Error("Section doesn't exists.");
    }
    return section;
  }

  @computed
  get sectionColor(): Color {
    return SectionUtils.getSectionColor(this.section, this.data.account, undefined)!;
  }

  @computed
  get studentCount() {
    return this.allStudents.length;
  }

  @computed
  get students(): PlannerSectionInfoStudentRowViewModel[] {
    const searchString = this.searchValue.toUpperCase();
    return (
      searchString.length === 0
        ? this.allStudents
        : this.upperCasedStudents.filter((uc) => uc.upperCaseDisplayName.includes(searchString)).map((uc) => uc.student)
    ).map((a) => new AppPlannerSectionInfoStudentRowViewModel(a, this.data));
  }

  sendEmail(student: PlannerSectionInfoStudentRowViewModel, toRole: PlannerSectionInfoEmailRole) {
    const emails = toRole === 'student' ? [student.emailAddress] : student.parentEmailAddresses;
    this.sendEmailTo(emails);
  }

  sendEmailToAll(role: PlannerSectionInfoEmailRole | undefined) {
    let emails: string[];

    switch (role) {
      case 'student':
        emails = this.allStudents.map((s) => s.visibleEmail);
        break;

      case 'parent':
        emails = this.allParents.map((p) => p.visibleEmail);
        break;

      default:
        emails = this.allStudents.map((s) => s.visibleEmail);
        emails.push(...this.allParents.map((p) => p.visibleEmail));
        break;
    }

    this.sendEmailTo(emails);
  }

  dismiss() {
    this._onSuccess();
  }

  private sendEmailTo(emails: string[]) {
    const toEmail = emails.length > 1 ? this.data.account.visibleEmail : emails[0];
    const bccEmails = emails.length > 1 ? emails : [];

    const info: EmailInfo = {
      bcc: bccEmails,
      to: [toEmail]
    };

    this._linkingService.launchEmail(info);
  }
}
