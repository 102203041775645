import { Box, Breakpoint, CardActionArea, Stack, SxProps, Typography, useMediaQuery, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';
import { Badge } from './utils';

export interface BottomTabBarItemProps {
  sx?: SxProps;
  className?: string;
  title: string;
  onClick: () => void;
  isActive: boolean;
  icon: (size: number, color: string) => ReactNode;
  minimumVisibleWidth?: Breakpoint;
  badgeCount?: number;
}

export const BottomTabBarItem = observer((props: BottomTabBarItemProps) => {
  const { icon, isActive = false, onClick, sx = [], className, minimumVisibleWidth, title, badgeCount } = props;
  const theme = useTheme();
  const isVisible = useMediaQuery(() => theme.breakpoints.up(minimumVisibleWidth ?? 'xs'));
  const isSmallScreen = useMediaQuery(() => theme.breakpoints.down('md'));

  if (!isVisible) {
    return null;
  }

  return (
    <Box sx={sx} className={className}>
      <CardActionArea onClick={() => onClick()} sx={{ height: '100%' }}>
        <Stack
          direction="row"
          sx={{
            px: 2,
            pt: 1,
            pb: isSmallScreen ? 0.5 : 1,
            justifyContent: 'center'
          }}
        >
          <Badge badgeContent={badgeCount} color={'error'}>
            <Stack
              direction={isSmallScreen ? 'column' : 'row'}
              spacing={isSmallScreen ? 0 : 1}
              sx={{
                alignItems: 'center'
              }}
            >
              {icon(isSmallScreen ? 22 : 28, isActive ? theme.palette.primary.main : theme.palette.text.secondary)}

              {title != null && (
                <Typography
                  variant="caption"
                  sx={{
                    color: (theme) => (isActive ? theme.palette.primary.main : theme.palette.text.secondary),
                    userSelect: 'none',
                    fontSize: isSmallScreen ? 10 : undefined,
                    fontWeight: isSmallScreen ? '500' : undefined
                  }}
                >
                  {title}
                </Typography>
              )}
            </Stack>
          </Badge>
        </Stack>
      </CardActionArea>
    </Box>
  );
});
