import { Stack, SxProps, Tab, Tabs } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useStudyoServices } from '../../UseStudyoServicesHook';
import { GearMenuShareViewModel, ShareOption } from '../../viewmodels';
import { InviteParent } from './InviteParent';
import { ShareCalendar } from './ShareCalendar';

export interface GearMenuShareViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: GearMenuShareViewModel;
}

export const GearMenuShareView = observer((props: GearMenuShareViewProps) => {
  const { localizationService } = useStudyoServices();
  const { sx = [], className, viewModel } = props;
  const strings = localizationService.localizedStrings.studyo.agenda.setting.gearMenuShare;

  function getTab(option: ShareOption) {
    switch (option) {
      case 'calendar-link':
        return (
          <Tab key={option} value={option} label={strings.shareCalendarLink} disabled={!viewModel.hasFetchedData} />
        );

      case 'invite-parent':
        return <Tab key={option} value={option} label={strings.inviteMyParent} disabled={!viewModel.hasFetchedData} />;
    }
  }

  return (
    <Stack sx={{ ...sx, backgroundColor: 'transparent' }} className={className}>
      {viewModel.displayOptions.length > 1 && (
        <Tabs
          value={viewModel.currentDisplay}
          onChange={(_, value: ShareOption) => (viewModel.currentDisplay = value)}
          variant="fullWidth"
        >
          {viewModel.displayOptions.map((o) => getTab(o))}
        </Tabs>
      )}

      {viewModel.currentDisplay === 'invite-parent' && <InviteParent sx={{ flex: 1 }} viewModel={viewModel} />}
      {viewModel.currentDisplay === 'calendar-link' && <ShareCalendar sx={{ flex: 1 }} viewModel={viewModel} />}
    </Stack>
  );
});
