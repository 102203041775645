import { Box, Stack, SxProps, Typography, useTheme } from '@mui/material';
import { SpecialDaySymbolGrid } from '@shared/components/special_day_symbols';
import { isSxArray } from '@shared/components/utils';
import { SectionColors } from '@shared/models/Colors';
import { observer } from 'mobx-react-lite';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { DisplayableContentBoxViewModel, WeeklyDayHeaderViewModel } from '../../../viewmodels';
import { DisplayableContentBox } from '../../contents';
import { DayAndWeekConstants } from '../shared';
import { DayInfo } from '../utils';

interface WeeklyDayHeaderProps {
  sx?: SxProps;
  className?: string;
  contentBoxViewModel: DisplayableContentBoxViewModel;
  viewModel: WeeklyDayHeaderViewModel;
}

export const WeeklyDayHeader = observer((props: WeeklyDayHeaderProps) => {
  const { localizationService } = useStudyoServices();
  const { viewModel, sx = [], className, contentBoxViewModel } = props;
  const theme = useTheme();
  const dow = viewModel.day.dayOfWeek;
  const dowText = localizationService.localizedStrings.models.dayOfWeek.shortLocalizedDayOfWeek(dow);

  return (
    <Stack
      className={className}
      sx={[
        {
          position: 'relative',
          overflow: 'hidden'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <Box
        sx={{
          backgroundColor: viewModel.isToday ? theme.studyo.todaySymbolBackgroundColor : undefined,
          opacity: 0.15,
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
        }}
      />
      <DayInfo day={viewModel.day} key={viewModel.day.asString}>
        <Stack
          direction="row"
          spacing={1}
          sx={{
            flex: 1,
            alignItems: 'baseline',
            overflow: 'hidden',
            py: 0.5,
            px: 1
          }}
        >
          <Stack direction="row" spacing={0.5}>
            <Typography variant="body1" sx={{ userSelect: 'none' }}>
              {viewModel.day.day}
            </Typography>

            {viewModel.cycleDayTitle.length > 0 && (
              <Typography
                variant="caption"
                sx={{
                  color: (theme) => theme.palette.text.secondary,
                  userSelect: 'none'
                }}
              >
                {viewModel.cycleDayTitle}
              </Typography>
            )}
          </Stack>

          <Typography variant="subtitle2">{dowText}</Typography>

          {viewModel.specialDaySymbolsAndColors.length > 0 && (
            <SpecialDaySymbolGrid
              squareSize={viewModel.specialDaySymbolsAndColors.length > 1 ? 6 : 14}
              maxSymbolCount={4}
              displayKind={viewModel.specialDaySymbolsAndColors.length > 1 ? 'grid' : 'linear'}
              symbols={viewModel.specialDaySymbolsAndColors}
            />
          )}

          <Stack
            direction="row"
            sx={{
              flex: 1,
              overflow: 'hidden'
            }}
          >
            {viewModel.specialDays.map((specialDay, i) => {
              const isLast = i === viewModel.specialDays.length - 1;
              const suffix = !isLast ? ', ' : '';
              return (
                <Typography
                  key={i}
                  noWrap
                  variant="caption"
                  sx={{
                    color: specialDay.symbol !== 'none' ? SectionColors.get(specialDay.color) : undefined,
                    userSelect: 'none'
                  }}
                >{`${specialDay.title}${suffix}`}</Typography>
              );
            })}
          </Stack>
        </Stack>
      </DayInfo>
      <DisplayableContentBox
        sx={{ height: 40, m: 0.25 }}
        viewModel={contentBoxViewModel}
        representationKind="detailed"
        itemSpacing={DayAndWeekConstants.itemSpacing}
        iconSize={DayAndWeekConstants.detailedIconSize}
        minItemHeight={DayAndWeekConstants.detailedMinItemHeight}
        minItemWidth={DayAndWeekConstants.detailedMinItemWidth}
        maxItemWidth={DayAndWeekConstants.detailedMaxItemWidth}
      />
    </Stack>
  );
});
