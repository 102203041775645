import { SxProps } from '@mui/material';
import { DialogResult } from '@shared/services';
import { DialogActionButtons, DialogButton, ResponsiveDialog } from '@studyo/components';
import { OtherProfilesListView } from '@studyo/views';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { DialogHeader } from '../../../components';

export interface OtherProfilesListScreenProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
}

export const OtherProfilesListScreen = observer((props: OtherProfilesListScreenProps) => {
  const { localizationService, viewModelFactory } = useStudyoServices();
  const { sx = [], className, onCancel, onSuccess } = props;
  const strings = localizationService.localizedStrings.studyo.agenda.setting.otherProfilesList;
  const viewModel = useMemo(() => viewModelFactory.createOtherProfilesList(onSuccess!, onCancel!), []);

  const close = () => {
    viewModel.dismiss();
  };

  return (
    <ResponsiveDialog
      sx={sx}
      className={className}
      maxWidth="sm"
      fullWidth={true}
      open={true}
      maxHeight="large"
      onClose={close}
    >
      <DialogHeader title={strings.title} onClose={close} />

      <OtherProfilesListView sx={{ flex: 1 }} viewModel={viewModel} />

      <DialogActionButtons minimumScreenSize="sm">
        <DialogButton title={strings.close} type="normal" onPress={close} />
      </DialogActionButtons>
    </ResponsiveDialog>
  );
});
