import { SectionUtils } from '@shared/components/utils';
import { AccountModel, SectionModel } from '@shared/models/config';
import { Color } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { AccountData } from '@shared/services/stores';
import { debounce } from 'lodash';
import { action, computed, makeObservable, observable, when } from 'mobx';
import { AccountService, NavigationService, StudyoAccountSettings, StudyoSettingsStore } from '../../services';

export interface SectionFilterItemViewModel {
  readonly sectionColor: Color;
  readonly sectionTitle: string;
  readonly sectionNumber: string;
  readonly isSelected: boolean;

  onSelection(): void;
}

export class AppSectionFilterItemViewModel implements SectionFilterItemViewModel {
  @observable private _isSelected: boolean;

  constructor(
    private readonly _section: SectionModel,
    private readonly _account: AccountModel,
    readonly onSelection: () => void,
    isSelected: boolean
  ) {
    makeObservable(this);
    this._isSelected = isSelected;
  }

  @computed
  get sectionColor(): Color {
    return SectionUtils.getSectionColor(this._section, this._account, undefined)!;
  }

  @computed
  get sectionTitle(): string {
    return this._section.title;
  }

  @computed
  get sectionNumber(): string {
    return this._section.sectionNumber;
  }

  @computed
  get isSelected() {
    return this._isSelected;
  }
}

export interface SectionFilterViewModel {
  data: AccountData;
  preferences: StudyoAccountSettings;
  items: SectionFilterItemViewModel[];
  isFiltered: boolean;
  dismiss: () => void;
  reset: () => void;
}

export abstract class AppSectionFilterViewModel implements SectionFilterViewModel {
  readonly data: AccountData;
  readonly preferences: StudyoAccountSettings;

  @observable protected _selectedSectionIds: string[];

  // Prevents calling the settingsStore multiple times in a row. If multiple changes occurs within
  // 250ms, they will only trigger one change.
  private _setSelectionSectionsDebounced: () => void;

  constructor(
    private readonly _onlyShowTaughtSections: boolean,
    private readonly _localizationService: LocalizationService,
    private readonly _navigationService: NavigationService,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    accountService: AccountService,
    settings: StudyoSettingsStore
  ) {
    makeObservable(this);
    this.data = accountService.displayedAccountData;
    this.preferences = settings.getPreferences(this.data.accountId);

    this._selectedSectionIds = [];
    this._setSelectionSectionsDebounced = debounce(() => this.updateSettings(), 500, {
      leading: false,
      trailing: true
    });

    when(
      () => this.preferences.hasData,
      () => (this._selectedSectionIds = this.initialSelectedSectionIds)
    );
  }

  protected abstract get initialSelectedSectionIds(): string[];

  @computed
  private get sections(): SectionModel[] {
    const allSections = this.data.userSections;
    return this._onlyShowTaughtSections
      ? allSections.filter((s) => s.teacherIds.includes(this.data.accountId))
      : allSections;
  }

  @computed
  get items(): SectionFilterItemViewModel[] {
    const sections = this.sections.map((section) => {
      const isSelected = this._selectedSectionIds.length === 0 || this._selectedSectionIds.includes(section.id);

      return new AppSectionFilterItemViewModel(
        section,
        this.data.account,
        () => this.onSelectSection(section.id),
        isSelected
      );
    });

    const locale = this._localizationService.currentLocale;

    return sections.sort((s1, s2) => {
      if (s1.sectionTitle !== s2.sectionTitle) {
        return s1.sectionTitle.localeCompare(s2.sectionTitle, locale, { sensitivity: 'base' });
      } else {
        return s1.sectionNumber.localeCompare(s2.sectionNumber, locale, { sensitivity: 'base' });
      }
    });
  }

  get isFiltered(): boolean {
    const selectedSectionCount = this._selectedSectionIds.length;
    return selectedSectionCount !== 0 && selectedSectionCount !== this.data.userSections.length;
  }

  dismiss() {
    this._onSuccess();
  }

  abstract reset(): void;

  protected abstract updateSettings: () => void;

  @action
  private onSelectSection(sectionId: string) {
    if (this._selectedSectionIds.length === 0) {
      this._selectedSectionIds = this.data.userSections
        .filter((section) => section.id != sectionId)
        .map((section) => section.id);
    } else {
      const sectionIndex = this._selectedSectionIds.indexOf(sectionId);

      if (sectionIndex !== -1) {
        if (this._selectedSectionIds.length > 1) {
          this._selectedSectionIds.splice(sectionIndex, 1);
        }
      } else {
        this._selectedSectionIds.push(sectionId);
      }
    }

    this._setSelectionSectionsDebounced();
  }
}
