import { ListItemButton, ListItemText, SxProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { ProfileMenuOptionViewModel } from '../../../viewmodels';
import { TintedListItemIcon } from '../../lists';

export interface ProfileMenuOptionRowProps {
  sx?: SxProps;
  className?: string;
  viewModel: ProfileMenuOptionViewModel;
}

export const ProfileMenuOptionRow = observer((props: ProfileMenuOptionRowProps) => {
  const { viewModel, sx = [], className } = props;
  const navigate = useNavigate();

  return (
    <ListItemButton sx={sx} className={className} onClick={() => viewModel.action(navigate)}>
      <TintedListItemIcon icon={viewModel.icon} isTextPrimaryColor />
      <ListItemText primary={viewModel.title} />
    </ListItemButton>
  );
});
