import { SxProps, Typography } from '@mui/material';
import { isSxArray } from '@shared/components/utils';
import { observer } from 'mobx-react-lite';
import { YearMonthViewModel } from '../../../viewmodels';

export interface YearMonthTitleProps {
  sx?: SxProps;
  className?: string;
  month: YearMonthViewModel;
}

export const YearMonthTitle = observer((props: YearMonthTitleProps) => {
  const { sx = [], className, month } = props;

  return (
    <Typography
      className={className}
      variant="h6"
      sx={[
        {
          fontWeight: '500'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      {month.monthTitle}
    </Typography>
  );
});
