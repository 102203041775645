import { Button, IconButton, SxProps, useMediaQuery, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';

export type DialogHeaderButtonOptionsColorKind =
  | 'inherit'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'error'
  | 'info'
  | 'warning';

export interface DialogHeaderButtonOptions {
  icon?: (size: 'inherit' | 'small' | 'medium' | 'large') => ReactNode;
  title?: string;
  action: () => void;
  color?: DialogHeaderButtonOptionsColorKind;
  onlyShowOnExtraSmallScreen?: boolean;
  disabled?: boolean;
}

export interface DialogHeaderButtonProps {
  sx?: SxProps;
  className?: string;
  options: DialogHeaderButtonOptions;
  defaultColor?: string;
}

export const DialogHeaderButton = observer((props: DialogHeaderButtonProps) => {
  const { options, sx = [], className } = props;
  const { icon, title, color, disabled, action } = options;
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));

  if (title != null) {
    return (
      <Button
        size={isExtraSmallScreen ? 'medium' : 'large'}
        sx={sx}
        className={className}
        startIcon={icon != null ? icon('medium') : undefined}
        color={color ?? 'primary'}
        disabled={disabled}
        onClick={() => action()}
      >
        {title}
      </Button>
    );
  }

  if (icon != null) {
    return (
      <IconButton
        onClick={() => action()}
        size={isExtraSmallScreen ? 'medium' : 'large'}
        sx={sx}
        className={className}
        color={color}
        disabled={disabled}
      >
        {icon('medium')}
      </IconButton>
    );
  }

  return null;
});
