import { Box, Menu, MenuItem, SxProps, useTheme } from '@mui/material';
import { SectionColors } from '@shared/models/Colors';
import { Day } from '@shared/models/types';
import { DialogResult } from '@shared/services';
import { DialogActionButtons, DialogButton, ResponsiveDialog } from '@studyo/components';
import { DisplayableContentListView } from '@studyo/views';
import { observer } from 'mobx-react-lite';
import { useMemo, useRef, useState } from 'react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { DialogHeader } from '../../../components';

export interface DisplayableContentListScreenProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  day: Day;
  periodTag: string | undefined;
  sectionId: string | undefined;
  contentIds: string[];
}

export const DisplayableContentListScreen = observer((props: DisplayableContentListScreenProps) => {
  const { localizationService, viewModelFactory } = useStudyoServices();
  const { sx = [], className, contentIds, day, periodTag, sectionId, onSuccess, onCancel } = props;

  const viewModel = useMemo(
    () => viewModelFactory.createDisplayableContentList(day, periodTag, sectionId, contentIds, onSuccess!, onCancel!),
    [day, periodTag, sectionId]
  );

  const theme = useTheme();
  const strings = localizationService.localizedStrings.studyo.contents.lists;
  const [displayAddContentMenu, setDisplayAddContentMenu] = useState(false);
  const addContentButtonRef = useRef<HTMLDivElement | null>();

  const setAddContentMenuVisibility = (value: boolean) => {
    setDisplayAddContentMenu(value);
  };

  const addTaskPressed = () => {
    setAddContentMenuVisibility(false);
    void viewModel.addTask();
  };

  const editNotePressed = () => {
    setAddContentMenuVisibility(false);
    void viewModel.editNote();
  };

  const pasteTaskPressed = () => {
    setAddContentMenuVisibility(false);
    void viewModel.paste();
  };

  const tintColor =
    viewModel.sectionColor != null ? SectionColors.get(viewModel.sectionColor) : theme.studyo.periods.freePeriodColor;
  const textColor =
    viewModel.sectionColor != null
      ? theme.studyo.periods.periodWithSectionTextColor
      : theme.studyo.periods.freePeriodTextColor;

  return (
    <ResponsiveDialog
      sx={sx}
      className={className}
      maxWidth="sm"
      fullWidth={true}
      open={true}
      maxHeight="large"
      onClose={() => viewModel.close()}
    >
      <DialogHeader
        onClose={() => viewModel.close()}
        title={viewModel.sectionTitle ?? localizationService.localizedStrings.studyo.contents.lists.allDayTasksTitle}
        tintColor={textColor}
        color={tintColor}
      />
      <DisplayableContentListView sx={{ flex: 1 }} viewModel={viewModel} />
      <DialogActionButtons>
        {viewModel.canEdit && (
          <div ref={(ref) => (addContentButtonRef.current = ref)}>
            <DialogButton
              title={strings.editContents}
              type="normal"
              onPress={() => setAddContentMenuVisibility(true)}
            />
          </div>
        )}

        <Box
          sx={{
            flex: 1
          }}
        />

        <DialogButton title={strings.close} type="normal" onPress={() => viewModel.close()} minimumScreenSize="sm" />
      </DialogActionButtons>
      <Menu
        id="render-props-menu"
        anchorEl={addContentButtonRef.current}
        open={displayAddContentMenu}
        onClose={() => setAddContentMenuVisibility(false)}
        disableAutoFocusItem={true}
        slotProps={{ paper: { sx: { width: 200 } } }}
      >
        <MenuItem onClick={() => addTaskPressed()}>{strings.addTask}</MenuItem>
        <MenuItem onClick={() => editNotePressed()}>{strings.editNote}</MenuItem>
        {viewModel.canPaste && <MenuItem onClick={() => pasteTaskPressed()}>{strings.pasteTask}</MenuItem>}
      </Menu>
    </ResponsiveDialog>
  );
});
