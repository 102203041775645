import { SxProps } from '@mui/material';
import { Day } from '@shared/models/types';
import { DialogResult } from '@shared/services';
import { DialogActionButtons, DialogButton, ResponsiveDialog } from '@studyo/components';
import { PeriodPrioritySelectorView } from '@studyo/views';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { DialogHeader } from '../../../components';

export interface PeriodPrioritySelectorScreenProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  periodTag: string;
  day: Day;
}

export const PeriodPrioritySelectorScreen = observer((props: PeriodPrioritySelectorScreenProps) => {
  const { localizationService, viewModelFactory } = useStudyoServices();
  const { day, periodTag, onCancel, onSuccess } = props;

  const viewModel = useMemo(
    () => viewModelFactory.createPeriodPrioritySelector(periodTag, day, onSuccess!, onCancel!),
    [periodTag, day]
  );

  const strings = localizationService.localizedStrings.studyo.utils.periodPrioritySelector;

  const close = () => {
    viewModel.close();
  };

  return (
    <ResponsiveDialog maxWidth="xs" fullWidth={true} open={true} maxHeight="medium" onClose={close}>
      <DialogHeader title={strings.title} onClose={close} />

      <PeriodPrioritySelectorView sx={{ flex: 1 }} viewModel={viewModel} />

      <DialogActionButtons minimumScreenSize="sm">
        <DialogButton type="normal" title={strings.close} onPress={close} />
      </DialogActionButtons>
    </ResponsiveDialog>
  );
});
