import { css } from '@emotion/css';
import { Box, Stack, SxProps, useTheme } from '@mui/material';
import { isSxArray } from '@shared/components/utils';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { TaskDueBoxViewModel } from '../../../viewmodels';
import { TaskDateIconBox, TaskDateIconBoxType } from './TaskDateIconBox';
import { TaskDuePeriodBox } from './TaskDuePeriodBox';
import { TaskDueChildHeight } from './TaskDueStyles';

export interface TaskDueBoxProps {
  sx?: SxProps;
  className?: string;
  viewModel: TaskDueBoxViewModel;
  componentWidth: number;
  selectedType?: TaskDateIconBoxType;
  onAssignmentDatePress?: () => void;
  onPlannedDatePress?: () => void;
  onDueDatePress?: () => void;
  onDuePeriodTagPress?: () => void;
}

export const TaskDueBox = observer((props: TaskDueBoxProps) => {
  const { localizationService } = useStudyoServices();
  const {
    sx = [],
    className,
    onAssignmentDatePress,
    onPlannedDatePress,
    onDueDatePress,
    onDuePeriodTagPress,
    viewModel,
    componentWidth,
    selectedType
  } = props;
  const theme = useTheme();

  const dueSubLineColor = useMemo(() => {
    switch (viewModel.dueBoxTaskState) {
      case 'active':
        return theme.studyo.contents.states.active;

      case 'late':
        return theme.studyo.contents.states.late;

      case 'today':
        return theme.studyo.contents.states.today;

      case 'none':
        return theme.studyo.contents.taskDueBox.dueLineColor;
    }
  }, [viewModel.dueBoxTaskState, theme]);

  const dateIconBoxClassName = css({
    flexGrow: 1,
    flexBasis: 0,

    zIndex: 3
  });

  const announcedLineHeight = 2;
  const singleElementWidth = componentWidth / (viewModel.displayPlannedDay ? 4 : 3);
  const announcedLineLeft = singleElementWidth * 0.53;
  const announcedLineTop = TaskDueChildHeight / 2 - announcedLineHeight / 2;
  const dueLineHeight = 20;
  const dueLineTop = TaskDueChildHeight / 2 - dueLineHeight / 2;
  const dueLineLeft = viewModel.displayPlannedDay ? singleElementWidth * 1.5 : singleElementWidth * 0.5;

  const announcedLineClassName = css({
    position: 'absolute',
    top: announcedLineTop,
    left: announcedLineLeft,
    height: announcedLineHeight,
    width: singleElementWidth,
    backgroundColor: theme.studyo.contents.icons.assignmentColor,
    zIndex: 2
  });

  const dueLineClassName = css({
    height: dueLineHeight - 2,
    width: singleElementWidth,
    position: 'absolute',
    top: dueLineTop,
    left: dueLineLeft,
    backgroundColor: theme.studyo.contents.taskDueBox.dueLineColor,
    zIndex: 2
  });
  const dueSubLineClassName = css({
    height: dueLineHeight,
    width: singleElementWidth,
    position: 'absolute',
    top: dueLineTop,
    left: dueLineLeft,
    backgroundColor: dueSubLineColor,
    zIndex: 1
  });

  return (
    <Stack
      direction="row"
      className={className}
      sx={[
        {
          position: 'relative'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <TaskDateIconBox
        className={dateIconBoxClassName}
        title={localizationService.localizedStrings.studyo.contents.taskDueBox.announced}
        icon={viewModel.icon}
        day={viewModel.assignmentDay}
        type="announced"
        state={viewModel.state}
        taskIconPublishKind={viewModel.taskIconPublishKind}
        externalSource={viewModel.externalSource}
        onPress={viewModel.canEditDates ? onAssignmentDatePress : undefined}
        isSelected={selectedType === 'announced'}
        workloadLevel={viewModel.workloadLevel}
        isPrivate={viewModel.isPrivate}
        hasSteps={viewModel.hasSteps}
      />
      {viewModel.displayPlannedDay && (
        <TaskDateIconBox
          className={dateIconBoxClassName}
          title={localizationService.localizedStrings.studyo.contents.taskDueBox.planned}
          icon={viewModel.icon}
          day={viewModel.plannedDay}
          type="planned"
          state={viewModel.state}
          onPress={onPlannedDatePress}
          isSelected={selectedType === 'planned'}
          workloadLevel={viewModel.workloadLevel}
          taskIconPublishKind={viewModel.taskIconPublishKind}
          externalSource={viewModel.externalSource}
          isPrivate={viewModel.isPrivate}
          hasSteps={viewModel.hasSteps}
        />
      )}
      <TaskDateIconBox
        className={dateIconBoxClassName}
        title={localizationService.localizedStrings.studyo.contents.taskDueBox.due}
        icon={viewModel.icon}
        day={viewModel.dueDay}
        type="due"
        state={viewModel.state}
        onPress={viewModel.canEditDates ? onDueDatePress : undefined}
        isSelected={selectedType === 'due'}
        workloadLevel={viewModel.workloadLevel}
        taskIconPublishKind={viewModel.taskIconPublishKind}
        externalSource={viewModel.externalSource}
        isPrivate={viewModel.isPrivate}
        hasSteps={viewModel.hasSteps}
      />
      <TaskDuePeriodBox
        sx={{ flexGrow: 1, flexBasis: 0 }}
        periodNumber={viewModel.duePeriodTag}
        onPress={viewModel.canEditDates ? onDuePeriodTagPress : undefined}
      />
      {viewModel.displayPlannedDay && <Box className={announcedLineClassName} />}
      <Box className={dueLineClassName} />
      <Box className={dueSubLineClassName} />
    </Stack>
  );
});
