import { Capacitor } from '@capacitor/core';
import LinkIcon from '@mui/icons-material/Link';
import { alpha, CircularProgress, Popover, Stack, SxProps, TextField, Typography, useTheme } from '@mui/material';
import {
  ContentAttachmentAddTypeOption,
  DialogActionButtons,
  DialogButton,
  DialogHeader,
  FileDropzone
} from '@studyo/components';
import { ContentAttachmentAddViewModel } from '@studyo/viewmodels';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { useStudyoServices } from '../../UseStudyoServicesHook';

export interface ContentAttachmentAddViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: ContentAttachmentAddViewModel;
}

export const ContentAttachmentAddView = observer((props: ContentAttachmentAddViewProps) => {
  const { imageService, localizationService } = useStudyoServices();
  const { viewModel, sx = [], className } = props;
  const theme = useTheme();
  const strings = localizationService.localizedStrings.studyo.contents.attachments.add;

  useEffect(() => {
    viewModel.onInit();
    return () => viewModel.onDestroy();
  }, [viewModel]);

  const fileInputRef = useRef<HTMLInputElement | null>();
  const urlButtonRef = useRef<HTMLDivElement | null>();
  const fileReader = useRef(new FileReader());

  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [displayURLDialog, setDisplayURLDialog] = useState(false);
  const [isCreatingAttachment, setIsCreatingAttachment] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);

  const localFilename = useRef<string | undefined>(undefined);

  //
  // File import
  //
  fileReader.current.onload = () => {
    void createFileAttachment(fileReader.current.result);
  };

  fileReader.current.onloadstart = () => {
    setIsLoadingFile(true);
  };

  fileReader.current.onloadend = () => {
    setIsLoadingFile(false);
  };

  const openFileInput = () => {
    if (fileInputRef.current != null) {
      fileInputRef.current.click();
    }
  };

  const onDropFile = (file: File) => {
    readFile(file);
  };

  const onImportFiles = (files: FileList | null) => {
    if (files != null) {
      const file = files[0];
      readFile(file);
    }
  };

  const readFile = (file: File) => {
    localFilename.current = file.name;
    fileReader.current.readAsDataURL(file);
  };

  const createFileAttachment = async (data: unknown) => {
    if (localFilename.current != null) {
      try {
        runInAction(() => {
          setIsCreatingAttachment(true);
          setError(undefined);
        });
        await viewModel.createFileAttachment(localFilename.current, data);
        viewModel.close(true);
      } catch (e) {
        console.warn(e);
        setError(e as Error);
      } finally {
        localFilename.current = undefined;
        setIsCreatingAttachment(false);
      }
    }
  };

  //
  // URL
  //

  const onURLPressed = () => {
    setDisplayURLDialog(true);
  };

  const dismissURLDialog = () => {
    setDisplayURLDialog(false);
    viewModel.url = '';
  };

  const addURL = async () => {
    if (viewModel.url.length === 0) {
      setDisplayURLDialog(false);
      return;
    }

    try {
      runInAction(() => {
        setIsCreatingAttachment(true);
        setDisplayURLDialog(false);
        setError(undefined);
      });
      await viewModel.createURLAttachment();
      viewModel.close(true);
    } catch (e) {
      console.warn(e);
      setError(e as Error);
    } finally {
      setIsCreatingAttachment(false);
    }
  };

  //
  // Google Drive
  //

  const openGoogleDrivePickerForLink = async () => {
    try {
      runInAction(() => {
        setIsCreatingAttachment(true);
        setError(undefined);
      });
      await viewModel.createGoogleDriveLinkAttachment();
      viewModel.close(true);
    } catch (e) {
      console.error(e);
      setError(e as Error);
    } finally {
      localFilename.current = undefined;
      setIsCreatingAttachment(false);
    }
  };

  const isCapacitor = Capacitor.isNativePlatform();

  return (
    <Stack sx={sx} className={className}>
      {(isLoadingFile || isCreatingAttachment) && (
        <Stack
          sx={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <CircularProgress />
        </Stack>
      )}
      {!isLoadingFile && !isCreatingAttachment && (
        <FileDropzone
          sx={{ flex: 1 }}
          onDragColor={alpha(theme.palette.primary.main, 0.2)}
          acceptMultipleFiles={false}
          onFileDrop={onDropFile}
          supportClick={false}
        >
          <Stack
            spacing={3}
            sx={{
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Typography
              sx={{
                textAlign: 'center',
                fontStyle: 'italic'
              }}
            >
              {isCapacitor ? strings.capacitorSubtitle : strings.subtitle}
            </Typography>

            <Stack
              direction="row"
              spacing={2}
              sx={{
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <input
                type="file"
                id="file"
                ref={(ref) => (fileInputRef.current = ref)}
                style={{ display: 'none' }}
                onChange={(e) => onImportFiles(e.target.files)}
              />

              <ContentAttachmentAddTypeOption
                icon={{ case: 'named', value: imageService.studyoImages.tasks.addAttachments.importPicture }}
                onPress={openFileInput}
                title={strings.chooseFromDevice}
              />

              <div ref={(ref) => (urlButtonRef.current = ref)} style={{ display: 'flex' }}>
                <ContentAttachmentAddTypeOption
                  icon={{ case: 'component', value: (size) => <LinkIcon sx={{ width: size, height: size }} /> }}
                  onPress={onURLPressed}
                  title={strings.chooseURL}
                />
              </div>

              {!isCapacitor && (
                <ContentAttachmentAddTypeOption
                  icon={{ case: 'named', value: imageService.studyoImages.tasks.addAttachments.googleDrive }}
                  onPress={() => void openGoogleDrivePickerForLink()}
                  title={strings.chooseGoogleDriveLink}
                />
              )}
            </Stack>

            {/* Keeping this in case we have to disable Google Drive again for some reason. */}
            {/*<Stack>*/}
            {/*  <Typography variant="body2">{strings.disabledGoogleDrivePickerLine1}</Typography>*/}
            {/*  <Typography variant="body2">{strings.disabledGoogleDrivePickerLine2}</Typography>*/}
            {/*</Stack>*/}

            {error != null && (
              <Typography
                variant="subtitle2"
                sx={{
                  color: (theme) => theme.palette.error.main
                }}
              >
                {strings.errorMessage}
              </Typography>
            )}
          </Stack>
        </FileDropzone>
      )}
      <Popover
        open={displayURLDialog}
        anchorEl={urlButtonRef.current}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        onClose={dismissURLDialog}
        slotProps={{ paper: { sx: { width: 500 } } }}
      >
        <Stack sx={{ width: '100%' }}>
          <DialogHeader title={strings.addURLTitle} />

          <Stack
            sx={{
              p: 2
            }}
          >
            <TextField
              fullWidth
              value={viewModel.url}
              onChange={(e) => (viewModel.url = e.currentTarget.value)}
              label={strings.addURLPlaceholder}
              variant="filled"
              type="url"
              autoFocus
              autoCapitalize="none"
              onSubmit={() => void addURL()}
            />
          </Stack>

          <DialogActionButtons>
            <DialogButton title={strings.cancelURL} type="cancel" onPress={dismissURLDialog} />

            <DialogButton
              title={strings.addURL}
              type="normal"
              variant="contained"
              onPress={() => void addURL()}
              disabled={viewModel.url.length === 0}
            />
          </DialogActionButtons>
        </Stack>
      </Popover>
    </Stack>
  );
});
