import { SxProps } from '@mui/material';
import { Day } from '@shared/models/types';
import { DialogResult } from '@shared/services';
import { DialogActionButtons, DialogButton, ResponsiveDialog } from '@studyo/components';
import { SchoolDayContentListView } from '@studyo/views';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { DialogHeader } from '../../../components';

export interface SchoolDayContentListScreenProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  day: Day;
}

export const SchoolDayContentListScreen = observer((props: SchoolDayContentListScreenProps) => {
  const { localizationService, viewModelFactory } = useStudyoServices();
  const { sx = [], className, day, onSuccess, onCancel } = props;
  const viewModel = useMemo(() => viewModelFactory.createSchoolDayContentList(day, onSuccess!, onCancel!), [day]);
  const strings = localizationService.localizedStrings.studyo.contents.lists;

  return (
    <ResponsiveDialog
      sx={sx}
      className={className}
      maxWidth="sm"
      fullWidth={true}
      open={true}
      maxHeight="large"
      onClose={() => viewModel.close()}
    >
      <DialogHeader
        onClose={() => viewModel.close()}
        title={viewModel.day.formattedString(localizationService.localizedStrings.models.dateFormats.mediumUnabridged)}
      />

      <SchoolDayContentListView sx={{ flex: 1 }} viewModel={viewModel} />

      <DialogActionButtons minimumScreenSize="sm">
        <DialogButton title={strings.close} type="normal" onPress={() => viewModel.close()} />
      </DialogActionButtons>
    </ResponsiveDialog>
  );
});
