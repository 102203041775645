import { ListItemButton, ListItemText, SxProps, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { AvatarView } from '../AvatarView';

export interface OtherProfilesRowProps {
  sx?: SxProps;
  className?: string;
  onPress: () => void;
}

export const OtherProfilesRow = observer((props: OtherProfilesRowProps) => {
  const { localizationService } = useStudyoServices();
  const { sx = [], className, onPress } = props;
  const theme = useTheme();

  return (
    <ListItemButton sx={sx} className={className} onClick={() => onPress()}>
      <AvatarView
        sx={{ ml: 2, mr: 2 }}
        firstName="⋯"
        lastName=""
        textColor={theme.palette.text.secondary}
        isImpersonating={false}
        size={40}
      />
      <ListItemText primary={localizationService.localizedStrings.studyo.agenda.setting.profileMenu.otherProfiles} />
    </ListItemButton>
  );
});
