import { Stack, SxProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CalendarHeaderActionButton, CalendarHeaderActionButtonOptions } from './CalendarHeaderActionButton';

export interface CalendarHeaderActionButtonsProps {
  sx?: SxProps;
  className?: string;
  iconSize?: number;
  options: CalendarHeaderActionButtonOptions[];
}

export const CalendarHeaderActionButtons = observer((props: CalendarHeaderActionButtonsProps) => {
  const { options, iconSize, sx = [], className } = props;
  const imageSize = iconSize ?? 22;

  return (
    <Stack sx={sx} className={className} direction="row" spacing={1}>
      {options.map((option) => (
        <CalendarHeaderActionButton
          key={`CalendarHeaderActionButton-${option.kind}`}
          options={option}
          iconSize={imageSize}
        />
      ))}
    </Stack>
  );
});
