import { SxProps } from '@mui/material';
import { AccountModel, SectionModel } from '@shared/models/config';
import { DialogResult } from '@shared/services';
import { useStudyoServices } from '@studyo/UseStudyoServicesHook';
import { DialogActionButtons, DialogButton, DialogHeader, ResponsiveDialog } from '@studyo/components';
import { WorkloadInfo } from '@studyo/viewmodels';
import { WorkloadImpactDetailsView } from '@studyo/views';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';

export interface WorkloadImpactDetailsScreenProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  workloadInfos: WorkloadInfo[];
  sectionsById: Map<string, SectionModel>;
  accountsById: Map<string, AccountModel>;
  isInformativeOnly: boolean;
}

export const WorkloadImpactDetailsScreen = observer(
  ({
    sx = [],
    className,
    workloadInfos,
    sectionsById,
    accountsById,
    isInformativeOnly,
    onSuccess,
    onCancel
  }: WorkloadImpactDetailsScreenProps) => {
    const { localizationService, viewModelFactory } = useStudyoServices();
    const strings = localizationService.localizedStrings.studyo.contents.workload;

    const viewModel = useMemo(
      () =>
        viewModelFactory.createWorkloadImpactDetails(workloadInfos, sectionsById, accountsById, onSuccess!, onCancel!),
      [workloadInfos, sectionsById]
    );

    return (
      <ResponsiveDialog
        sx={sx}
        className={className}
        maxWidth="sm"
        fullWidth
        open
        maxHeight="large"
        onClose={() => viewModel.close()}
        disableEnforceFocus
      >
        <DialogHeader onClose={() => viewModel.close()} title={strings.title} />

        <WorkloadImpactDetailsView sx={{ flex: 1 }} viewModel={viewModel} />

        <DialogActionButtons>
          <DialogButton
            title={isInformativeOnly ? strings.closeButton : strings.cancelButton}
            type="cancel"
            onPress={() => viewModel.close()}
          />
          {!isInformativeOnly && (
            <DialogButton title={strings.proceedButton} type="success" onPress={() => viewModel.proceed()} />
          )}
        </DialogActionButtons>
      </ResponsiveDialog>
    );
  }
);
