import { Box, Button, Stack, SxProps, Typography, useMediaQuery, useTheme } from '@mui/material';

import { isSxArray } from '@shared/components/utils';
import { OnboardingBackgroundImage, OnboardingContentBackgroundView } from '@studyo/components';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { useStudyoServices } from '../UseStudyoServicesHook';

export interface NotFoundScreenProps {
  sx?: SxProps;
  className?: string;
}

export const NotFoundScreen = observer((props: NotFoundScreenProps) => {
  const { localizationService } = useStudyoServices();
  const { sx = [], className } = props;

  const strings = localizationService.localizedStrings.studyo.notFound;
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));
  const navigate = useNavigate();

  const goToHomepage = () => {
    navigate('/');
  };

  return (
    <Stack
      className={className}
      sx={[
        {
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <OnboardingBackgroundImage />
      <div className="login-container">
        <Stack
          sx={{
            flex: 1,
            position: 'relative'
          }}
        >
          <OnboardingContentBackgroundView sx={{ zIndex: 1 }} />

          <Stack
            spacing={2}
            sx={{
              flex: 1,
              zIndex: 2,
              alignItems: { xs: 'stretch', sm: 'center' },
              p: { xs: 2, sm: 3 },
              overflowY: 'auto',
              overflowX: 'hidden'
            }}
          >
            <Stack spacing={1}>
              <Typography
                variant="h5"
                sx={{
                  textAlign: 'center'
                }}
              >
                {strings.title}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  textAlign: 'center'
                }}
              >
                {strings.subtitle}
              </Typography>
            </Stack>

            <Box
              sx={{
                height: '1px',
                backgroundColor: theme.studyo.onboarding.separatorLineColor,
                width: !isExtraSmallScreen ? 300 : undefined
              }}
            />

            <Button variant="contained" sx={{ minWidth: !isExtraSmallScreen ? 350 : undefined }} onClick={goToHomepage}>
              {strings.goHomeButtonTitle}
            </Button>
          </Stack>
        </Stack>
      </div>
    </Stack>
  );
});
