import { DialogContent, SxProps, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { DialogActionButtons } from './DialogActionButtons.tsx';
import { DialogButton } from './DialogButton.tsx';
import { DialogHeader } from './DialogHeader.tsx';
import { ResponsiveDialog } from './ResponsiveDialog.tsx';

export interface ConfirmDialogProps {
  sx?: SxProps;
  className?: string;
  open: boolean;
  title: string;
  description: string;
  cancelTitle?: string;
  onCancelPress?: () => void;
  confirmTitle: string;
  onConfirmPress: () => void;
  onClose: () => void;
}

export const ConfirmDialog = observer((props: ConfirmDialogProps) => {
  const {
    open,
    onClose,
    title,
    description,
    cancelTitle,
    confirmTitle,
    onConfirmPress,
    onCancelPress,
    sx = [],
    className
  } = props;

  const didClickOnCancel = () => {
    onCancelPress?.();
  };

  const didClickOnConfirm = () => {
    onConfirmPress();
  };

  return (
    <ResponsiveDialog
      sx={sx}
      className={className}
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth={true}
      supportsFullScreen={false}
    >
      <DialogHeader title={title} />

      <DialogContent>
        <Typography>{description}</Typography>
      </DialogContent>

      <DialogActionButtons backgroundColor="transparent">
        {cancelTitle != null && (
          <DialogButton type="cancel" title={cancelTitle} onPress={didClickOnCancel} sx={{ minWidth: 75 }} />
        )}

        <DialogButton
          title={confirmTitle}
          type="normal"
          variant="contained"
          onPress={didClickOnConfirm}
          sx={{ minWidth: 75 }}
        />
      </DialogActionButtons>
    </ResponsiveDialog>
  );
});
