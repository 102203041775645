import { List, SxProps } from '@mui/material';
import { SectionModel } from '@shared/models/config';
import { observer } from 'mobx-react-lite';
import { PeriodsSectionListViewModel } from '../../../viewmodels';
import { PeriodsSectionListItem } from './PeriodsSectionListItem';

export interface PeriodsSectionListProps {
  sx?: SxProps;
  className?: string;
  selectedSection: SectionModel | undefined;
  viewModel: PeriodsSectionListViewModel;
}

export const PeriodsSectionList = observer((props: PeriodsSectionListProps) => {
  const { selectedSection, sx = [], className, viewModel } = props;
  const selectedSectionId = selectedSection?.id ?? '';

  return (
    <List sx={{ ...sx, overflow: 'auto' }} className={className} disablePadding>
      {viewModel.sections.map((s) => (
        <PeriodsSectionListItem
          key={`periods-section-list-item-${s.sectionId}`}
          isSelected={selectedSectionId === s.sectionId}
          viewModel={s}
        />
      ))}
    </List>
  );
});
