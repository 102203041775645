import { Check } from '@mui/icons-material';
import { Box, Divider, List, ListItemButton, ListItemText, SxProps, useTheme } from '@mui/material';
import { SectionColors } from '@shared/models/Colors.ts';
import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';
import { ListSection } from '../../components';
import { ContentDistributeViewModel } from '../../viewmodels';

export interface ContentDistributeViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: ContentDistributeViewModel;
}

export const ContentDistributeView = observer((props: ContentDistributeViewProps) => {
  const { viewModel, sx = [], className } = props;
  const theme = useTheme();

  return (
    <List sx={{ ...sx, overflow: 'auto' }} className={className} dense>
      <ListSection>
        {viewModel.elements.map((element, index) => (
          <Fragment key={`content-distribute-item-${index}`}>
            <ListItemButton
              onClick={() => (element.isSelected = !element.isSelected)}
              disabled={element.isContentSection}
              disableGutters
              sx={{ p: 0 }}
            >
              <Box
                sx={{
                  height: 60,
                  width: theme.spacing(3),
                  backgroundColor: SectionColors.get(element.color),
                  mr: 2
                }}
              />

              <ListItemText primary={element.sectionTitle} secondary={element.groupNumber} />

              {element.isSelected && <Check color="primary" sx={{ mr: 2 }} />}
            </ListItemButton>

            {index < viewModel.elements.length - 1 && <Divider />}
          </Fragment>
        ))}
      </ListSection>
    </List>
  );
});
