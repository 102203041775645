import { Box, SxProps, Typography, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useStudyoServices } from '../../../UseStudyoServicesHook';

export type PeriodCellDragOverType = 'move' | 'copy';

export interface PeriodCellDragOverIndicatorProps {
  sx?: SxProps;
  className?: string;
  type: PeriodCellDragOverType;
}

export const PeriodCellDragOverIndicator = observer((props: PeriodCellDragOverIndicatorProps) => {
  const { localizationService } = useStudyoServices();
  const { type, sx = [], className } = props;
  const theme = useTheme();

  const strings = localizationService.localizedStrings.studyo.agenda.planner;
  const text = type === 'copy' ? strings.copyContent : strings.moveContent;

  return (
    <Box
      sx={{
        ...sx,
        backgroundColor: theme.studyo.agenda.planner.dragOverIndicatorColor,
        px: 1,
        py: 0.5,
        pointerEvents: 'none'
      }}
      className={className}
    >
      <Typography sx={{ userSelect: 'none' }} variant="subtitle2">
        {text}
      </Typography>
    </Box>
  );
});
