import { Logout } from '@mui/icons-material';
import { SxProps, useMediaQuery, useTheme } from '@mui/material';
import { DialogResult } from '@shared/services';
import { DialogActionButtons, DialogButton, ResponsiveDialog } from '@studyo/components';
import { ProfileMenuView } from '@studyo/views';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { DialogHeader } from '../../../components';

export interface ProfileMenuScreenProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
}

export const ProfileMenuScreen = observer((props: ProfileMenuScreenProps) => {
  const { localizationService, viewModelFactory } = useStudyoServices();
  const { sx = [], className, onCancel, onSuccess } = props;
  const viewModel = useMemo(() => viewModelFactory.createProfileMenu(onSuccess!, onCancel!), []);
  const strings = localizationService.localizedStrings.studyo.agenda.setting.profileMenu;
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));
  const location = useLocation();
  const navigate = useNavigate();

  const close = () => {
    viewModel.close();
  };

  const logout = () => {
    void viewModel.logout(location, navigate);
  };

  return (
    <ResponsiveDialog
      sx={sx}
      className={className}
      maxWidth="sm"
      fullWidth={true}
      open={true}
      maxHeight="large"
      onClose={close}
    >
      <DialogHeader
        onClose={close}
        title={viewModel.userEmail}
        rightButton={{
          action: logout,
          color: 'error',
          title: !isExtraSmallScreen ? strings.logout : undefined,
          icon: isExtraSmallScreen ? (size) => <Logout fontSize={size} /> : undefined
        }}
      />

      <ProfileMenuView sx={{ flex: 1 }} viewModel={viewModel} />

      <DialogActionButtons minimumScreenSize="sm">
        <DialogButton title={strings.close} type="normal" onPress={close} />
      </DialogActionButtons>
    </ResponsiveDialog>
  );
});
