import { EmailOutlined } from '@mui/icons-material';
import { Box, Button, CardActionArea, Stack, Typography, useTheme } from '@mui/material';
import { isSxArray, withoutPropagation } from '@shared/components/utils';
import { observer } from 'mobx-react-lite';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { OptionsDialog } from '../../utils';
import { DayAndWeekPeriodInfoBoxProps } from './DayAndWeekPeriodInfoBox';

export const RegularDayAndWeekPeriodInfoBox = observer((props: DayAndWeekPeriodInfoBoxProps) => {
  const { localizationService } = useStudyoServices();
  const { height, backgroundColor, borderColor, sx = [], className, isCompact, viewModel } = props;
  const theme = useTheme();
  const strings = localizationService.localizedStrings.studyo.agenda.daily;

  let sectionDisplayTitle = '';
  if (viewModel.sectionTitle.length > 0) {
    sectionDisplayTitle = isCompact ? viewModel.sectionShortTitle : viewModel.sectionTitle;
  }

  const textColor =
    viewModel.hasSection && !viewModel.isSkipped
      ? theme.studyo.periods.periodWithSectionTextColor
      : theme.studyo.periods.freePeriodTextColor;

  const startTime = viewModel.periodStartTime.formattedString(
    localizationService.localizedStrings.models.timeFormats.singleDigit
  );
  const endTime = viewModel.periodEndTime.formattedString(
    localizationService.localizedStrings.models.timeFormats.singleDigit
  );

  const marginAndPaddingSpace = 2 + theme.studyo.baseThicknessValue;
  const normalLineHeight = 16 * 1.2;
  const smallLineHeight = 14 * 1.2;

  const threeLineSpace = normalLineHeight * 2 + smallLineHeight + marginAndPaddingSpace;
  const twoLineSpace = normalLineHeight + smallLineHeight + marginAndPaddingSpace;
  const oneLineSpace = normalLineHeight + marginAndPaddingSpace;
  const smallOneLineSpace = smallLineHeight + marginAndPaddingSpace;

  const showTeacherName = height > threeLineSpace && viewModel.teacherName.length > 0;

  return (
    <Stack
      className={className}
      sx={[
        {
          overflow: 'hidden'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <CardActionArea
        onClick={() => viewModel.openNoteEdit()}
        sx={{
          flex: 1,
          p: 0.5,
          justifyContent: height <= twoLineSpace ? 'center' : undefined,
          display: 'flex',
          flexDirection: 'column',
          backgroundColor,
          width: 250,
          color: textColor,
          overflow: 'hidden',
          borderWidth: 1,
          borderColor,
          borderStyle: 'solid',
          borderBottomLeftRadius: 4,
          borderTopLeftRadius: 4
        }}
        component="div"
      >
        <Stack
          spacing="2px"
          sx={{
            width: '100%',
            height: '100%',
            overflow: 'hidden'
          }}
        >
          {height >= smallOneLineSpace && (
            <Stack
              direction="row"
              spacing={1}
              sx={{
                alignItems: height > twoLineSpace ? 'baseline' : 'center'
              }}
            >
              <Typography
                variant={height < oneLineSpace ? 'body2' : 'body1'}
                noWrap
                sx={{
                  flex: 1,
                  fontWeight: '600',
                  color: 'inherit',
                  lineHeight: `${normalLineHeight}px`
                }}
              >
                {sectionDisplayTitle}
              </Typography>

              <Typography
                variant="subtitle2"
                noWrap
                sx={{
                  color: 'inherit',
                  lineHeight: `${smallLineHeight}px`
                }}
              >{`${startTime} - ${endTime}`}</Typography>
            </Stack>
          )}

          {height >= threeLineSpace && (
            <Stack
              direction="row"
              spacing={1}
              sx={{
                alignItems: 'baseline'
              }}
            >
              <Typography
                variant="body2"
                noWrap
                sx={{
                  flex: 1,
                  color: 'inherit',
                  lineHeight: `${smallLineHeight}px`
                }}
              >
                {viewModel.sectionNumber}
              </Typography>

              <Typography
                variant="body2"
                noWrap
                sx={{
                  color: 'inherit',
                  lineHeight: `${smallLineHeight}px`
                }}
              >
                {viewModel.courseOrdinal}
              </Typography>
            </Stack>
          )}

          {height >= twoLineSpace && (
            <Box
              sx={{
                flex: 1
              }}
            />
          )}

          {height >= twoLineSpace && (
            <Stack
              direction="row"
              sx={{
                alignItems: 'baseline'
              }}
            >
              {showTeacherName &&
                (viewModel.teacherHasEmail ? (
                  <Button
                    size="small"
                    color="inherit"
                    startIcon={<EmailOutlined />}
                    sx={{ height: normalLineHeight }}
                    onClick={withoutPropagation(() => viewModel.sendEmailToTeacher())}
                  >
                    {viewModel.teacherName}
                  </Button>
                ) : (
                  <Typography
                    variant="body2"
                    noWrap
                    sx={{
                      color: 'inherit',
                      lineHeight: `${smallLineHeight}px`
                    }}
                  >
                    {viewModel.teacherName}
                  </Typography>
                ))}

              <Box
                sx={{
                  flex: 1
                }}
              />

              <Typography
                variant="body2"
                noWrap
                sx={{
                  color: 'inherit',
                  lineHeight: `${smallLineHeight}px`,
                  flex: 1,
                  textAlign: 'end'
                }}
              >
                {viewModel.roomName}
              </Typography>
            </Stack>
          )}
        </Stack>
      </CardActionArea>
      {viewModel.showTeacherEmailSelection && (
        <OptionsDialog
          open={viewModel.showTeacherEmailSelection}
          title={strings.teacherEmailMenuTitle}
          description={strings.teacherEmailMenuMessage}
          onCancel={() => viewModel.hideTeacherSelection()}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onClose={() => {}}
          elements={viewModel.teacherSelectionElements}
        />
      )}
    </Stack>
  );
});
