import { css } from '@emotion/css';
import { Badge as MuiBadge } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties, ReactNode } from 'react';

export interface BadgeProps {
  badgeContent?: number;
  color?: 'primary' | 'secondary' | 'default' | 'error';
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
}

export const Badge = observer((props: BadgeProps) => {
  const { badgeContent, color, children, className, style } = props;

  const badgeClassName = css({ top: '2px !important' });
  return (
    <MuiBadge
      badgeContent={badgeContent}
      color={color}
      classes={{ badge: badgeClassName }}
      className={className}
      style={style}
    >
      {children}
    </MuiBadge>
  );
});
