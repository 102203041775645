import { Box, Stack, SxProps, Typography, useMediaQuery, useTheme } from '@mui/material';
import { PeriodOverlapIndicator } from '@shared/components/utils';
import { observer } from 'mobx-react-lite';
import { DayAndWeekPeriodViewModel } from '../../../viewmodels';
import { DayAndWeekPeriodContentBox } from './DayAndWeekPeriodContentBox';
import { DayAndWeekPeriodInfoBox } from './DayAndWeekPeriodInfoBox.tsx';
import { PeriodContentBoxDisplayType } from './PeriodContentBoxDisplayType';

const borderRadius = 4;

export interface DayAndWeekPeriodProps {
  sx?: SxProps;
  className?: string;
  viewModel: DayAndWeekPeriodViewModel;
  isCompact: boolean;
  isSmallPeriodTag: boolean;
  contentDisplayType: PeriodContentBoxDisplayType;
  height: number;
}

export const DayAndWeekPeriod = observer((props: DayAndWeekPeriodProps) => {
  const { sx = [], className, viewModel, height, isCompact, isSmallPeriodTag } = props;
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));

  const backgroundColor =
    viewModel.backgroundColor ??
    (viewModel.isSkipped ? theme.studyo.periods.skippedPeriodColor : theme.studyo.periods.freePeriodColor);
  const borderColor = viewModel.borderColor ?? theme.studyo.periods.freePeriodColor;

  const renderPeriodTag = () => {
    const tagColor = viewModel.backgroundColor ?? theme.studyo.periods.freePeriodColor;

    return (
      <Typography
        variant={isSmallPeriodTag ? 'body1' : 'h5'}
        sx={[
          {
            color: tagColor,
            fontWeight: '600',
            textAlign: 'center'
          },
          !isSmallPeriodTag ? { width: isExtraSmallScreen ? 30 : 42, alignSelf: 'center', pl: 0 } : {}
        ]}
      >
        {viewModel.periodTag}
      </Typography>
    );
  };

  const onPeriodOverlapIndicatorPress = () => {
    void viewModel.displayPeriodPrioritySelector();
  };

  return (
    <Box
      className={className}
      sx={{
        display: 'flex',
        ...sx,
        marginBottom: isCompact ? '3px' : undefined,
        borderTopLeftRadius: borderRadius,
        borderBottomLeftRadius: borderRadius,
        overflow: !isCompact ? 'hidden' : undefined
      }}
    >
      <Stack
        direction="row"
        sx={{
          flex: 1
        }}
      >
        <DayAndWeekPeriodInfoBox
          viewModel={viewModel.infoBoxViewModel}
          backgroundColor={backgroundColor}
          borderColor={borderColor}
          isCompact={isCompact}
          height={height}
        />

        <Stack
          direction="row"
          sx={{
            flex: 1,
            borderTopRightRadius: borderRadius,
            borderBottomRightRadius: borderRadius,
            borderWidth: 1,
            borderLeftWidth: 0,
            borderStyle: 'solid',
            borderColor,
            backgroundColor: theme.studyo.periods.contentBoxBackgroundColor
          }}
        >
          <DayAndWeekPeriodContentBox
            sx={{
              flex: 1,
              borderTopRightRadius: !viewModel.displayPeriodTag ? 4 : undefined,
              borderBottomRightRadius: !viewModel.displayPeriodTag ? 4 : undefined,
              overflow: 'hidden'
            }}
            canEdit={viewModel.canEdit}
            viewModel={viewModel.contentBoxViewModel}
            renderPeriodTag={renderPeriodTag}
          />

          {viewModel.displayPeriodTag && renderPeriodTag()}
        </Stack>
      </Stack>
      {viewModel.isOverlapping && (
        <PeriodOverlapIndicator
          sx={{ position: 'absolute', right: 0, top: 0 }}
          onPress={onPeriodOverlapIndicatorPress}
        />
      )}
    </Box>
  );
});
