import { Stack, SxProps } from '@mui/material';
import { isSxArray } from '@shared/components/utils';
import { OnboardingBackgroundImage, OnboardingContentBackgroundView } from '@studyo/components';
import { LearnAboutTodayView } from '@studyo/views';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import '../../styles/LoginScreen.style.scss';

export interface LearnAboutTodayScreenProps {
  sx?: SxProps;
  className?: string;
}

export const LearnAboutTodayScreen = observer((props: LearnAboutTodayScreenProps) => {
  const { viewModelFactory } = useStudyoServices();
  const { sx = [], className } = props;
  const viewModel = useMemo(() => viewModelFactory.createLearnAboutTodayScreen(), []);

  return (
    <Stack
      className={className}
      sx={[
        {
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <OnboardingBackgroundImage />
      <div className="login-container">
        <Stack
          sx={{
            flex: 1,
            position: 'relative'
          }}
        >
          <OnboardingContentBackgroundView sx={{ zIndex: 1 }} />

          <LearnAboutTodayView sx={{ flex: 1, zIndex: 2 }} viewModel={viewModel} />
        </Stack>
      </div>
    </Stack>
  );
});
