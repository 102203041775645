import { Box, Stack, SxProps, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Time } from '@shared/models/types';
import { observer } from 'mobx-react-lite';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { DayAndWeekConstants } from '../shared';

export interface CurrentTimeIndicatorProps {
  sx?: SxProps;
  className?: string;
  time: Time;
  offset: number;
}

export const CurrentTimeIndicator = observer((props: CurrentTimeIndicatorProps) => {
  const { localizationService } = useStudyoServices();
  const { sx = [], className, time, offset } = props;
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));

  return (
    <Stack
      sx={{
        ...sx,
        top: offset,
        position: 'absolute',
        width: isExtraSmallScreen ? DayAndWeekConstants.timesColumnCompactWidth : DayAndWeekConstants.timesColumnWidth,
        alignItems: 'center'
      }}
      className={className}
      direction="row"
      spacing={0}
    >
      <Box sx={{ backgroundColor: theme.palette.error.main, height: 2, width: 4 }} />
      <Box
        sx={{
          p: '2px',
          backgroundColor: theme.palette.error.main,
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 4,
          flex: 3,
          width: !isExtraSmallScreen ? 60 : undefined,
          aspectRatio: isExtraSmallScreen ? 1 : 'inherit',
          display: 'flex'
        }}
      >
        {!isExtraSmallScreen && (
          <Typography
            variant="caption"
            noWrap
            sx={{
              color: theme.studyo.agenda.currentTimeIndicatorTextColor,
              fontSize: 10
            }}
          >
            {time.formattedString(localizationService.localizedStrings.models.timeFormats.singleDigit)}
          </Typography>
        )}
      </Box>
    </Stack>
  );
});
