import { Box, SxProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { IPromiseBasedObservable } from 'mobx-utils';
import { ReactNode } from 'react';

export interface ObservablePresenterProps<T> {
  sx?: SxProps;
  className?: string;
  data: IPromiseBasedObservable<T>;
  renderData: (data: T) => ReactNode;
  renderLoading?: (previousData?: T) => ReactNode;
  renderError?: (error: Error, previousData?: T) => ReactNode;
}

export const ObservablePresenter = observer(<T,>(props: ObservablePresenterProps<T>) => {
  const { sx = [], className, data, renderData, renderLoading, renderError } = props;

  return data.case({
    pending: () =>
      renderLoading != null ? (
        <Box sx={sx} className={className}>
          {renderLoading(data.value as T | undefined)}
        </Box>
      ) : undefined,
    rejected: (error: Error) =>
      renderError != null ? (
        <Box sx={sx} className={className}>
          {renderError(error, data.value as T | undefined)}
        </Box>
      ) : undefined,
    fulfilled: (value) => (
      <Box sx={sx} className={className}>
        {renderData(value)}
      </Box>
    )
  });
});
