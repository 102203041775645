import { CircularProgress, Stack, SxProps } from '@mui/material';
import { isSxArray } from '@shared/components/utils';
import { OnboardingBackgroundImage } from '@studyo/components';

export interface InitializingScreenProps {
  sx?: SxProps;
  className?: string;
}

export function InitializingScreen(props: InitializingScreenProps) {
  const { sx = [], className } = props;

  return (
    <Stack
      className={className}
      sx={[
        {
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <OnboardingBackgroundImage />
      <CircularProgress sx={{ zIndex: 2 }} />
    </Stack>
  );
}
