import { EmailOutlined } from '@mui/icons-material';
import { Box, IconButton, Menu, MenuItem, SxProps } from '@mui/material';
import { PlannerSectionInfoEmailRole, PlannerSectionInfoStudentRowViewModel } from '@studyo/viewmodels';
import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';

export interface PlannerSectionInfoEmailButtonProps {
  sx?: SxProps;
  className?: string;
  student: PlannerSectionInfoStudentRowViewModel;
  sendEmail: (student: PlannerSectionInfoStudentRowViewModel, choice: PlannerSectionInfoEmailRole) => void;
}

export const PlannerSectionInfoEmailButton = observer((props: PlannerSectionInfoEmailButtonProps) => {
  const { localizationService } = useStudyoServices();
  const { student, sendEmail, sx = [], className } = props;
  const strings = localizationService.localizedStrings.studyo.agenda.planner.sectionInfo;
  const [displaySelectionMenu, setDisplaySelectionMenu] = useState(false);
  const emailButtonRef = useRef<HTMLButtonElement>(null);

  const didSelectRole = (role: PlannerSectionInfoEmailRole) => {
    closeMenu();
    sendEmail(student, role);
  };

  const openMenu = () => {
    setDisplaySelectionMenu(true);
  };

  const closeMenu = () => {
    setDisplaySelectionMenu(false);
  };

  // return focus to the button when we transitioned from !open -> open
  return (
    <Box sx={sx} className={className}>
      <IconButton ref={emailButtonRef} onClick={openMenu}>
        <EmailOutlined />
      </IconButton>

      <Menu
        id="simple-popover"
        open={displaySelectionMenu}
        anchorEl={emailButtonRef.current}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        onClose={closeMenu}
      >
        <MenuItem onClick={() => didSelectRole('student')}>{strings.sendEmailToStudent}</MenuItem>
        <MenuItem disabled={student.parentEmailAddresses.length === 0} onClick={() => didSelectRole('parent')}>
          {strings.sendEmailToParent}
        </MenuItem>
      </Menu>
    </Box>
  );
});
