import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import { IconButton, Stack, Switch, SxProps, Typography } from '@mui/material';
import { isSxArray } from '@shared/components/utils';
import { observer } from 'mobx-react-lite';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { ProfileMenuDemoOptionsViewModel } from '../../../viewmodels';

export interface ProfileMenuDemoRowProps {
  sx?: SxProps;
  className?: string;
  viewModel: ProfileMenuDemoOptionsViewModel;
}

export const ProfileMenuDemoRow = observer((props: ProfileMenuDemoRowProps) => {
  const { localizationService } = useStudyoServices();
  const { viewModel, sx = [], className } = props;
  const strings = localizationService.localizedStrings.studyo.agenda.setting.profileMenu;

  return (
    <Stack
      className={className}
      sx={[
        {
          px: 2
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <Typography
        variant="subtitle2"
        sx={{
          color: (theme) => theme.palette.text.secondary
        }}
      >
        {strings.demoSchoolNotice}
      </Typography>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          flexWrap: 'wrap'
        }}
      >
        <Stack sx={{ flexGrow: 1, flexBasis: 0, minWidth: 240 }}>
          <Stack
            direction="row"
            spacing={1}
            sx={{
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Typography variant="body2">{strings.demoSchoolPreventChangesLabel}</Typography>

            <Switch
              checked={viewModel.isPreventingChanges}
              onChange={(e) => (viewModel.isPreventingChanges = e.currentTarget.checked)}
            />
          </Stack>
        </Stack>
        <Stack sx={{ flexGrow: 1, flexBasis: 0, minWidth: 240 }}>
          <Stack
            direction="row"
            spacing={1}
            sx={{
              justifyContent: 'stretch',
              alignContent: 'center'
            }}
          >
            <IconButton size="small" onClick={() => (viewModel.today = viewModel.today.addDays(-1))}>
              <ArrowBackIosNew fontSize="medium" />
            </IconButton>

            <Stack
              spacing={0.5}
              sx={{
                flex: 1,
                alignItems: 'center'
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  color: (theme) => theme.palette.text.secondary
                }}
              >
                {strings.demoSchoolTodayLabel}
              </Typography>
              <Typography variant="body2">
                {viewModel.today.formattedString(localizationService.localizedStrings.models.dateFormats.long)}
              </Typography>
            </Stack>

            <IconButton size="small" onClick={() => (viewModel.today = viewModel.today.addDays(1))}>
              <ArrowForwardIos fontSize="medium" />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
});
