import { GetPublishingTaskWorkloadImpactResponse } from '@buf/studyo_studyo.bufbuild_es/studyo/services/contents_pb';
import { WorkloadImpactSummary } from '@buf/studyo_studyo.bufbuild_es/studyo/type_workload_pb';
import { ContentDefinitionUtils, DateUtils, SectionUtils } from '@shared/components/utils';
import { notConcurrent } from '@shared/models/PromiseUtils.ts';
import { SchoolDay } from '@shared/models/calendar';
import { AccountModel, SectionModel } from '@shared/models/config';
import {
  ContentDefinitionModel,
  ContentPublishTarget,
  EditableContentAttachment,
  EditableContentDefinition,
  EditableContentPublishTarget,
  EditableContentStep
} from '@shared/models/content';
import { Color, ContentIcon, ContentWorkloadLevel, Day, UnplannedContentIcons } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { DataLoader, DialogCancelled, OnDestroy, OnInit, dateService } from '@shared/services';
import { AccountData } from '@shared/services/stores';
import _, { sum } from 'lodash';
import { computed, makeObservable, observable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import {
  AccountAutoSyncService,
  AccountService,
  NavigationService,
  StudyoAnalyticsEventActions,
  StudyoAnalyticsService,
  StudyoSettingsStore
} from '../../../services';
import { AppTaskDueBoxViewModel, TaskDueBoxViewModel } from '../TaskDueBoxViewModel';
import { AppTaskStepListViewModel, TaskStepListViewModel } from '../TaskStepListViewModel';

export interface AvailablePeriodTag {
  readonly tag: string;
  readonly displayValue: string;
  readonly isHidden: boolean;
}

export interface PublishingWorkloadImpactViewModel {
  readonly impact: WorkloadImpactSummary;
  readonly mainStudentCount: number;

  showDetails(): Promise<void>;
}

export interface TaskEditViewModel extends OnInit, OnDestroy {
  save: () => Promise<void | DialogCancelled>;
  cancel: () => Promise<void>;
  closeAllModals: () => void;

  readonly dataLoader: DataLoader;
  readonly task: ContentDefinitionModel;

  readonly isCreatingTask: boolean;
  readonly isSlaveTask: boolean;
  readonly isPublishedTask: boolean;
  readonly hasChanges: boolean;
  readonly isLinkedToOtherTasks: boolean;
  readonly hasEditedLinkedTaskProperties: boolean;
  readonly canEditSection: boolean;
  readonly canEditWorkloadLevel: boolean;
  readonly shouldWarnUserForLongTimeSpan: boolean;
  readonly maximumDueDate: Day;

  title: string;
  icon: ContentIcon;
  notes: string;
  workloadLevel: ContentWorkloadLevel;
  isPrivate: boolean;

  readonly showPublishOnCreation: boolean;
  readonly canPublishOnCreation: boolean;
  shouldPublishOnCreation: boolean;

  readonly publishingImpact: IPromiseBasedObservable<PublishingWorkloadImpactViewModel | undefined>;

  readonly selectedSection: SectionModel | undefined;
  readonly allSections: SectionModel[];

  readonly numberOfAttachments: number;

  readonly datesViewModel: TaskDueBoxViewModel;
  periodTag: string;

  readonly availablePeriodTags: AvailablePeriodTag[];
  readonly schoolDays: SchoolDay[];
  readonly daysWithSectionOccurring: Day[];

  readonly userSteps: TaskStepListViewModel;
  readonly teacherSteps: TaskStepListViewModel | undefined;

  getSectionColor: (section: SectionModel) => Color;
  setSectionId: (id: string) => void;
  orderSteps: () => Promise<void>;
  copyTeacherSteps: () => void;
  showAttachments: () => Promise<void>;
  unlink: () => void;
}

export interface TaskEditNewTaskInfo {
  day: Day;
  periodTag: string | undefined;
  sectionId: string | undefined;
}

export class AppPublishingWorkloadImpactViewModel implements PublishingWorkloadImpactViewModel {
  constructor(
    private readonly _navigationService: NavigationService,
    private readonly _workload: GetPublishingTaskWorkloadImpactResponse,
    private readonly _targetSectionId: string,
    private readonly _sectionsById: Map<string, SectionModel>,
    private readonly _accountsById: Map<string, AccountModel>
  ) {
    makeObservable(this);
  }

  get impact(): WorkloadImpactSummary {
    return this._workload.impactSummary;
  }

  @computed
  get mainStudentCount(): number {
    return (
      sum(
        this._workload.dayGroups.filter((g) => g.impact === this._workload.impactSummary).map((g) => g.accounts.length)
      ) +
      sum(
        this._workload.weekGroups.filter((g) => g.impact === this._workload.impactSummary).map((g) => g.accounts.length)
      )
    );
  }

  async showDetails(): Promise<void> {
    await this._navigationService.navigateToWorkloadImpactDetails(
      [{ workload: this._workload, targetSectionId: this._targetSectionId }],
      this._sectionsById,
      this._accountsById,
      true
    );
  }
}

export class AppTaskEditViewModel implements TaskEditViewModel {
  readonly dataLoader: AccountData;
  private readonly _originalTask: ContentDefinitionModel | undefined;
  task: EditableContentDefinition;
  initialSectionId: string;
  private readonly _initialAssignmentDay?: Day;
  @observable private _shouldPublishOnCreation = false;

  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _navigationService: NavigationService,
    private readonly _analyticsService: StudyoAnalyticsService,
    private readonly _accountAutoSyncService: AccountAutoSyncService,
    settingsStore: StudyoSettingsStore,
    private readonly _onSuccess: (value: string) => void,
    private readonly _onCancel: () => void,
    accountService: AccountService,
    contentId: string | undefined,
    newTaskInfo: TaskEditNewTaskInfo | undefined
  ) {
    makeObservable(this);
    this.dataLoader = accountService.displayedAccountData;

    if (contentId != null) {
      this._originalTask = this.dataLoader.contentsById.get(contentId)!;
      this.task = new EditableContentDefinition(this._originalTask, false);
    } else if (newTaskInfo != null) {
      const preferences = settingsStore.getPreferences(this.dataLoader.accountId);
      const schoolDays = this.dataLoader.schoolDays;

      if (preferences.newTaskDueNextPeriod && newTaskInfo.sectionId != null && DateUtils.isToday(newTaskInfo.day)) {
        const nextOccurrence = this.getNextSectionOccurrence(newTaskInfo.sectionId);
        if (nextOccurrence != null) {
          newTaskInfo.day = nextOccurrence.day;
          newTaskInfo.periodTag = nextOccurrence.period.tag;
        }
      }

      this._initialAssignmentDay = ContentDefinitionUtils.announcementDate(
        preferences.announcementDateToday,
        newTaskInfo.day,
        schoolDays[0],
        schoolDays[this.dataLoader.schoolDays.length - 1]
      );

      this.task = EditableContentDefinition.createNewTask(
        this.dataLoader.configId,
        this.dataLoader.accountId,
        newTaskInfo.day,
        this._initialAssignmentDay,
        newTaskInfo.periodTag,
        newTaskInfo.sectionId
      );
    } else {
      throw new Error('Trying to edit a content without passing a contentId or information used to create new task.');
    }

    this.initialSectionId = this.task.sectionId;
  }

  @computed
  private get attachments() {
    if (this.task.isSlave && this.task.masterContent != null) {
      return this.task.masterContent.attachmentsList;
    } else {
      return this.task.attachments.filter((att) => !att.shouldBeDeleted);
    }
  }

  @computed
  get isCreatingTask() {
    return this.task.isNew;
  }

  @computed
  get isSlaveTask() {
    return this.task.isSlave;
  }

  @computed
  get isPublishedTask() {
    return this.task.isPublished;
  }

  @computed
  get hasChanges() {
    return this.task.hasChanges;
  }

  @computed
  get canEditSection() {
    return !this.isSlaveTask && this.task.ownerId === this.dataLoader.accountId && !this.task.isPrivate;
  }

  @computed
  get canEditWorkloadLevel() {
    return !this.isSlaveTask;
  }

  @computed
  get isLinkedToOtherTasks() {
    if (this.task.contentsGroupIdentifier.length === 0) {
      return false;
    }

    return (
      this.dataLoader.visibleContents.filter(
        (cd) => cd.id !== this.task.id && cd.contentsGroupIdentifier === this.task.contentsGroupIdentifier
      ).length > 0
    );
  }

  @computed
  get hasEditedLinkedTaskProperties() {
    return this.task.hasChangedLinkedTasksProperties;
  }

  @computed
  get maximumDueDate() {
    return this.dataLoader.config.endDay.addMonths(1).lastDayOfMonth();
  }

  @computed
  get title() {
    return this.task.title;
  }

  set title(value: string) {
    this.task.title = value;
  }

  @computed
  get icon() {
    return this.task.icon;
  }

  set icon(value: ContentIcon) {
    this.task.icon = value;

    // Set default Workload value
    const defaultWorkload = ContentDefinitionUtils.getDefaultWorkloadLevelForNewContentIcon(value);

    if (defaultWorkload != undefined) {
      this.task.workloadLevel = defaultWorkload;
    }

    // Set default announcement and planned dates. If we have an initial day, it means
    // we're a new task.
    if (
      this._initialAssignmentDay != null &&
      !this.datesViewModel.hasChangedAssignmentDay &&
      !this.datesViewModel.hasChangedPlannedDay
    ) {
      if (UnplannedContentIcons.includes(value)) {
        this.task.assignmentDay = this.task.plannedDay = this.task.dueDay;
      } else {
        this.task.assignmentDay = this.task.plannedDay = this._initialAssignmentDay;
      }
    }
  }

  @computed
  get notes() {
    return this.task.notes;
  }

  set notes(value: string) {
    this.task.notes = value;
  }

  @computed
  get workloadLevel() {
    return this.task.workloadLevel;
  }

  set workloadLevel(value: ContentWorkloadLevel) {
    this.task.workloadLevel = value;
  }

  @computed
  get isPrivate() {
    return this.task.isPrivate;
  }

  set isPrivate(value: boolean) {
    this.task.isPrivate = value;

    if (value) {
      this.task.sectionId = '';
    } else {
      this.task.sectionId = this.initialSectionId;
    }
  }

  @computed
  get showPublishOnCreation(): boolean {
    return this.isCreatingTask && this.dataLoader.hasData && this.dataLoader.account.role === 'teacher';
  }

  @computed
  get canPublishOnCreation(): boolean {
    return this.isCreatingTask && this.selectedSection?.teacherIds.includes(this.dataLoader.accountId) === true;
  }

  @computed
  get shouldPublishOnCreation(): boolean {
    return this.isCreatingTask && this.canPublishOnCreation && this._shouldPublishOnCreation;
  }

  set shouldPublishOnCreation(value: boolean) {
    // No need to verify if conditions make sense, as long as other parts of the code use
    // shouldPublishOnCreation, not _shouldPublishOnCreation.
    this._shouldPublishOnCreation = value;
  }

  @computed
  get publishingImpact() {
    // All properties that can affect this value must be obtained here, not in loadPublishingImpact.
    return fromPromise(
      this.loadPublishingImpact(
        this.selectedSection,
        this.workloadLevel,
        this.shouldPublishOnCreation,
        this.datesViewModel.dueDay
      )
    );
  }

  @computed
  get selectedSection() {
    return this.task.sectionId !== '' ? this.dataLoader.sectionsById.get(this.task.sectionId) : undefined;
  }

  @computed
  get numberOfAttachments() {
    return this.attachments.length;
  }

  @computed
  get allSections() {
    return this.dataLoader.userSections;
  }

  @computed
  get datesViewModel() {
    return new AppTaskDueBoxViewModel(this.dataLoader, this.task, this._localizationService);
  }

  @computed
  get periodTag() {
    return this.task.duePeriodTag;
  }

  set periodTag(value: string) {
    if (value === '' || this.availablePeriodTags.findIndex((t) => t.tag === value) >= 0) {
      this.task.duePeriodTag = value;
    }
  }

  @computed
  get availablePeriodTags() {
    const schoolDay = this.dataLoader.schoolDaysByDay.get(this.task.dueDay.asString)!;
    const format = this._localizationService.localizedStrings.models.timeFormats.singleDigit;
    const isClassTimes = this.dataLoader.config.scheduleKind === 'class-times';
    const tags = new Set(schoolDay.periods.map((p) => p.tag));

    return schoolDay.sortedPeriods
      .map((p) => ({
        tag: p.tag,
        displayValue: isClassTimes
          ? `${p.startTime.formattedString(format)} - ${p.endTime.formattedString(format)}`
          : p.tag,
        isHidden: this.dataLoader.periodPrioritiesStore.getPeriodIsHidden(p, schoolDay.day)
      }))
      .concat(
        schoolDay.sortedPrunedPeriods
          .filter((p) => !tags.has(p.tag))
          .map((p) => ({
            tag: p.tag,
            displayValue: p.tag,
            isHidden: true
          }))
      );
  }

  @computed
  get schoolDays() {
    return this.dataLoader.schoolDays;
  }

  @computed
  get daysWithSectionOccurring() {
    if (this.selectedSection == null) {
      return [];
    }

    const occurrences = this.dataLoader.getOccurrencesForSectionId(this.selectedSection.id);
    return _.chain(occurrences)
      .map((oc) => oc.day)
      .uniqWith((day1, day2) => day1.isSame(day2))
      .value();
  }

  @computed
  get userSteps() {
    return new AppTaskStepListViewModel(this.schoolDays, this.task, false, undefined, true, true, false);
  }

  @computed
  get teacherSteps() {
    if (this.task.isSlave && this.task.masterContent!.stepsList.length > 0) {
      return new AppTaskStepListViewModel(this.schoolDays, this.task, true, undefined, false, false, false);
    } else {
      return undefined;
    }
  }

  @computed
  get shouldWarnUserForLongTimeSpan() {
    if (!this.task.isMaster && !this.shouldPublishOnCreation) {
      return false;
    }

    if (DateUtils.numberOfDaysBetween(this.task.dueDay, this.task.assignmentDay) > 14) {
      return true;
    }
    if (this.task.contentsGroupIdentifier.length > 0) {
      const tasksInGroup = this.dataLoader.visibleContents.filter(
        (cd) => cd.id !== this.task.id && cd.contentsGroupIdentifier === this.task.contentsGroupIdentifier
      );
      return tasksInGroup.find((t) => DateUtils.numberOfDaysBetween(t.dueDay, t.assignmentDay) > 14) != null;
    }
    return false;
  }

  onInit() {
    this._accountAutoSyncService.suspend();
  }

  onDestroy() {
    this._accountAutoSyncService.resume();
  }

  getSectionColor(section: SectionModel) {
    return SectionUtils.getSectionColor(section, this.dataLoader.account, undefined)!;
  }

  getNextSectionOccurrence(sectionId: string) {
    const occurrences = this.dataLoader.getOccurrencesForSectionId(sectionId);
    const today = dateService.today;
    return _.find(occurrences, (occurrence) => occurrence.day.isAfter(today));
  }

  setSectionId(value: string) {
    this.task.sectionId = value;
  }

  unlink() {
    this.task.contentsGroupIdentifier = '';
  }

  copyTeacherSteps() {
    if (this.task.masterContent != null) {
      this.task.masterContent.stepsList.forEach((masterStep) => {
        const existingStep = this.task.steps.find((s) => s.stepId === masterStep.id);

        if (existingStep != null) {
          existingStep.title = masterStep.title;
          existingStep.date = masterStep.date;
        } else {
          const newStep = EditableContentStep.createNew(this.task, masterStep.id);
          newStep.title = masterStep.title;
          newStep.date = masterStep.date;
          newStep.sortOrder = this.task.steps.length;
          this.task.addStep(newStep);
        }
      });
    }
  }

  async orderSteps() {
    await this._navigationService.navigateToTaskStepsOrderModal(
      this.task.clone(),
      this.selectedSection,
      this.applyStepsReorder
    );
  }

  async showAttachments() {
    await this._navigationService.navigateToAttachmentList(
      () => this.attachments,
      (attachment) => this.deleteAttachment(attachment as EditableContentAttachment),
      (attachment) => this.addAttachment(attachment as EditableContentAttachment),
      this.task.isSlave,
      this.task.sectionId
    );
  }

  async save(): Promise<void> {
    await this._innerSave();
  }

  closeAllModals() {
    this._onSuccess(this.task.id);
  }

  cancel() {
    this._onCancel();
    return Promise.resolve();
  }

  private async deleteAttachment(attachment: EditableContentAttachment) {
    this.task.deleteAttachment(attachment);
    return Promise.resolve();
  }

  private addAttachment(attachment: EditableContentAttachment) {
    this.task.addAttachment(attachment);
  }

  private applyStepsReorder = (content: EditableContentDefinition) => {
    this.task.editableSteps.applyFrom(content.editableSteps);
  };

  private readonly _innerSave = notConcurrent(async () => {
    this.userSteps.saveEmptyStep();

    if (this.isCreatingTask) {
      this._analyticsService.trackEvent({ action: StudyoAnalyticsEventActions.task.createTask });
    } else {
      this._analyticsService.trackEvent({ action: StudyoAnalyticsEventActions.task.updateTask });

      if (this.task.contentsGroupIdentifier.length > 0) {
        const tasksInGroup = this.dataLoader.visibleContents.filter(
          (cd) => cd.id !== this.task.id && cd.contentsGroupIdentifier === this.task.contentsGroupIdentifier
        );

        for (const item of tasksInGroup) {
          const task = new EditableContentDefinition(item, false);
          EditableContentDefinition.applyModificationToOtherTask(task, this.task);
          await this.dataLoader.createOrUpdateContent(task);
        }
      }
    }

    if (this.shouldPublishOnCreation) {
      // Confirmed: The task is new, the selected section is taught by the user and they have
      //            enabled "also publish".
      this.task.publishTarget = new EditableContentPublishTarget(ContentPublishTarget.createNew(), true);
      this.task.publishTarget.kind = 'section';
      this.task.publishTarget.targetAccountIds = [];
    }

    const response = await this.dataLoader.createOrUpdateContent(this.task);
    this.task = new EditableContentDefinition(response);
  });

  private async loadPublishingImpact(
    selectedSection: SectionModel | undefined,
    workloadLevel: ContentWorkloadLevel,
    shouldPublishOnCreation: boolean,
    dueDay: Day
  ): Promise<PublishingWorkloadImpactViewModel | undefined> {
    if (selectedSection == null || (workloadLevel !== 'medium' && workloadLevel !== 'major')) {
      return undefined;
    }

    if (this.isCreatingTask) {
      if (!shouldPublishOnCreation) {
        return undefined;
      }
    } else if (this._originalTask?.isMaster !== true) {
      return undefined;
    } else if (
      this._originalTask != null &&
      (this._originalTask.workloadLevel === 'medium' || this._originalTask.workloadLevel === 'major')
    ) {
      // When a task moves from not important to important, we always check workload,
      // even if the section and due date this not change.
      if (this._originalTask.sectionId == selectedSection.id && this._originalTask.dueDay.isSame(dueDay)) {
        return undefined;
      }
    }

    const targetAccountIds = this._originalTask?.publishTarget?.targetAccountIds ?? [];
    const response = await this.dataLoader.getPublishingTaskWorkloadImpact(
      selectedSection.id,
      dueDay,
      targetAccountIds,
      this._originalTask?.id
    );

    return new AppPublishingWorkloadImpactViewModel(
      this._navigationService,
      response,
      selectedSection.id,
      this.dataLoader.sectionsById,
      this.dataLoader.accountsById
    );
  }
}
