import { DarkMode, LightMode, PersonRemove, SettingsBrightness } from '@mui/icons-material';
import { Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Switch, SxProps } from '@mui/material';
import { AllLocales, Locale, LocalizationService } from '@shared/resources/services';
import { runInAction } from 'mobx';
import { Observer, observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useStudyoServices } from '../../UseStudyoServicesHook';
import { ConfirmDialog, DropDownSelector, ListSection } from '../../components';
import { PreferencesViewModel, SettableThemeKind } from '../../viewmodels';

export interface PreferencesViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: PreferencesViewModel;
}

export const PreferencesView = observer((props: PreferencesViewProps) => {
  const { localizationService } = useStudyoServices();
  const { sx = [], className, viewModel } = props;
  const strings = localizationService.localizedStrings.studyo.agenda.setting.preferences;
  const [showDeleteAccountConfirmation, setShowDeleteAccountConfirmation] = useState(false);
  const [showDeleteAccountRequested, setShowDeleteAccountRequested] = useState(false);
  const [deleteAccountRequested, setDeleteAccountRequested] = useState(false);
  const [showUnexpectedError, setShowUnexpectedError] = useState(false);

  const deleteAccountPressed = () => {
    setShowDeleteAccountConfirmation(true);
  };

  const deleteAccountConfirmed = async () => {
    setShowDeleteAccountConfirmation(false);

    try {
      await viewModel.deleteAccount();

      runInAction(() => {
        setDeleteAccountRequested(true);
        setShowDeleteAccountRequested(true);
      });
    } catch (error) {
      console.error(error);
      setShowUnexpectedError(true);
    }
  };

  const hideDeleteAccountConfirmation = () => {
    setShowDeleteAccountConfirmation(false);
  };

  const hideDeleteAccountRequested = () => {
    setShowDeleteAccountRequested(false);
  };

  const hideUnexpectedError = () => {
    setShowUnexpectedError(false);
  };

  return (
    <>
      <List sx={sx} className={className}>
        <ListSection title={strings.generalOptionsTitle}>
          <ListItem>
            <ListItemText primary={strings.languaguePreferenceTitle} />
            <DropDownSelector
              options={AllLocales}
              selectedIndex={AllLocales.indexOf(viewModel.locale)}
              didSelectOption={(value) => requestAnimationFrame(() => (viewModel.locale = AllLocales[value]))}
              displayValueForOption={(value) => localeDisplayValue(value as Locale, localizationService)}
            />
          </ListItem>

          <Divider />

          <ListItem>
            <ListItemText primary={strings.themeTitle} />
            <DropDownSelector
              options={viewModel.availableThemes}
              selectedIndex={viewModel.availableThemes.indexOf(viewModel.theme)}
              didSelectOption={(value) =>
                requestAnimationFrame(() => (viewModel.theme = viewModel.availableThemes[value]))
              }
              displayValueForOption={(value) => themeDisplayValue(value as SettableThemeKind, localizationService)}
              renderOption={(option) => renderThemeValue(option as SettableThemeKind, localizationService)}
            />
          </ListItem>
        </ListSection>
        <ListSection title={strings.newTasksOptionsTitle}>
          <ListItem>
            <ListItemText primary={strings.announcementDateTodayTitle} />
            <Observer>
              {() => (
                <Switch
                  checked={viewModel.announcementDateToday}
                  onChange={(e) => (viewModel.announcementDateToday = e.target.checked)}
                />
              )}
            </Observer>
          </ListItem>

          <Divider />

          <ListItem>
            <ListItemText primary={strings.newTaskDueNextPeriodTitle} />
            <Observer>
              {() => (
                <Switch
                  checked={viewModel.newTaskDueNextPeriod}
                  onChange={(e) => (viewModel.newTaskDueNextPeriod = e.target.checked)}
                />
              )}
            </Observer>
          </ListItem>
        </ListSection>

        {viewModel.canDeleteAccount && (
          <ListSection title={strings.accountOptionsTitle}>
            <ListItemButton onClick={deleteAccountPressed} disabled={deleteAccountRequested}>
              <ListItemIcon>
                <PersonRemove color="error" />
              </ListItemIcon>
              <ListItemText primary={strings.deleteAccount} primaryTypographyProps={{ color: 'error' }} />
            </ListItemButton>
          </ListSection>
        )}
      </List>

      <ConfirmDialog
        open={showDeleteAccountConfirmation}
        onClose={hideDeleteAccountConfirmation}
        cancelTitle={strings.deleteAccountConfirmationNo}
        onCancelPress={hideDeleteAccountConfirmation}
        confirmTitle={strings.deleteAccountConfirmationYes}
        onConfirmPress={() => void deleteAccountConfirmed()}
        title={strings.deleteAccountConfirmationTitle}
        description={strings.deleteAccountConfirmationMessage}
      />

      <ConfirmDialog
        open={showDeleteAccountRequested}
        onClose={hideDeleteAccountRequested}
        confirmTitle={strings.close}
        onConfirmPress={hideDeleteAccountRequested}
        title={strings.deleteAccountResponseTitle}
        description={strings.deleteAccountResponseMessage}
      />

      <ConfirmDialog
        open={showUnexpectedError}
        onClose={hideUnexpectedError}
        confirmTitle={strings.close}
        onConfirmPress={hideUnexpectedError}
        title={strings.unexpectedErrorTitle}
        description={strings.unexpectedErrorMessage}
      />
    </>
  );
});

function localeDisplayValue(locale: Locale, localizationService: LocalizationService) {
  const strings = localizationService.localizedStrings.studyo.agenda.setting.preferences;

  switch (locale) {
    case 'en':
      return `🇺🇸 ${strings.languageEnglishOption}`;

    case 'fr':
      return `🇫🇷 ${strings.languageFrenchOption}`;
  }
}

function themeDisplayValue(kind: SettableThemeKind, localizationService: LocalizationService) {
  const strings = localizationService.localizedStrings.studyo.agenda.setting.preferences;

  switch (kind) {
    case 'dark':
      return strings.themeDarkOption;

    case 'light':
      return strings.themeLightOption;

    case 'system':
      return strings.themeSystemOption;
  }
}

function themeIconValue(kind: SettableThemeKind) {
  switch (kind) {
    case 'dark':
      return <DarkMode />;

    case 'light':
      return <LightMode />;

    case 'system':
      return <SettingsBrightness />;
  }
}

function renderThemeValue(kind: SettableThemeKind, localizationService: LocalizationService) {
  return (
    <>
      <ListItemIcon>{themeIconValue(kind)}</ListItemIcon>
      <ListItemText>{themeDisplayValue(kind, localizationService)}</ListItemText>
    </>
  );
}
