import { Box, SxProps } from '@mui/material';
import { ReactNode, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

export interface FileDropzoneProps {
  sx?: SxProps;
  className?: string;
  children: ReactNode;
  onDragColor: string;
  acceptMultipleFiles: boolean;
  supportClick: boolean;
  onFileDrop: (file: File) => void;
}

export function FileDropzone(props: FileDropzoneProps) {
  const { onFileDrop, supportClick, acceptMultipleFiles, onDragColor, sx = [], className, children } = props;

  const onDrop = useCallback((acceptedFiles: File[]) => {
    // Do something with the files
    onFileDrop(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: !supportClick,
    multiple: acceptMultipleFiles
  });

  return (
    <Box
      className={className}
      sx={{
        display: 'flex',
        ...sx,
        backgroundColor: isDragActive ? onDragColor : undefined,
        cursor: isDragActive ? 'copy' : undefined
      }}
    >
      <div style={{ flex: 1, display: 'flex' }} {...getRootProps()}>
        <input {...getInputProps()} />
        {children}
      </div>
    </Box>
  );
}
