import SearchIcon from '@mui/icons-material/Search';
import {
  Badge,
  Box,
  Divider,
  InputBase,
  Paper,
  Stack,
  SxProps,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { isSxArray } from '@shared/components/utils';
import { ConfirmDialog, SaveDialog, TimelineList, useSaveDialog } from '@studyo/components';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useStudyoServices } from '../../UseStudyoServicesHook';
import { TimelineListViewModel } from '../../viewmodels';

type TimelineListKind = 'today' | 'todo' | 'completed' | 'cancelled';

export interface TimelineListViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: TimelineListViewModel;
}

export const TimelineListView = observer((props: TimelineListViewProps) => {
  const { localizationService, uiSettingsStore } = useStudyoServices();
  const { viewModel, sx = [], className } = props;
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));
  const strings = localizationService.localizedStrings.studyo.agenda.timeline;
  const [currentTab, setCurrentTab] = useState<TimelineListKind>('today');
  const [movePromise, startMove, resetMove, retryMove] = useSaveDialog(() => viewModel.doMovePlannedDates());

  const currentListViewModel = () => {
    switch (currentTab) {
      case 'today':
        return viewModel.today;
      case 'todo':
        return viewModel.todo;
      case 'completed':
        return viewModel.completed;
      case 'cancelled':
        return viewModel.cancelled;
    }
  };

  return (
    <Box
      className={className}
      sx={[
        {
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <Stack
        sx={{
          flex: 1,
          overflow: 'hidden',
          backgroundColor: theme.studyo.agenda.timeline.listBackgroundColor
        }}
      >
        <Paper
          sx={{
            px: 2,
            py: 1,
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'transparent',
            borderRadius: 0
          }}
          elevation={0}
        >
          <InputBase
            sx={{ flex: 1, fontSize: '1em' }}
            placeholder={strings.search}
            value={viewModel.searchFilter}
            onChange={(e) => (viewModel.searchFilter = e.currentTarget.value)}
            onFocus={() => uiSettingsStore.preventArrowNavigation()}
            onBlur={() => uiSettingsStore.allowArrowNavigation()}
          />
          <SearchIcon fontSize="inherit" sx={{ color: theme.palette.text.secondary }} />
        </Paper>

        <Divider />

        <Stack
          sx={{
            flex: 1,
            overflow: 'hidden',
            backgroundColor: theme.studyo.agenda.timeline.listBackgroundColor
          }}
        >
          <Tabs
            value={currentTab}
            onChange={(_, value: TimelineListKind) => setCurrentTab(value)}
            variant={isExtraSmallScreen ? 'scrollable' : 'fullWidth'}
            scrollButtons={isExtraSmallScreen}
          >
            {renderTab('today', strings.todayTasks, viewModel.today.searchFilterMatches)}
            {renderTab('todo', strings.todoTasks, viewModel.todo.searchFilterMatches)}
            {renderTab('completed', strings.completedTasks, viewModel.completed.searchFilterMatches)}
            {renderTab('cancelled', strings.canceledTasks, viewModel.cancelled.searchFilterMatches)}
          </Tabs>

          <Divider />

          <TimelineList viewModel={currentListViewModel()} sx={{ flex: 1 }} />
        </Stack>
      </Stack>
      <ConfirmDialog
        open={viewModel.isConfirmingMovePlannedDates}
        title={strings.movePlannedDatesTitle}
        description={strings.movePlannedDatesMessage}
        cancelTitle={strings.movePlannedCancelLabel}
        onCancelPress={() => viewModel.hideMovePlannedDates()}
        confirmTitle={strings.movePlannedConfirmLabel}
        onConfirmPress={startMove}
        onClose={() => viewModel.hideMovePlannedDates()}
      />
      <SaveDialog
        promise={movePromise}
        retryCall={retryMove}
        onClose={resetMove}
        titles={{
          saving: strings.movingPlannedDatesTitle,
          saved: strings.movedPlannedDatesTitle,
          error: strings.failedMovingPlannedDatesTitle
        }}
        descriptions={{
          saving: strings.movingPlannedDatesDescription,
          saved: strings.movedPlannedDatesDescription,
          error: strings.failedMovingPlannedDatesDescription,
          permanentError: strings.failedPermanentMovingPlannedDatesDescription
        }}
      />
    </Box>
  );
});

function renderTab(value: TimelineListKind, title: string, badgeCount: number) {
  return (
    <Tab
      sx={{ overflow: 'visible', fontSize: 14 }}
      value={value}
      label={
        <Badge badgeContent={badgeCount} color="warning">
          {title}
        </Badge>
      }
    />
  );
}
