import { InputBase, ListItem, SxProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { TaskEditViewModel } from '../../../viewmodels';
import { TaskEditIconSelector } from './TaskEditIconSelector';

export interface TaskEditTitleCellProps {
  sx?: SxProps;
  className?: string;
  viewModel: TaskEditViewModel;
}

export const TaskEditTitleCell = observer((props: TaskEditTitleCellProps) => {
  const { localizationService } = useStudyoServices();
  const { sx = [], className, viewModel } = props;

  const placeholder = localizationService.localizedStrings.models.contents.defaultTitle(viewModel.task.icon);
  const canEdit = !viewModel.isSlaveTask;
  // If no custom title for task when edition is disabled, we display the placeholder which
  // is the task icon default title.
  const title = canEdit || viewModel.title.length > 0 ? viewModel.title : placeholder;

  return (
    <ListItem sx={sx} className={className}>
      <InputBase
        sx={{ flex: 1 }}
        value={title}
        onChange={(e) => (viewModel.title = e.currentTarget.value)}
        placeholder={placeholder}
        disabled={!canEdit}
        autoFocus={viewModel.isCreatingTask}
      />

      <TaskEditIconSelector viewModel={viewModel} />
    </ListItem>
  );
});
