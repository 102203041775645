import { EmailOutlined } from '@mui/icons-material';
import { Box, Button, InputBase, Paper, Stack, SxProps, Typography, useMediaQuery, useTheme } from '@mui/material';
import { isSxArray } from '@shared/components/utils';
import { SectionColors } from '@shared/models/Colors';
import { OptionsDialog } from '@studyo/components';
import { NoteEditViewModel } from '@studyo/viewmodels';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useStudyoServices } from '../../UseStudyoServicesHook';

export interface NoteEditViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: NoteEditViewModel;
}

export const NoteEditView = observer((props: NoteEditViewProps) => {
  const { localizationService } = useStudyoServices();
  const { viewModel, sx = [], className } = props;
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));

  const strings = localizationService.localizedStrings.studyo.contents.noteEdit;

  useEffect(() => {
    viewModel.onInit();
    return () => viewModel.onDestroy();
  }, []);

  const backgroundColor = viewModel.isSkipped
    ? theme.studyo.periods.skippedPeriodColor
    : viewModel.sectionColor != null
      ? SectionColors.get(viewModel.sectionColor)
      : theme.studyo.periods.freePeriodColor;

  const bandColor =
    viewModel.sectionColor != null ? SectionColors.get(viewModel.sectionColor) : theme.studyo.periods.freePeriodColor;

  const textColor =
    viewModel.sectionColor != null && !viewModel.isSkipped
      ? theme.studyo.periods.periodWithSectionTextColor
      : theme.studyo.periods.freePeriodTextColor;

  const startTime = viewModel.periodStartTime?.formattedString(
    localizationService.localizedStrings.models.timeFormats.singleDigit
  );
  const endTime = viewModel.periodEndTime?.formattedString(
    localizationService.localizedStrings.models.timeFormats.singleDigit
  );
  const timeLabel = startTime != null ? (endTime != null ? `${startTime} - ${endTime}` : startTime) : (endTime ?? '');

  const didClickOnTeacherName = () => {
    viewModel.sendEmailToTeacher();
  };

  const didCancelTeacherSelection = () => {
    viewModel.hideTeacherSelectionDialog();
  };

  return (
    <Box
      className={className}
      sx={[
        {
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      {/* Header */}
      <Stack
        spacing={0.5}
        sx={{
          px: 2,
          pb: 1,
          pt: { xs: 0, sm: 2 },
          backgroundColor,
          color: textColor
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          sx={{
            alignItems: 'baseline',
            overflow: 'hidden'
          }}
        >
          <Typography
            variant="h6"
            noWrap
            sx={{
              flex: 1,
              color: 'inherit',
              textOverflow: 'ellipsis'
            }}
          >
            {viewModel.sectionTitle ?? strings.noSectionTitle}
          </Typography>

          <Typography
            variant="subtitle2"
            sx={{
              color: 'inherit'
            }}
          >
            {viewModel.day.formattedString(localizationService.localizedStrings.models.dateFormats.medium) +
              ' - ' +
              (isExtraSmallScreen ? viewModel.periodTag : strings.displayPeriodTag(viewModel.periodTag))}
          </Typography>
        </Stack>

        <Stack
          direction="row"
          spacing={1}
          sx={{
            alignItems: 'baseline',
            justifyContent: 'space-between'
          }}
        >
          {viewModel.sectionNumber != null && (
            <Typography
              variant="body2"
              sx={{
                color: 'inherit'
              }}
            >
              {viewModel.sectionNumber}
            </Typography>
          )}

          <Typography
            variant="body2"
            sx={{
              color: 'inherit'
            }}
          >
            {timeLabel}
          </Typography>
        </Stack>

        {viewModel.occurrenceTitle != null && (
          <Stack
            direction="row"
            sx={{
              alignItems: 'baseline',
              justifyContent: 'flex-end'
            }}
          >
            <Typography
              variant="body2"
              sx={{
                color: 'inherit'
              }}
            >
              {viewModel.occurrenceTitle}
            </Typography>
          </Stack>
        )}

        {viewModel.teachersName != null && viewModel.roomName != null && (
          <Stack
            direction="row"
            sx={{
              alignItems: 'baseline',
              justifyContent: 'space-between'
            }}
          >
            {viewModel.teachersName != null && (
              <Button
                disabled={!viewModel.teacherHasEmail}
                onClick={didClickOnTeacherName}
                color="inherit"
                size="small"
                startIcon={<EmailOutlined />}
              >
                {viewModel.teachersName}
              </Button>
            )}

            {viewModel.roomName != null && (
              <Typography
                variant="body2"
                sx={{
                  color: 'inherit'
                }}
              >
                {viewModel.roomName}
              </Typography>
            )}
          </Stack>
        )}
      </Stack>
      <Box sx={{ backgroundColor: bandColor, height: '4px' }} />
      {/* Note */}
      <Paper
        sx={{
          flex: 1,
          px: 2,
          py: 1,
          backgroundColor: 'transparent',
          borderWidth: 0,
          borderRadius: 0,
          overflow: 'auto'
        }}
        elevation={0}
      >
        <InputBase
          sx={{ height: '100%', width: '100%', fontSize: '1em', alignItems: 'flex-start' }}
          placeholder={strings.notesPlaceholder}
          value={viewModel.notes}
          onChange={(e) => (viewModel.notes = e.currentTarget.value)}
          multiline
          minRows={0}
          disabled={!viewModel.canEdit}
        />
      </Paper>
      {viewModel.showTeacherEmailSelection && (
        <OptionsDialog
          open={viewModel.showTeacherEmailSelection}
          title={strings.teacherEmailMenuTitle}
          description={strings.teacherEmailMenuMessage}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onClose={() => {}}
          onCancel={didCancelTeacherSelection}
          elements={viewModel.teacherSelectionElements}
        />
      )}
    </Box>
  );
});
