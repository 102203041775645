import { Box, SxProps } from '@mui/material';
import { DialogResult } from '@shared/services';
import { DialogActionButtons, DialogButton, ResponsiveDialog } from '@studyo/components';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { DialogHeader } from '../../../components';
import { MonthSectionFilterView } from '../../../views';

export interface MonthlySectionFilterScreenProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
}

export const MonthlySectionFilterScreen = observer((props: MonthlySectionFilterScreenProps) => {
  const { localizationService, viewModelFactory } = useStudyoServices();
  const { className, sx = [], onCancel, onSuccess } = props;
  const viewModel = useMemo(
    () => viewModelFactory.createMonthlySectionFilter(onSuccess!, onCancel!),
    [onSuccess, onCancel]
  );
  const strings = localizationService.localizedStrings.studyo.agenda.monthly;

  return (
    <ResponsiveDialog
      sx={sx}
      className={className}
      maxWidth="xs"
      fullWidth={true}
      open={true}
      maxHeight="large"
      onClose={() => viewModel.dismiss()}
    >
      <DialogHeader title={strings.sectionFilterTitle} onClose={() => viewModel.dismiss()} />
      <MonthSectionFilterView sx={{ flex: 1 }} viewModel={viewModel} />
      <DialogActionButtons>
        <DialogButton
          title={strings.filterOptions.reset}
          type="destructive"
          disabled={!viewModel.isFiltered}
          onPress={() => viewModel.reset()}
        />
        <Box
          sx={{
            flex: 1
          }}
        />
        <DialogButton
          title={strings.sectionFilterClose}
          type="normal"
          onPress={() => viewModel.dismiss()}
          minimumScreenSize="sm"
        />
      </DialogActionButtons>
    </ResponsiveDialog>
  );
});
